import React from "react";
import classnames from "classnames";
import Breadcrumbs from "../components/Breadcrumbs.jsx";
import ReactTable from "react-table";
import {
  Card, CardBody, Row, Col, Input, InputGroupAddon, InputGroupText, InputGroup, Nav, NavItem, NavLink, Progress
} from "reactstrap";
import Graph from "charts/networkStatusGraph";
import mediaPlanner from '../../api/mediaPlanner';

import DatetimeRangePicker from "../components/ReactDateTimePicker"

const breadcrumbs = [
  {name: <i className="fas fa-home"></i>, href: "/", disabled: 0},
  {name: 'Media Planner', href: "/media-planner", disabled: 1},
];

let dataExcluded = ['usedViews', 'usedClicks'];

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tableCols : [],
      showActions: '',
    };
    this.mediaPlanner = new mediaPlanner();
  }


  /*
  * Helper methods
  * perform actions on the campaign table
  * views the campaigns details
  * */
  viewCampaign = (prop) => {
    console.log('view campaign');
    console.log(prop);
  }

  /*
  * Helper methods
  * perform actions on the campaign table
  * Approves the campaign
  * */
  approveCampaign = (prop) => {
    console.log('approve campaign');
    console.log(prop);
  }

  /*
  * Helper methods
  * perform actions on the campaign table
  * Rejects the campaign
  * */
  rejectCampaign = (prop) => {
    console.log('reject campaign');
    console.log(prop);
  }

  /*
  * Helper methods
  * perform actions on the campaign table
  * Reschedules the campaign
  * */
  rescheduleCampaign = (prop) => {
    console.log('reschedule campaign');
    console.log(prop);
  }

  /*
  * Helper methods
  * Shows color ranges from red to green depending on %
  * */
  getColor = (value) => {
    value = value/100;

    //value from 0 to 1
    let hue=((value)*120).toString(10);
    return ["hsl(",hue,",100%,50%)"].join("");
  }

  renameColumns = (key) => {
    let name = '';
    switch(key){
      case 'totalViews':
        name = 'Views';
        break;
      case 'totalClicks':
        name = 'Clicks';
        break;
      default:
        name = key.split(/(?=[A-Z])/).join(' ').toLowerCase();
        break;
    }
    return name;
  }

  /*
  * Before mounting the component,
  * prepares the data of the pending approval campaigns table
  * */
  componentWillMount = () => {
    this.mediaPlanner.getPublishersList().then((r)=> {
      let columns = [];
      if (r.data.length > 0) {
        let obj = r.data[0];
        for (let key in obj) {
          if(dataExcluded.indexOf(key) === -1){
            let name = this.renameColumns(key);
            switch(key) {
              default:
                columns.push({
                  Header: <span className="cognativex-table-header" title={name}>{name}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return info.original[key];
                  }
                });
                break;
              case "ctr":
              case "predictedViews":
                columns.push({
                  Header: <span className="cognativex-table-header" title={name}>{name}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return `${info.original[key]}%`;
                  }
                });
                break;
              case "fillRate":
                columns.push({
                  Header: <span className="cognativex-table-header" title={name}>{name}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <span className="badge badge-pill" style={{
                      backgroundColor: this.getColor(info.original[key]),
                      color: '#000',
                    }}>{info.original[key]}%</span>;
                  }
                });
                break;
              case "totalViews":
              case "totalClicks":
                let used = 'used' + key.split('total')[1];
                columns.push({
                  Header: <span className="cognativex-table-header" title={name}>{name}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    let percent = ((original[used]/original[key])*100).toFixed(1);

                    return <div className="progress-container">
                      <div>
                        <p style={{"fontSize": "85%"}}>{original[used]} <span style={{"color": "rgb(142, 142, 142)"}}>of {original[key]}</span></p>
                      </div>
                      <Progress max="100" value={percent}> <span className="progress-value">{percent}%</span> </Progress>
                    </div>
                  }
                });
                break;
              case "revenue":
                columns.push({
                  Header: <span className="cognativex-table-header" title={name}>{name}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return `$${info.original[key]}`;
                  }
                });
                break;
            }
          }
        }
      }

      this.setState({tableCols: columns}, ()=>{
        this.setState({data: r.data});
      });
    });
  }

  /*
  * Helper Function:
  * helps filtering out values in table
  * */
  searchForData = (text )=> {
    console.log(`search for ${text}`);
  }

  setDate = (date) => {
    this.setState(date);
  }

  networkStatus = () =>{
    return (
      <Col sm={12}>

        <Row className={"justify-content-end"}>
          <Col lg={"3"}>
            <DatetimeRangePicker
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              handler={this.setDate}
            />
          </Col>
        </Row>
        <Graph startDate={this.state.startDate} endDate={this.state.endDate}/>
        <Col className={classnames({
          'd-none' : this.state.data.length === 0
        })}>
          <Row className="col-md-12 col-xs-12 mt-3 mb-2 mx-0 justify-content-between align-items-end">
            <h4 className={"px-0 mb-2"} style={{color: 'white'}}>Publisher List</h4>
            <Row className={"px-2"}>
              <InputGroup
                className={classnames('ml-2', {
                  "input-group-focus": this.state.searchFocus
                })}
                style={{width: 'auto'}}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="search"
                  placeholder="Search"
                  type="text"
                  onChange={e => this.searchForData(e.target.value)}
                  onFocus={e => this.setState({ searchFocus: true })}
                  onBlur={e => this.setState({ searchFocus: false })}
                />
              </InputGroup>
            </Row>
          </Row>
          <Card>
            <CardBody>
              <ReactTable
                minRows={0}
                data={this.state.data}
                resizable={false}
                columns={this.state.tableCols}
                defaultPageSize={10}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </Col>
      </Col>
    );
  }

  render() {
    return (
      <>
        <div className="content">

            <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Card className="card-plain card-subcategories">
                  <CardBody style={{
                    'marginBottom': '-60px',
                    'position': 'relative',
                    'bottom': '60px'
                  }}>
                    {/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger" */}
                    <Nav
                      className="nav-pills-info nav-pills-icons justify-content-center"
                      pills
                    >
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/media-planner"
                        >
                          <i className="fa-info-circle fa" />
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/network-status"
                          onClick={(e)=>{e.preventDefault()}}
                          className={"active"}
                        >
                          <i className="tim-icons icon-molecule-40" />
                          Network Status
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/campaign-status"
                        >
                          <i className="fa-bullhorn fa" />
                          Campaign Status
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {this.networkStatus()}
        </div>
      </>
    );
  }
}

export default Overview;
