import axios from 'axios';

/* Helper Class
* To make requesting countries and regions more flexible
* */
class publisher {

  baseUrl = 'https://cognativex-campaign-portal.appspot.com';
  apiUrl = 'https://dashboard-api.cognativex.com';

  constructor(props) {
    this.details = props;
    if (window.location.hostname === 'localhost') {
      this.baseUrl = 'http://localhost:5000';
    }
  }

  // revenue page

  getRevenueByLocation = async (pid,queryString) => {
    return await axios.get(this.baseUrl + '/publisher-revenue-by-location/'+pid+'/?'+queryString);
  }

  getRevenueByCategory = async (pid , queryString) => {
    return await axios.get(this.baseUrl + '/publisher-revenue-by-category/'+pid+'/?'+queryString);
  }

  getRevenueSummary = async (queryString) => {
    return await axios.get(this.baseUrl + '/publisher-revenue-summary?'+queryString);
  }

  getRevenuetable = async (pid, queryString) => {
    return await axios.get(this.baseUrl + '/publisher-revenue-table/'+pid+'/?groupByFilter=' + queryString);
  }

  getPublisherWidgetList = async (pid) => {
    return await axios.get(this.baseUrl + '/publisher-widgets/' + pid);
  }

  getPublisherCategoryList = async (pid) => {
    return await axios.get(this.baseUrl + '/publisher-categories/' + pid);
  }

  getPublisherGeolocationList = async (pid) => {
    return await axios.get(this.baseUrl + '/publisher-geolocations/' + pid);
  }

  // overview page

  pageViews = async (queryStr, by) =>{
    switch (by) {
      case 'Hour':
        return await axios.get(this.apiUrl + '/pageviews_by_hour?' + queryStr);
        break;
      case 'Today':
        return await axios.get(this.apiUrl + '/pageviews_today?' + queryStr);
        break;
      case 'Referral':
        return await axios.get(this.apiUrl + '/pageviews_by_referral_today?' + queryStr);
        break;
      case 'Country':
        return await axios.get(this.apiUrl + '/pageviews_by_country_today?' + queryStr);
        break;
      case 'Section':
        return await axios.get(this.apiUrl + '/pageviews_by_section_today?' + queryStr);
        break;
      case 'Author':
        return await axios.get(this.apiUrl + '/pageviews_by_author_today?' + queryStr);
        break;
    }
  }

  widgetClicksToday = async (queryStr) =>{
    return await axios.get(this.apiUrl + '/widget_clicks_today?' + queryStr);
  }

}

export default publisher;
