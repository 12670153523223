import ReactTables from "views/tables/ReactTables.jsx";
import RegularTables from "views/tables/RegularTables.jsx";
import ExtendedTables from "views/tables/ExtendedTables.jsx";
import Wizard from "views/forms/Wizard.jsx";
import ValidationForms from "views/forms/ValidationForms.jsx";
import ExtendedForms from "views/forms/ExtendedForms.jsx";
import RegularForms from "views/forms/RegularForms.jsx";
import Buttons from "views/components/Buttons.jsx";
import SweetAlert from "views/components/SweetAlert.jsx";
import Notifications from "views/components/Notifications.jsx";
import Grid from "views/components/Grid.jsx";
import Typography from "views/components/Typography.jsx";
import Panels from "views/components/Panels.jsx";
import Pricing from "views/pages/Pricing.jsx";
import Register from "views/pages/Register.jsx";
import Timeline from "views/pages/Timeline.jsx";
import User from "views/pages/User.jsx";
import Login from "views/pages/Login.jsx";
import Rtl from "views/pages/Rtl.jsx";
import Lock from "views/pages/Lock.jsx";


import CampaignList from "views/campaign/List.jsx";
import CampaignPerformance from "views/campaign/Performance.jsx";
import CampaignCreate from "views/campaign/Create.jsx";
import CampaignManage from "views/mediaPlannner/campaignManage.jsx";
import MediaPlannerOverview from "views/mediaPlannner/overview.jsx";
import MediaPlannerNetworkStatus from "views/mediaPlannner/networkStatus.jsx";
import MediaPlannerCampaignStatus from "views/mediaPlannner/campaignStatus.jsx";
import PublisherRevenue from "views/publisher/publisherRevenue.jsx";
import PublisherOverview from "views/publisher/publisherOverview.jsx";

const routes = [
  {
    collapse: true,
    name: "Campaigns",
    rtlName: "حملات",
    icon: "tim-icons icon-volume-98",
    state: "campaignsCollapse",
    views: [
      {
        path: "/campaign-list",
        name: "List",
        rtlName: "جدول",
        mini: "L",
        rtlMini: "ج",
        component: CampaignList,
        layout: "/admin",
        rtlLayout: "/rtl"
      },
      {
        path: "/campaign-create",
        name: "Create",
        rtlName: "إنشاء",
        mini: "C",
        rtlMini: "إ",
        component: CampaignCreate,
        layout: "/admin",
        rtlLayout: "/rtl"
      },
      {
        path: "/campaign-edit/:id",
        name: "Edit",
        rtlName: "تحرير",
        mini: "E",
        rtlMini: "ت",
        component: CampaignCreate,
        invisible: true,
        layout: "/admin",
        withParams: true,
      },
      {
        path: "/campaign-performance/:id",
        name: "Performance",
        rtlName: "أداء",
        mini: "P",
        rtlMini: "أ",
        component: CampaignPerformance,
        invisible: true,
        layout: "/admin",
        withParams: true,
      },
      // {
      //   path: "/campaign-list",
      //   component: CampaignListAr,
      //   layout: "/rtl",
      // },
    ]
  },
  {
    collapse: true,
    name: "Media Planner",
    rtlName: "مخطط الإعلانات",
    icon: "tim-icons icon-puzzle-10",
    state: "mediaPlannerCollapse",
    views: [
      {
        path: "/media-planner",
        name: "Overview",
        rtlName: "نظرة عامة",
        mini: "O",
        rtlMini: "ن",
        component: MediaPlannerOverview,
        layout: "/admin",
        rtlLayout: "/rtl"
      },
      {
        path: "/network-status",
        name: "Network Status",
        rtlName: "حالة الشبكة",
        mini: "N",
        rtlMini: "ش",
        component: MediaPlannerNetworkStatus,
        layout: "/admin",
        rtlLayout: "/rtl"
      },
      {
        path: "/campaign-status",
        name: "Campaign Status",
        rtlName: "حالة الحملات",
        mini: "C",
        rtlMini: "ح",
        component: MediaPlannerCampaignStatus,
        layout: "/admin",
        rtlLayout: "/rtl"
      },
      {
        path: "/campaign-manage/:id",
        name: "Manage Campaign",
        rtlName: "إدارة الحملة",
        mini: "M",
        rtlMini: "إ",
        component: CampaignManage,
        invisible: true,
        layout: "/admin",
        withParams: true,
      }
    ]
  },
  {
    collapse: false,
    name: "Overview",
    rtlName: "نظرة عامة",
    icon: "fa fa-home",
    state: "pagesCollapse",
    path: "/overview",
    layout: "/admin",
    rtlLayout: "/rtl",
    component: PublisherOverview,
  },
  {
    collapse: false,
    name: "Revenue",
    rtlName: "إيرادات",
    icon: "tim-icons icon-money-coins",
    state: "pagesCollapse",
    path: "/revenue",
    layout: "/admin",
    rtlLayout: "/rtl",
    component: PublisherRevenue,
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: "tim-icons icon-image-02",
    state: "pagesCollapse",
    invisible: true,
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        rtlName: "عالتسعير",
        mini: "P",
        rtlMini: "ع",
        component: Pricing,
        layout: "/auth"
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: Rtl,
        layout: "/rtl"
      },
      {
        path: "/timeline",
        name: "Timeline",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: Timeline,
        layout: "/admin"
      },
      {
        path: "/login",
        name: "Login",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/lock-screen",
        name: "Lock Screen",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: Lock,
        layout: "/auth"
      },
      {
        path: "/user-profile",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: User,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: "tim-icons icon-molecule-40",
    state: "componentsCollapse",
    invisible: true,
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MLT",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            layout: "/admin"
          }
        ]
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin"
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: Grid,
        layout: "/admin"
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin"
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/admin"
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin"
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "tim-icons icon-notes",
    state: "formsCollapse",
    invisible: true,
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin"
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin"
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin"
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin"
      }
    ]
  },
  {
    invisible: true,
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: "tim-icons icon-puzzle-10",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin"
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin"
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin"
      }
    ]
  }
];

export default routes;
