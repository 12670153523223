import JSON from 'circular-json';
class CreateHelper {
  /*
* Helper function
* Helps convert 24h to 12h
* Ex. 13 -> 1pm , 5 -> 5am
* */

  convert24hTo12h = (time24h) => { // 0,1, 2, 23
    const h = (time24h % 12) || 12, ampm = (parseInt(time24h) < 12)? "am" : "pm";
    return h + ampm;
  }

  /*
  * Helper function
  * Helps convert 12h to 24h
  * Ex. 1pm -> 13 , 12am -> 00
  * */
  convert12hTo24h = (time12h) => { // 1pm 5am
    let hours = '', modifier= '';
    if(time12h.indexOf('pm') > -1){
      modifier = 'pm';
      hours = time12h.split('pm')[0];
    }else{
      modifier = 'am';
      hours = time12h.split('am')[0];
    }
    if (hours === '12') { hours = '00'; }
    if (modifier === 'pm') { hours = parseInt(hours, 10) + 12; }
    return hours;
  }

  /*
  * Helper function
  * Helps map the data to the structure the api needs.
  * */
  mapDataToApi (finalData) {
    // clone the object so that i can work without modifying the real object.
    let allStates = JSON.parse(JSON.stringify(finalData));

    // remove all unnecessary data from array to send
    delete allStates['Details']["temporary"];
    delete allStates['Details']["remainingCountries"];
    delete allStates['Creative']["languages"];
    for (var creativeInd in allStates['Creative']['creatives']){
      delete allStates['Creative']['creatives'][creativeInd]['file'];
      delete allStates['Creative']['creatives'][creativeInd]['ref'];
      delete allStates['Creative']['creatives'][creativeInd]['preview'];
      delete allStates['Creative']['creatives'][creativeInd]['identifier'];
      for(var creativeDetail in allStates['Creative']['creatives'][creativeInd]){
        if(creativeDetail.indexOf('State') > -1){
          delete allStates['Creative']['creatives'][creativeInd][creativeDetail];
        }
      }
    }
    // send the location data of what the api cares about
    for(let ind in allStates['Details']['locations']){
      allStates['Details']['locations'][ind] = allStates['Details']['locations'][ind]['alpha2Code'];
    }
    for(let ind in allStates['Details']['blockedLocations']){
      allStates['Details']['blockedLocations'][ind] = allStates['Details']['blockedLocations'][ind]['alpha2Code'];
    }

    // convert dates from
    // schedule: [
    //     {day: "Wednesday", hour: "2am"},
    //     {day: "Thursday", hour: "2am"}
    // ],
    // to the following
    // schedule: { "Wednesday": [1, 20], "Thursday": [2,3,8,13] }, // for chosen
    let schdObj = {};
    for(let ind in allStates['Details']['schedule']){
      let dayHour = allStates['Details']['schedule'][ind]; // {day: "Wednesday", hour: "1pm"},
      if(!schdObj[dayHour['day']]){
        // if the day isn't added to the schedule array then add it up
        // {wednesday: [convert12hTo24h(1pm)]} Thus  {wednesday: [13]}
        schdObj[dayHour['day']] = [this.convert12hTo24h(dayHour['hour']) + ''];
      }else{
        // push the hour to the day array.
        // {wednesday: [13, 2]}
        schdObj[dayHour['day']].push(this.convert12hTo24h(dayHour['hour']) + '');
      }
    }
    // When done the array is build so just replace the schedule with it
    allStates['Details']['schedule'] = schdObj;

    // Covert the target device from:
    // targetDevice: {
    //     mobile: {
    //         ios: true,
    //           android: false
    //     },
    //     tablet: {
    //         ios: false,
    //           android: true
    //     },
    //     desktop: true
    // },
    // to the following:
    // mobileIos: true,
    // mobileAndroid: true,
    // tabletIos: true,
    // tabletAndroid: true,
    // desktop: true,
    allStates['Details']['mobileIos'] = allStates['Details']['targetDevice']['mobile']['ios'];
    allStates['Details']['mobileAndroid'] = allStates['Details']['targetDevice']['tablet']['ios'];
    allStates['Details']['tabletIos'] = allStates['Details']['targetDevice']['mobile']['android'];
    allStates['Details']['tabletAndroid'] = allStates['Details']['targetDevice']['tablet']['android'];
    allStates['Details']['desktop'] = allStates['Details']['targetDevice']['desktop'];
    delete allStates['Details']["targetDevice"];

    return { ...allStates['Details'], ...allStates['Creative'] };
  }

  /*
  * Helper function
  * Helps map the data to the structure the api needs.
  * */
  mapApiToData (fetchedData) {
    // clone the object so that i can work without modifying the real object.
    let allStates = JSON.parse(JSON.stringify(fetchedData));

    // convert dates from
    // schedule: { "Wednesday": [1, 20], "Thursday": [2,3,8,13] }, // for chosen
    // to the following
    // schedule: [
    //     {day: "Wednesday", hour: "2am"},
    //     {day: "Thursday", hour: "2am"}
    // ],
    let schdArr = [];
    for(let day in allStates['schedule']){
      for(let i in allStates['schedule'][day]){
        let hour = this.convert24hTo12h(allStates['schedule'][day][i]);
        schdArr.push({day: day, hour: hour});
      }
    }
    // When done the array is build so just replace the schedule with it
    allStates['schedule'] = schdArr;

    // Covert the target device from:
    // mobileIos: true,
    // mobileAndroid: true,
    // tabletIos: true,
    // tabletAndroid: true,
    // desktop: true,
    //------------------
    // to the following:
    // targetDevice: {
    //     mobile: {
    //         ios: true,
    //           android: false
    //     },
    //     tablet: {
    //         ios: false,
    //           android: true
    //     },
    //     desktop: true
    // },
    allStates['targetDevice'] = {
      mobile: {
        ios: allStates['mobileIos'],
        android: allStates['mobileAndroid'],
      },
      tablet: {
        ios: allStates['tabletIos'],
        android: allStates['tabletAndroid'],
      },
      desktop: allStates['desktop']
    }
    delete allStates['mobileIos'];
    delete allStates['mobileAndroid'];
    delete allStates['tabletIos'];
    delete allStates['tabletAndroid'];
    delete allStates['desktop'];

    return allStates;
  }
}

export default new CreateHelper ();