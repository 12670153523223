import React from 'react';
import mediaPlanner from '../api/mediaPlanner';
// import {Line,Chart} from "react-chartjs-2";
import {Line} from "react-chartjs-2";

// helper function to draw out the graph with the colors and style needed
let graphOption = (canvas, hexColor, rgbaColor, steppedLine, fill) => {
  let ctx = canvas.getContext("2d");

  let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
  gradientStroke.addColorStop(1, "rgba("+rgbaColor+",0.2)");
  gradientStroke.addColorStop(0.4, "rgba("+rgbaColor+",0.0)");
  gradientStroke.addColorStop(0, "rgba("+rgbaColor+",0)");

  return {
    fill: fill,
    borderColor: "#"+hexColor,
    borderWidth: 3,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: "#"+hexColor,
    pointBorderWidth: 5,
    pointRadius: 4,
    pointBorderColor: 'rgba('+rgbaColor+',0.5)',
    pointHoverRadius: 4,
    pointHoverBorderWidth: 10,
    backgroundColor: gradientStroke,
    steppedLine: steppedLine
  }
}

class Revenue extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data: {}
    }
    this.months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    // get up the options of the graph
    this.options = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'rgb(255,255,255)',
        }
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9a9a9a",
              beginAtZero: true,

              min: 0,
              suggestedMax: 10000,
              stepSize: 1000,

              callback: function(value) {
                return '$' + value.toLocaleString();
              }
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9a9a9a",
              callback: (value) => {
                let mydate = new Date(value);
                return mydate.getDate() + ' ' + this.months[mydate.getMonth()]
              }
            }
          }
        ]
      }
    };
    // initialize the class to be able to run the ajax call to get the revenue details
    this.mediaPlanner = new mediaPlanner();
  }

  componentWillMount = () => {
    this.mediaPlanner.getRevenueGraphDetails().then((r)=>{
      let gatherData = {};
      // loop to separate all data alone
      for(let index in r.data){
        let object = r.data[index]; // {timestamp: "2019-02-01", revenue: "1000", avgRevenue: "2000", forecastRevenue: "3000"}
        for(let key in object){
          if(!gatherData[key]){
            gatherData[key] = [];
          }
          gatherData[key].push(object[key]);
        }
      }
      // now gatherData has:
      // {
      //   avgRevenue: (3) ["2000", "500", "4000"]
      //   forecastRevenue: (3) ["3000", "3500", "3500"]
      //   revenue: (3) ["1000", "3000", "5000"]
      //   timestamp: (3) ["2019-02-01", "2019-02-02", "2019-02-03"]
      // }
      this.setState({data: function(canvas){
          return {
            labels: gatherData.timestamp, // X-axis
            datasets: [
              {
                ...graphOption(canvas, '1f8ef1', '31,142,241', false, true),
                label: "Revenue ($)",
                data: gatherData.revenue, // Y-axis
                stroke: false,
              },
              {
                fill: false,
                borderColor: "#6C6C74",
                borderWidth: 3,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#6C6C74",
                pointHoverRadius: 0,
                pointHoverBorderWidth: 10,
                pointBorderWidth: 0,
                steppedLine: false,
                pointRadius: 0,
                label: "Forecast Revenue ($)",
                data: gatherData.forecastRevenue, // Y-axis
              }
            ]
          };
      }});
    });
  }

  render() {
    return (
        <Line
          data={this.state.data}
          options={this.options}
        />
    );
  }
}




export default Revenue;
