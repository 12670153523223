import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import loaderImage from "assets/img/loader.gif";
import classnames from 'classnames';

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.imagePreviewUrl ? this.props.imagePreviewUrl : defaultImage
    };
    this.allowed_ext = ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/bmp', 'image/tiff'];
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  updateImage = (image) =>{
    this.setState({'imagePreviewUrl' : image});
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      if(this.allowed_ext.indexOf(file.type) > -1){
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
          loader: loaderImage
        });
        this.props.handleChangeImage({
          file: file,
          imagePreviewUrl: reader.result,
          identifier: this.props.identifier
        });
        this.props.api.imageUload({campaign_key: this.props.api.details.campaignKey, image_ext: file.type.split('image/')[1]}, file).then((response)=>{
          if(response && response.data){
            this.setState({
              imagePreviewUrl: response.data.url,
              loader: ''
            });
            this.props.handleChangeImage({
              imagePreviewUrl: response.data.url,
              identifier: this.props.identifier,
            });
          }
        });
      }else{
        this.handleRemove('not-supported-file', 'Files supported are ' + this.allowed_ext.map((d)=>{
          return d.replace('image/', '');
        }).join(', '));
      }
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove(type, msg) {
    this.setState({
      file: null,
      imagePreviewUrl: defaultImage
    });
    this.refs.fileInput.value = null;
    this.props.handleRemoveImage({
      file: null,
      imagePreviewUrl: '',
      identifier: this.props.identifier,
      msg: (type === 'not-supported-file')?  {type: type, msg: msg} : null
    });
  }
  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div className={classnames("thumbnail", {
          "img-circle" : this.props.avatar,
          "cognativex-loader" : this.state.loader
        })}>
          <span className={"loader"} title={"Uploading"}></span>
          <img src={this.state.imagePreviewUrl} alt="uploaded creative" />
        </div>
        <div>
          {this.state.file === null ? (
            <Button
              color={this.props.addBtnColor}
              className={this.props.addBtnClasses}
              onClick={() => this.handleClick()}
            >
              {this.props.avatar ? "Add Photo" : "Select image"}
            </Button>
          ) : (
            <span>
              <Button
                color={this.props.changeBtnColor}
                className={this.props.changeBtnClasses}
                onClick={() => this.handleClick()}
              >
                Change
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button
                color={this.props.removeBtnColor}
                className={this.props.removeBtnClasses}
                onClick={() => this.handleRemove()}
              >
                <i className="fa fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary"
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  handleChangeImage: PropTypes.func,
  handleRemoveImage: PropTypes.func,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ])
};

export default ImageUpload;
