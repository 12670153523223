import React from "react";
import classnames from "classnames";
import Breadcrumbs from "../components/Breadcrumbs.jsx";
import ReactTable from "react-table";
import Population from "charts/population";
import NotificationAlert from "react-notification-alert";
import moment from 'moment';

import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  InputGroupAddon, InputGroupText, InputGroup, Button, Modal, ModalBody, CardHeader, Collapse
} from "reactstrap";
import mediaPlanner from '../../api/mediaPlanner';
import ReactBSAlert from "react-bootstrap-sweetalert";
import DatetimeRangePicker from "../components/ReactDateTimePicker"
import campaignDetails from '../../api/campaignDetails';


const breadcrumbs = [
  {name: <i className="fas fa-home"></i>, href: "/", disabled: 0},
  {name: 'Media Planner', href: "/admin/media-planner", disabled: 0},
  {name: 'Manage', href: "/admin/campaign-manage", disabled: 1},
];

let dataExcluded = ['publisherId', 'fillRateTolerance'];

class campaignManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tableCols: [],
      showActions: '',
      alert: null,
      campaignId: this.props.match.params.id,
      campaignName: '',
      modalClassic: false,
      openedCollapses: "collapseFour",
      campaignDetails: {},
      creatives: [],
      deliveryStatus: false
    };
    this.mediaPlanner = new mediaPlanner();
    this.campaignDetail = new campaignDetails({
      id: ((this.props.match.params.id)? this.props.match.params.id : '')
    });
    console.log(this.props.match.url);
  }

  confirm = (postData) => {
    this.mediaPlanner.postCampaignPublishers(postData).then((r)=> {
      this.setState({
        alert: (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Saved Successfully!"
            onConfirm={() => this.onHideAlert()}
            onCancel={() => this.onHideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
          </ReactBSAlert>
        )
      });
      setTimeout(()=>{
        this.props.history.push(`/admin/media-planner`);
      }, 1000)
    });
  }

  verifyFillRate = () => {
    for(let i in this.state.data){
      if(this.state.data[i]['fillRate'] + this.state.data[i]['fillRateTolerance'] > 100){
        return false;
      }
    }
    return true;
  }

  warnNetworkViews = () => {
    let arr = [];
    for(let i in this.state.data){
      if(!(parseFloat(this.state.data[i]['campaignPopulation']) - parseFloat(this.state.data[i]['viewsRequired']) >= 0)){
        arr.push(this.state.data[i]['publisherName']);
      }
    }
    let msg = arr.join(', ');
    if(msg === ''){
      return msg;
    }
    return  "No capability of delivering this campaign on " + msg;
  }

  /*
  * Action function
  * To approve campaign
  * */
  approveCampaign = () => {
    let error = false,
      allocation = parseFloat(this.sumAllocations());
    if (allocation < 100){
      error = true;
      this.setState({
        alert: (
          <ReactBSAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title="Allocation!"
            onConfirm={() => this.onHideAlert()}
            onCancel={() => this.onHideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            The sum of allocations should be <b>100%</b>
          </ReactBSAlert>
        )
      });
    }
    if(!this.verifyFillRate()){
      error = true;
      this.setState({
        alert: (
          <ReactBSAlert
            error
            style={{ display: "block", marginTop: "-100px" }}
            title="Fill Rate!"
            onConfirm={() => this.onHideAlert()}
            onCancel={() => this.onHideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Fill Rate should not exceed <b>100%</b>
          </ReactBSAlert>
        )
      });
    }
    if(!error){
      // no more errors now save and redirect to the media planner overview page
      let postData = {
        campaignId: this.state.campaignId,
        campaignName: this.state.campaignName,
        publisherList: this.state.data
      },
      percent = this.printTotalClicks().percent,
      msg = '',
      allocationMsg = '';
      if(!(percent > 107 && percent <= 115)){
        msg = <div><span style={{background: 'rgba(0,0,0,0.7)', padding: '0 4px', borderRadius: '6px'}}>{this.printTotalClicks().msg}<br/></span></div>;
      }
      if(allocation > 100){
        allocationMsg = <span>{"You are allocating more clicks than required for this campaign. are you sure you want to proceed?"}<br/></span>
      }

      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Saving!"
            onConfirm={() => {this.onHideAlert(); this.confirm(postData);}}
            onCancel={() => this.onHideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            {msg}
            {allocationMsg}
            {this.warnNetworkViews()}
          </ReactBSAlert>
        )
      });
    }
  }


  /*
  * Event helper function
  * To open the modal for rescheduling the campaign
  * */
  onRescheduleCampaign = () =>{
    this.toggleModalClassic();
  }

  /*Helper function to notify about the actions being pressed*/
  notify = (place, type, icon, msg) => {
    var options = {
      place: place,
      message: msg,
      type: type,
      icon: icon,
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  }


  /*
  * Action helper function
  * To reschedule campaign
  * */
  rescheduleCampaign = () => {
    let data = {
      campaignId: this.state.campaignId,
      fromDate: this.state.tempDate.startDate,
      toDate: this.state.tempDate.endDate
    };
    this.mediaPlanner.postCampaignReschedule(data).then((r) => {
      this.setState({
        alert: (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Rescheduled!"
            onConfirm={() => this.onHideAlert()}
            onCancel={() => this.onHideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Please, please edit allocations as found suitable and approve !
          </ReactBSAlert>
        )
      });
    });
  }

  /*
  * Event helper function
  * To hide the alert box
  * */
  onHideAlert = () => {
    this.setState({
      alert: null
    });
  };

  /*
  * Event helper function
  * */
  onSuccessReject = () => {
    this.mediaPlanner.postCampaignReject({campaignId: this.state.campaignId}).then((r)=>{
      this.setState({
        alert: (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Rejected!"
            onConfirm={() => this.onHideAlert()}
            onCancel={() => this.onHideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            You have rejected the<br/><b>{this.state.campaignName}</b> campaign
          </ReactBSAlert>
        )
      }, ()=>{
        setTimeout(()=>{
          this.props.history.push(`/admin/media-planner`);
        }, 1500)
      });
    });
  };

  /*
  * Action function
  * To reject campaign
  * */
  rejectCampaign = () =>{
    console.log('rejectCampaign');
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.onSuccessReject()}
          onCancel={() => this.onHideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, reject it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You won't be able to see this campaign again
        </ReactBSAlert>
      )
    });
  }

  getColor = (value) => {
    value = value/100;

    //value from 0 to 1
    let hue=((1-value)*120).toString(10);
    return ["hsl(",hue,",100%,50%)"].join("");
  }


  /*
  * Before mounting the component,
  * prepares the data of the pending approval campaigns table
  * */
  componentWillMount = () => {
    this.mediaPlanner.getCampaignPublishers(this.props.match.params.id).then((r)=> {
      let data = r.data.publisherList;
      let columns = [];
      if (data.length > 0) {
        let obj = data[0];
        for (let key in obj) {
          if(dataExcluded.indexOf(key) === -1){
            switch(key) {
              default:
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return info.original[key];
                  }
                });
                break;
              case "allocation":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return (
                      <InputGroup
                        className={classnames('mb-0',{
                          "input-group-focus": this.state['allocateFocus'+info.index]
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-percent" aria-hidden="true"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="allocation"
                          placeholder="allocate"
                          type="text"
                          value={info.original.allocation}
                          onChange={e => this.onChange('allocation', info.index, e.target.value)}
                          onFocus={e => this.setState({ ['allocateFocus'+info.index]: true })}
                          onBlur={e => this.setState({ ['allocateFocus'+info.index]: false })}
                        />
                      </InputGroup>
                    );
                  }
                });
                break;
              case "requestedClicks":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return (
                      <InputGroup
                        className={classnames('mb-0',{
                          "input-group-focus": this.state['requestedClicksFocus'+info.index]
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-hashtag" aria-hidden="true"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="requestedClicks"
                          placeholder="Requested Clicks"
                          type="text"
                          value={info.original.requestedClicks}
                          onChange={e => this.onChange('requestedClicks' ,info.index, e.target.value)}
                          onFocus={e => this.setState({ ['requestedClicksFocus'+info.index]: true })}
                          onBlur={e => this.setState({ ['requestedClicksFocus'+info.index]: false })}
                        />
                      </InputGroup>
                    );
                  }
                });
                break;
              case "fillRate":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return (
                      <Row className={'px-0 mx-0 align-items-center justify-content-between'} style={{width: '100%'}}>
                        <span className={"mx-1 my-0"} title={"Current Fill Rate"}>{info.original.fillRate}%</span>
                        <h4 className={classnames("my-0")} title={"Change In Fill Rate"}>+{info.original.fillRateTolerance}%</h4>
                        <h4 title="Able to deliver" className={classnames('my-0', {
                          'd-none' : (info.original.fillRate + info.original.fillRateTolerance > 60)
                        })}><i className={"fa fa-check"} style={{color: 'green'}}></i></h4>
                        <h4 title="Reaching full capacity" className={classnames('my-0', {
                          'd-none' : (info.original.fillRate + info.original.fillRateTolerance <= 60 || info.original.fillRate + info.original.fillRateTolerance > 90)
                        })}><i className={"fa fa-exclamation"} style={{color: 'yellow'}}></i>
                        </h4>
                        <h4 title="May impact other campaigns" className={classnames('my-0', {
                          'd-none' : (info.original.fillRate + info.original.fillRateTolerance <= 90 || info.original.fillRate + info.original.fillRateTolerance > 100)
                        })}>
                          <i className={"fa fa-exclamation"} style={{color: 'red'}}></i>
                          <i className={"fa fa-exclamation"} style={{color: 'red'}}></i>
                        </h4>
                        <h4 title="Cannot deliver" className={classnames('my-0', {
                          'd-none' : (info.original.fillRate + info.original.fillRateTolerance < 100)
                        })}><i className={"tim-icons icon-simple-remove"} style={{color: 'red'}}></i></h4>
                      </Row>
                    );
                  }
                });
                break;
              case "ctr":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return `${info.original.ctr}%`;
                  }
                });
                break;
              case "viewsRequired":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return (
                      <Row className={'px-0 mx-0 align-items-center justify-content-between'} style={{width: '100%'}}>
                        <span className={"mx-1 my-0"}>{info.original.viewsRequired}</span>

                        <h4 title={`Network size (${info.original.campaignPopulation}) cannot deliver the required views/clicks`} className={classnames('my-0', {
                          'd-none' : (parseFloat(info.original.campaignPopulation) - parseFloat(info.original.viewsRequired) >= 0)
                        })}>
                          <i className={"tim-icons icon-simple-remove"} style={{color: 'red'}}></i>
                        </h4>
                      </Row>
                    );
                  }
                });
                break;
            }
          }
        }
      }
      this.setState({tableCols: columns}, ()=>{
        this.setState({data: data});
        this.setState({campaignName: r.data.campaignName});
      });
    });
    this.campaignDetail.getCampaignDetails(this.props.match.params.id).then((r)=>{
      let data = r.data;
      data.locations = data.locations.join(', ');
      data.blockedLocations = data.blockedLocations.join(', ');
      data.targetCategories = data.targetCategories.join(', ');

      data.mobile = [];
      if(data.mobileAndroid) data.mobile.push('android');
      if(data.mobileIos) data.mobile.push('ios');
      data.mobile = data.mobile.join(', ');

      data.tablet = [];
      if(data.tabletAndroid) data.tablet.push('android');
      if(data.tabletIos) data.tablet.push('ios');
      data.tablet = data.tablet.join(', ');

      let startDate = new Date(data.startDateTime);
      data.startDateTime = startDate.getDate() + '-' + (startDate.getMonth()+1) + '-' + startDate.getFullYear();

      let endDate = (data.endDateTime)? new Date(data.endDateTime) : '-';
      data.endDateTime = (endDate === '-')? endDate : endDate.getDate() + '-' + (endDate.getMonth()+1) + '-' + endDate.getFullYear();

      let schedule = '';
      for(let day in data.schedule){
        schedule +=  `${day} [Hours: ${data.schedule[day].join(', ')}] `;
      }
      data.schedule = schedule;

      this.setState({creatives: data.creatives});

      this.setState({campaignDetails: data});
    });
  }

  /*
  * Event Helper Function:
  * helps set the allocation changes
  * */
  onChange = (name, key, value)=> {
    let data = this.state.data;
    if(value === '') value = 0;
    data[key][name] = parseFloat(value);
    this.setState({data: data}, ()=> {
      switch(name) {
        default:
        case 'allocation':
          // value is the allocation
          // clicks = (allocation * totalRequestedClicks)/100
          data[key]['requestedClicks'] = parseFloat((parseFloat(value) * parseFloat(this.state.campaignDetails.totalClicks) / 100).toFixed(2));
          // views = clicks / (ctr*100)
          data[key]['viewsRequired'] = parseFloat((data[key]['requestedClicks']/(parseFloat(data[key]['ctr'])/100)).toFixed(2));
          // p.FR = views / N.population
          data[key]['fillRateTolerance'] = parseFloat((data[key]['viewsRequired']/parseFloat(data[key]['networkPopulation'])).toFixed(2));
          break;
        case 'requestedClicks':
          // value is the requestedClicks
          // allocation = (clicks * 100)/totalRequestedClicks
          data[key]['allocation'] = parseFloat(((parseFloat(value) * 100) / parseFloat(this.state.campaignDetails.totalClicks)).toFixed(2));
          // views = clicks / (ctr*100)
          data[key]['viewsRequired'] = parseFloat((parseFloat(value)/(parseFloat(data[key]['ctr'])/100)).toFixed(2));
          // p.FR = views / N.population
          data[key]['fillRateTolerance'] = parseFloat((data[key]['viewsRequired']/parseFloat(data[key]['networkPopulation'])).toFixed(2));
          break;
      }

      if(data[key]['fillRateTolerance'] + parseFloat(data[key]['fillRate']) === 100){
        this.notify("tc", 'warning', 'fa fa-exclamation' , data[key]['publisherName'] + '\'s Fill Rate Reached 100%');
      }else if(data[key]['fillRateTolerance'] + parseFloat(data[key]['fillRate']) > 100){
        this.notify("tc", 'danger', 'tim-icons icon-simple-remove' , data[key]['publisherName'] + '\'s Fill Rate Exceeded 100%');
      }

      this.setState({data: data});
    });
  }


  /*
  * Helper Function:
  * Helps sum allocations
  * */
  sumAllocations = () => {
    return this.state.data.map((value)=>{
      return parseFloat(value.allocation);
    }).reduce(function(a, b) { return a + b; }, 0).toString()
  }

  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = (e, collapse) => {
    e.preventDefault();
    this.setState({
      openedCollapses: collapse
    });
  };

  /*
  * Render helper Function:
  * */
  campaignDetails = () => {
    return (
      <div className={"px-4 col-sm-6"} id={'campaign-details'}>
        <label className={"pl-4 mt-5"} style={{color: 'white'}}>Campaign Details: </label>
        <Card className={'col-sm-12 mt-2'}>
          <div
            aria-multiselectable={true}
            className="card-collapse"
            id="accordion"
            role="tablist"
          >
            <Card className="card-plain">
              <CardHeader role="tab">
                <a
                  style={{color: 'white'}}
                  aria-expanded={this.state.openedCollapses === "collapseOne"}
                  href="#pablo"
                  data-parent="#accordion"
                  data-toggle="collapse"
                  onClick={e => this.collapsesToggle(e, "collapseOne")}
                >
                  <span className={"fa fa-info-circle"}></span> Info {" "}
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(
                  "collapseOne"
                )}
              >
                <CardBody>
                  <div>
                    <span style={{color: '#29b3e8'}}><i className={"fa fa-bullhorn"}></i> Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.campaignName}</span>
                  </div>
                  <div>
                    <span style={{color: '#29b3e8'}}><i className={"fa fa-list"}></i> Category:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.category}</span>
                  </div>
                  <div>
                    <span style={{color: '#29b3e8'}}><i className={"fa fa-address-card"}></i> Brand Name:&nbsp; </span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.brandName}</span>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
            <Card className="card-plain">
              <CardHeader role="tab">
                <a
                  style={{color: 'white'}}
                  aria-expanded={this.state.openedCollapses === "collapseTwo"}
                  href="#pablo"
                  data-parent="#accordion"
                  data-toggle="collapse"
                  onClick={e => this.collapsesToggle(e, "collapseTwo")}
                >
                  <span className={"tim-icons icon-compass-05"}></span> Target {" "}
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(
                  "collapseTwo"
                )}
              >
                <CardBody>
                  <div>
                    <span style={{color: '#29b3e8'}}><i className={"tim-icons icon-square-pin"}></i> Locations:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.locations}</span>
                  </div>
                  <div>
                    <span style={{color: '#29b3e8'}}><i className={"fa fa-ban"}></i> Blocked Location:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.blockedLocations}</span></div>
                  <div>
                    <span style={{color: '#29b3e8'}}><i className={"fa fa-users"}></i> Audience Categories:&nbsp;</span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.targetCategories}</span></div>
                  {(this.state.campaignDetails.mobile !== '')?
                    <div>
                      <span style={{color: '#29b3e8'}}><i className={"fa fa-mobile"}></i> Mobile:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{color: 'white'}}>Enabled ({this.state.campaignDetails.mobile})</span>
                    </div>
                   : ''}
                  {(this.state.campaignDetails.tablet !== '')?
                    <div>
                      <span style={{color: '#29b3e8'}}><i className={"fa fa-tablet"}></i> Tablet:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{color: 'white'}}>Enabled ({this.state.campaignDetails.tablet})</span>
                    </div>
                    : ''}
                  {(this.state.campaignDetails.desktop)?
                    <div>
                      <span style={{color: '#29b3e8'}}><i className={"fa fa-desktop"}></i> Desktop:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                      <span style={{color: 'white'}}>Enabled</span></div>
                    : ''}
                </CardBody>
              </Collapse>
            </Card>
            <Card className="card-plain">
              <CardHeader role="tab">
                <a
                  style={{color: 'white'}}
                  aria-expanded={this.state.openedCollapses === "collapseThree"}
                  href="#pablo"
                  data-parent="#accordion"
                  data-toggle="collapse"
                  onClick={e => this.collapsesToggle(e, "collapseThree")}
                >
                  <span className={"tim-icons icon-time-alarm"}></span> Kick off{" "}
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(
                  "collapseThree"
                )}
              >
                <CardBody>
                  <div>
                    <span style={{color: '#29b3e8'}}><i className={"fa fa-calendar-check"}></i> Start Date:&nbsp; </span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.startDateTime}</span>
                  </div>
                  <div>
                    <span style={{color: '#29b3e8'}}><i className={"fa fa-calendar-times"}></i> End Date:&nbsp;&nbsp; </span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.endDateTime}</span>
                  </div>
                  {(this.state.isScheduled)? <div><i className={"fa fa-calendar"}></i> Schedule:&nbsp;&nbsp; <span style={{color: 'white'}}>{this.state.campaignDetails.schedule}</span></div> : ''}
                </CardBody>
              </Collapse>
            </Card>
            <Card className="card-plain">
              <CardHeader role="tab">
                <a
                  style={{color: 'white'}}
                  aria-expanded={this.state.openedCollapses === "collapseFour"}
                  href="#pablo"
                  data-parent="#accordion"
                  data-toggle="collapse"
                  onClick={e => this.collapsesToggle(e, "collapseFour")}
                >
                  <span className={"tim-icons icon-money-coins"}></span> Budget{" "}
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(
                  "collapseFour"
                )}
              >
                <CardBody>
                  {(!this.state.campaignDetails.isCpmEnabled)?
                    <div>
                      <div>
                        <span style={{color: '#29b3e8'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CPC:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style={{color: 'white'}}>{this.state.campaignDetails.cpc}$</span>
                      </div>
                      <div>
                        <span style={{color: '#29b3e8'}}><i className={"tim-icons icon-tap-02"}></i> Clicks:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: 'white'}}>{this.state.campaignDetails.totalClicks}</span>
                      </div>
                      <div><span style={{color: '#29b3e8'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Budget:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: 'white'}}>{this.state.campaignDetails.totalCpcBudget}$</span></div>
                    </div>
                    :
                    <div>
                      <div>
                        <span style={{color: '#29b3e8'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CPM:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style={{color: 'white'}}>{this.state.campaignDetails.cpm}$</span>
                      </div>
                      <div>
                        <i className={"tim-icons icon-tap-02"}></i> Impressions:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color: 'white'}}>{this.state.campaignDetails.totalImpressions}</span>
                      </div>
                      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Budget:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color: 'white'}}>{this.state.campaignDetails.totalCpmBudget}$</span></div>
                    </div>
                  }
                  <div><span style={{color: '#29b3e8'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Daily Ad Delivery:&nbsp;</span>
                    <span style={{color: 'white'}}>{this.state.campaignDetails.dailyBudget}$</span>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
            <Card className="card-plain">
              <CardHeader role="tab">
                <a
                  style={{color: 'white'}}
                  aria-expanded={this.state.openedCollapses.includes(
                    "collapseFive"
                  )}
                  href="#pablo"
                  data-parent="#accordion"
                  data-toggle="collapse"
                  onClick={e => this.collapsesToggle(e, "collapseFive")}
                >
                  <span className={"tim-icons icon-palette"}></span> Creatives{" "}
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(
                  "collapseFive"
                )}
              >
                <CardBody>
                  <Row className={"creatives-container align-items-center"}>
                    {this.state.creatives.map((value, key)=>{
                      return (
                        <div className={"creative-item col-sm-6 mb-3"} key={'creative-item-' + key}>
                          <Row>
                            <Col className={'col-sm-4 px-0'}>
                              <img src={value.imagePreviewUrl} alt={value.title} style={{float: 'right'}} />
                            </Col>
                            <Row className={"align-items-center col-sm-8"}>
                              <Col>
                                <div><span style={{color: '#29b3e8'}}>Title:</span> <span style={{color: 'white'}}>{value.title}</span></div>
                                <div><span style={{color: '#29b3e8'}}>Url:</span> <a style={{color: 'white'}} href={value.clickUrl}>{value.clickUrl}</a></div>
                                <div><span style={{color: '#29b3e8'}}>Lang:</span> <span style={{color: 'white'}}>{value.lang.label}</span></div>
                              </Col>
                            </Row>
                          </Row>
                        </div>
                      );
                    })}
                  </Row>
                </CardBody>
              </Collapse>
            </Card>
          </div>
        </Card>
      </div>
    );
  }

  /*
   * Print visual clicks:
   * */
  printTotalClicks = () => {
    let totalForecast = 0;
    for(let d in this.state.data){
      totalForecast += parseFloat(this.state.data[d].requestedClicks);
    }
    let msg = '', icons = {};
    let percent = (totalForecast/this.state.campaignDetails.totalClicks*100).toFixed(2);
    if(percent < 100) {
      msg = 'Under deliver';
      icons = {class: 'tim-icons icon-simple-remove', color: 'red'};
    } else if(percent >= 100 && percent <= 107) {
      msg = 'Caution of under delivery';
      icons = {class: 'fa fa-exclamation', color: 'orange'};
    }else if(percent > 107 && percent <= 115) {
      msg = 'Delivering with 15% margin';
      this.setState({'deliveryStatus' : false});
      icons = {class: 'fa fa-check', color: 'green'};
    }else if(percent > 115) {
      msg = 'Caution of over delivery';
      icons = {class: 'fa fa-exclamation', color: 'yellow'};
    }

    return {
      requested: this.state.campaignDetails.totalClicks,
      totalForecast: totalForecast,
      percent: percent,
      text: msg,
      msg: <span style={{color: icons.color}}>{msg} <i className={icons.class}></i></span>
    };
  }

  /*
  * Render helper Function:
  * */
  campaignApproval = () =>{
    return (
      <Col sm={12}>
        <Col className={classnames({
          'd-none' : this.state.data.length === 0
        })}>
          <Row className={'align-items-end'}>
            <Card className={'col-sm-6 pt-4'}>
              <Card className="card-plain">
                <Population/>
              </Card>
            </Card>
            {this.campaignDetails()}
          </Row>
          <Row  className="col-md-12 col-xs-12 mt-3 mb-2 mx-0 justify-content-between align-items-center">
            <label style={{color: 'white'}} className={'mr-auto'}><b>{this.state.campaignName}</b> Publisher List:</label>
          </Row>
          <Card>
            <CardBody>
              <ReactTable
                minRows={0}
                data={this.state.data}
                resizable={false}
                columns={this.state.tableCols}
                // defaultPageSize={10}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
          <Col  className="col-md-12 col-xs-12 mt-3 mb-2 mx-0">
            <div>
              <label style={{color: 'white'}} className={'mr-auto'}>
                Total Allocation: {(this.sumAllocations() !== '100')? <span style={{color: 'red'}}>{this.sumAllocations()}</span> : this.sumAllocations()}%
              </label>
            </div>
            <div>
              <label style={{color: 'white'}} className={'mr-auto'}>
                Total Clicks: <b>{this.printTotalClicks().requested}/{this.printTotalClicks().totalForecast}</b> <h6>{this.printTotalClicks().msg} ({this.printTotalClicks().percent}%)</h6>
              </label>
            </div>
          </Col>
        </Col>
      </Col>
    );
  }

  /*
  * Helper function
  * for the classic modal
  * */
  toggleModalClassic = () => {
    this.setState({
      modalClassic: !this.state.modalClassic
    });
  };

  setDate = (date) => {
    this.setState({'tempDate': date});
  }

  render() {
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        {this.state.alert}
        <Modal
          isOpen={this.state.modalClassic}
          toggle={this.toggleModalClassic}
        >
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalClassic}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Reschedule the campaign</h6>
          </div>
          <ModalBody className="text-center">
            <Row className={"justify-content-center"}>

              <DatetimeRangePicker
                startDate={moment(this.state.campaignDetails.startDateTime, 'DD-MM-YYYY')}
                endDate={moment(this.state.campaignDetails.endDateTime,'DD-MM-YYYY')}
                handler={this.setDate}
              />


              {/*<ReactDatetime*/}
                {/*value={this.state.toDate}*/}
                {/*onChange= {e => this.setState({toDate: e._d.toISOString()})}*/}
                {/*onFocus= {e => this.setState({ toDateFocus: true })}*/}
                {/*onBlur = {e => this.setState({ toDateFocus: false })}*/}
                {/*closeOnTab*/}
                {/*timeFormat={false}*/}
                {/*inputProps={{*/}
                  {/*className: "form-control",*/}
                  {/*placeholder: "To Date",*/}
                  {/*name: 'toDate',*/}
                  {/*style:{color: 'black'}*/}
                {/*}}*/}
                {/*className={'col-sm-6'}*/}
              {/*/>*/}
            </Row>
          </ModalBody>
          <div className="modal-footer">
            <Button
              color="default"
              type="button"
              onClick={()=>{this.rescheduleCampaign();this.toggleModalClassic();}}
            >
              Reschedule
            </Button>
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={()=>{this.toggleModalClassic(); this.setState({startDate: ''}); this.setState({toDate: ''});}}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <div className="content">

            <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

            {this.campaignApproval()}
            <Row className={"justify-content-center"}>
              <Button color="info" onClick={()=>{this.approveCampaign()}}>Approve</Button>
              <Button color="primary" onClick={()=>{this.onRescheduleCampaign()}}>Reschedule</Button>
              <Button color="warning" onClick={()=>{this.rejectCampaign()}}>Reject</Button>
            </Row>
        </div>
      </>
    );
  }
}

export default campaignManage;
