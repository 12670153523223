import React from "react";
import classnames from "classnames";
// reactstrap components
import {
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup, Modal, ModalBody, Row, Col,
} from "reactstrap";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";
import placeholder from "assets/img/placeholder.jpg";
import placeholder1 from "assets/img/placeholder1.png";
import placeholder2 from "assets/img/placeholder2.jpg";
import placeholder3 from "assets/img/placeholder3.jpg";
import Select from "react-select";
import ReactCropper from "../../components/ReactCropper/ReactCropper"

class CreativeBox extends React.Component {

    constructor(props) {
        // init the constructor of the parent react component
        super(props);

        // init the state
        this.state = {
            identifier: this.props.identifier,
            title: this.props.title,
            titleState: "", // for the title
            clickUrl: this.props.clickUrl,
            clickUrlState: "",// click URL
            file: this.props.file,
            lang: this.props.lang,
            imagePreviewUrl: this.props.imagePreviewUrl, // for the image upload
            imageUrlState: "", // for the image Url text field that is deleted
            preview : {
                img: '',
                title: '',
                clickUrl: ''
            }
        };
        this.imageUploadWidget = React.createRef();
    }


    /* Helper function
    * To verify the length of the field,  Purpose is to make sure there is a value for the field
    * */
    verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    /* Helper function
    * verifies if value is a valid URL
    * */
    verifyUrl = value => {
        try {
            new URL(value);
            return true;
        } catch (_) {
            return false;
        }
    };
    handleRemoveImage = (data) =>{
        this.setState({imagePreviewUrl: data.imagePreviewUrl, file: data.file, imageUrlState: "has-danger", msg : (data.msg && data.msg.msg)? data.msg.msg: ''});
    }

    handleChangeImage = (data) => {
        this.setState({imagePreviewUrl: data.imagePreviewUrl, file: data.file, imageUrlState: "has-success", msg: null});
    }

    setCroppedImage = (croppedImage) => {
        let dataPreview = this.state.preview;
        dataPreview['img'] = croppedImage;
        this.setState({preview: dataPreview});
    }

    /* Helper function
    * To detect the changes on each field to apply validations
    * */
    change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        let targetValue = '';
        if(type === 'select') {
            targetValue = event;
        }else{
            targetValue = event.target.value;
        }
        switch (type) {
            case "length":
                if (this.verifyLength(targetValue, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "url":
                if (this.verifyUrl(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "select":

                if(targetValue.length === 0){
                    this.setState({ [stateName + "State"]: "has-danger" });
                }else{
                    this.setState({ [stateName + "State"]: "has-success" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: targetValue });
    };

    /* Helper function
    * To validations on submission
    * */
    isValidated = () => {
        this.props.sendStep3AllDataUpdates(this.state);
        if (
            this.state.imagePreviewUrl !== "" &&
            this.state.clickUrl !== "" &&
            this.state.title !== ""
        ) {
            return true;
        } else {
            if (this.state.imagePreviewUrl === "") {
                this.setState({ imageUrlState: "has-danger" });
            }
            if (this.state.clickUrlState !== "has-success") {
                this.setState({ clickUrlState: "has-danger" });
            }
            if (this.state.titleState !== "has-success") {
                this.setState({ titleState: "has-danger"});
            }
        }
    };

    /* Helper function
    * Renders the uploader
    * */
    renderUploader = ()=>{
        return (
            <div className="">
                <div className="text-center">
                    <ImageUpload
                        api={this.props.campaignDetailsApi}
                        imagePreviewUrl={this.state.imagePreviewUrl}
                        changeBtnClasses="btn-simple"
                        addBtnClasses="btn-simple"
                        removeBtnClasses="btn-simple"
                        ref={this.imageUploadWidget}
                        identifier={this.identifier}
                        handleRemoveImage={ this.handleRemoveImage }
                        handleChangeImage={ this.handleChangeImage }
                    />
                </div>
            </div>
        );
    };

    previewCreative(){
        let data = {
            img: this.state.imagePreviewUrl,
            title: this.state.title,
            clickUrl: this.state.clickUrl
        }
        this.setState({preview: data});
        this.toggleModalNotice();
    }

    toggleModalNotice = () => {
        // open or close preview modal
        this.setState({
            modalNotice: !this.state.modalNotice
        });
    };


    /* Helper function
    * Render functions for each section
    * */
    renderFields  = () => {
        return (
            <div className="col-lg-4 col-sm-6">
                <div className="cognativex-card-box mt-2">
                    <InputGroup
                      className={this.state.imageUrlState + " row px-0 mx-0 mb-0 justify-content-center"}
                    >
                    {this.renderUploader()}
                    {this.state.imageUrlState === "has-danger" && !this.state.msg? (
                      <label className="error text-center">Please upload an image.</label>
                    ) : null}
                    {this.state.imageUrlState === "has-danger" && this.state.msg? (
                      <label className="error text-center">{this.state.msg}</label>
                    ) : null}

                    </InputGroup>
                    <div>
                        <InputGroup
                          className={classnames(this.state.titleState, {
                              "input-group-focus": this.state.titleFocus
                          })}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="icon-caps-small tim-icons" aria-hidden="true"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              name="title"
                              placeholder="Ad Title "
                              type="text"
                              maxLength={23}
                              value={this.state.title}
                              onChange={e => this.change(e, "title", "length", 1)}
                              onFocus={e => this.setState({ titleFocus: true })}
                              onBlur={e => this.setState({ titleFocus: false })}
                            />
                        </InputGroup>
                    </div>
                    <div>
                        <InputGroup
                          className={classnames(this.state.clickUrlState, {
                              "input-group-focus": this.state.clickUrlFocus
                          })}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="icon-attach-87 tim-icons" aria-hidden="true"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              name="clickUrl"
                              placeholder="Ad Click Url *"
                              type="text"
                              value={this.state.clickUrl}
                              onChange={e => this.change(e, "clickUrl", "url", "url")}
                              onFocus={e => this.setState({ clickUrlFocus: true })}
                              onBlur={e => this.setState({ clickUrlFocus: false })}
                            />
                            {this.state.clickUrlState === "has-danger" ? (
                              <label className="error">Please enter a valid URL.</label>
                            ) : null}
                        </InputGroup>
                    </div>
                    <div>
                        <Select
                          className="pb-4 react-select info"
                          classNamePrefix="react-select"
                          value={this.state.lang}
                          name="language-creative"
                          placeholder={"Language"}
                          options={this.props.languages}
                          onChange={e => { this.setState({lang: e})}}
                        />
                    </div>
                    <div className={"icon-design-button d-flex justify-content-end"}>
                        <a href="/#" className={"cursor-pointer d-flex align-content-center"} title={"Preview"} onClick={(e)=>{e.preventDefault(); this.previewCreative();}}>
                            <i className="tim-icons icon-image-02"></i>
                        </a>
                        <a href="/#" className={"cursor-pointer d-flex align-content-center"} title={"Delete"} onClick={(e)=>{e.preventDefault(); this.props.deleteFunction();}}>
                            <i className="tim-icons icon-trash-simple"></i>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    renderPlaceholder  = (placeholderImg, classes = "col-sm-4",  full = 0) =>{
        let placeholderDom = "";
        switch (full) {
            default:
               placeholderDom = (
                 <Col className={ classes + " cognativex-bottom1-card cognativex-bottom1-content2 px-2"}>
                   <div className="cognativex-bottom1-article-container">
                       <div style={{backgroundImage: `url(${placeholderImg})`}} className="placeholder-img cognativex-bottom1-image-container cognativex-type-main-image-container-article">
                       </div>
                   </div>
                 </Col>
               );
               break;
            case "full":
                placeholderDom = (
                  <Col className={"px-2  cognativex-bottom1-card cognativex-bottom1-content2 " + classes}>
                      <div className="cognativex-bottom1-article-container">
                          <img src={placeholderImg} alt={"placeholder"} className={"img-fluid"}/>
                      </div>
                  </Col>
                );
                break;
        }
        return(placeholderDom);
    }

    saveImage = (imageToSave) => {
        // on save btn click of the react cropper component ..
        // saves the image url  to the ImageUpload component
        this.setState({'imagePreviewUrl': imageToSave}, ()=>{
            this.imageUploadWidget.current.updateImage(imageToSave);
        }, ()=>{
            // close the modal

        });
    }

    // Render
    render() {
        return (
            <>
                {this.renderFields()}
                <Modal
                  isOpen={this.state.modalNotice}
                  toggle={this.toggleModalNotice}
                  className={'cognativex-modal'}
                >
                    <div className="modal-header">
                        <button
                          aria-hidden={true}
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={this.toggleModalNotice}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h5 className="modal-title" id="myModalLabel">
                            <b>Adjust & Preview</b>
                        </h5>
                    </div>
                    <ModalBody className="text-center pt-3">
                        <p className={"pb-5"}>
                            This is an example to give you an idea of how your ad will feel in some of our widgets.
                        </p>
                        <div className="cognativex-preview-container">
                            <div className={"col-sm-6"}>
                                <ReactCropper saveBtnTxt={"Save"} saveBtnClicked={this.saveImage} src={this.state.preview.img} cropBtnTxt={'Crop and Preview'} callback={this.setCroppedImage}/>
                            </div>
                            <div className={"cognativex-preview"}>
                                <section id="demo">
                                    <div className="row mx-0">
                                        <aside className="col-sm-3">
                                            <h4>Side bar sample</h4>
                                            <div id="cognativex-widget-56506878630297670"
                                                 className="cognativex-widget cognativex-sidebar-style cognativex-widget-recommendation-side-bar"
                                            >
                                                <div className="cognativex-template-56506878630297670">
                                                    <div className="cognativex-side-bar1-styling">
                                                        <div className="cognativex-side-bar1-container cognativex-side-bar1-main-box">
                                                            <div className="cognativex-side-bar1-card">
                                                                <a href={this.state.preview.clickUrl} target="_blank" rel="noopener noreferrer">
                                                                    <div
                                                                      className="cognativex-type-main-image-container-video">
                                                                        <img className="cognativex-side-bar1-image"
                                                                             alt="Avatar"
                                                                             src={(this.state.preview.img === '')? defaultImage: this.state.preview.img}/>
                                                                    </div>
                                                                    <div className="cognativex-side-bar1-title">
                                                                        <h4 className={"text-center pt-0 mb-0"}>{(this.state.preview.title === '')? 'Title' : this.state.preview.title}</h4>
                                                                        <p>{(typeof (window.brandtext) === 'undefined')? 'Brand text' : window.brandtext}</p>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            {this.renderPlaceholder(placeholder, 'col-sm-12 border-placeholder', 'full')}
                                                            {this.renderPlaceholder(placeholder, 'col-sm-12 border-placeholder', 'full')}
                                                            {this.renderPlaceholder(placeholder, 'col-sm-12 border-placeholder', 'full')}
                                                            {this.renderPlaceholder(placeholder, 'col-sm-12 border-placeholder', 'full')}
                                                            {this.renderPlaceholder(placeholder, 'col-sm-12 border-placeholder', 'full')}
                                                            {this.renderPlaceholder(placeholder, 'col-sm-12 border-placeholder', 'full')}
                                                            {this.renderPlaceholder(placeholder, 'col-sm-12 border-placeholder', 'full')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </aside>
                                        <div className="col-sm-9">
                                            <div id="cognativex-widget-56506878630297610" className="cognativex-widget cognativex-widget-recommendation-banner">
                                                <div  className="cognativex-feeds1-container cognativex-feeds1-block-title"> <h4>Banner sample</h4></div>
                                                <div className="cognativex-template-56506878630297610">
                                                    <div className="cognativex-banner1-styling">
                                                        <div className="cognativex-banner1-container cognativex-banner1-main-box">
                                                            <div className="cognativex-banner1-contained1">
                                                                <Col className={"pl-0"}>
                                                                    <div className="cognativex-bottom1-article-container">
                                                                        <a className={"cognativex-bottom1-link"} href={this.state.preview.clickUrl} target="_blank" rel="noopener noreferrer">
                                                                            <div style={{backgroundImage: `url(${(this.state.preview.img === '')? defaultImage: this.state.preview.img})`}} className="cognativex-bottom1-image-container cognativex-type-main-image-container-article">
                                                                            </div>
                                                                            <div className="cognativex-bottom1-title">
                                                                                <h5>{(this.state.preview.title === '')? 'Title' : this.state.preview.title}</h5>
                                                                                <p className="cognativex-bottom1-text" >{(typeof (window.brandtext) === 'undefined')? 'Brand text' : window.brandtext}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                            <div className="cognativex-banner1-contained2">
                                                                {this.renderPlaceholder(placeholder3, "col-sm-6 pb-2", "full")}
                                                                {this.renderPlaceholder(placeholder3, "col-sm-6 pb-2", "full")}
                                                                {this.renderPlaceholder(placeholder3, "col-sm-6", "full")}
                                                                {this.renderPlaceholder(placeholder3, "col-sm-6", "full")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="cognativex-widget-56506878630297660"
                                                 className="cognativex-widget cognativex-widget-recommendation-feed">
                                                <div className="cognativex-template-56506878630297660">
                                                    <div className="cognativex-feeds1-styling">
                                                        <div
                                                          className="cognativex-feeds1-container cognativex-feeds1-block-title">
                                                            <h4>Feeds sample</h4></div>
                                                        <div className="cognativex-feeds1-container cognativex-feeds1-main-box">
                                                            <a post_id="5886" className="cognativex-feeds1-link" href={this.state.preview.clickUrl}>
                                                                <div className="cognativex-feeds1-card cognativex-feeds1-content2">
                                                                <div className="cognativex-feeds1-article-container">
                                                                    <div
                                                                      className="cognativex-feeds1-image-container"
                                                                      style={{backgroundImage: `url(${(this.state.preview.img === '')? defaultImage: this.state.preview.img})`}}
                                                                    >
                                                                    </div>
                                                                    <div className="cognativex-feeds1-title text-left">
                                                                        <h4>{(this.state.preview.title === '')? 'Title' : this.state.preview.title}</h4>
                                                                        <p className="cognativex-feeds1-details">
                                                                            {(typeof (window.brandtext) === 'undefined')? 'Brand text' : window.brandtext}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div></a>
                                                            {this.renderPlaceholder(placeholder2,'col-sm-12', 'full')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="cognativex-widget-56506878630297650"
                                                 className="cognativex-widget cognativex-widget-recommendation-bottom"
                                            >
                                                <div className="cognativex-template-56506878630297650">
                                                    <div className="cognativex-bottom2-styling">
                                                        <Row className={"col-sm-12 pt-3 px-0 mx-0"}>
                                                            <h4 className={'d-block col-sm-12'}>Read-more sample</h4>
                                                            {this.renderPlaceholder(placeholder1)}
                                                            <Col className="cognativex-bottom2-card cognativex-bottom2-content2 px-2" sm={"4"}>
                                                                <div className="cognativex-bottom2-article-container">
                                                                    <div
                                                                      className="cognativex-bottom2-image-container cognativex-type-main-image-container-article" style={{backgroundImage: `url(${(this.state.preview.img === '')? defaultImage: this.state.preview.img})`}}>
                                                                    </div>
                                                                    <div className="cognativex-bottom2-title">
                                                                        <h5 className={"text-center"}>{(this.state.preview.title === '')? 'Title' : this.state.preview.title}</h5>
                                                                        <p className="cognativex-bottom2-text" >{(typeof (window.brandtext) === 'undefined')? 'Brand text' : window.brandtext}</p>
                                                                        <a className="cognativex-bottom2-link" href={this.state.preview.clickUrl} target={"_blank"} rel="noopener noreferrer">
                                                                            <h5> Read more &gt; </h5>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            {this.renderPlaceholder(placeholder1)}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="cognativex-widget-56506878630297620"
                                                 className="cognativex-widget cognativex-widget-recommendation-bottom"
                                            >
                                                <div className="cognativex-template-56506878630297620">
                                                    <div className="cognativex-bottom1-styling">
                                                        <div
                                                          className="cognativex-bottom1-container cognativex-bottom1-block-title">
                                                            <h4 className={'col-sm-12'}>Bottom sample</h4></div>
                                                            <Row className={"col-sm-12 pb-3 px-0 mx-0"}>
                                                            {this.renderPlaceholder(placeholder)}
                                                            <Col className=" cognativex-bottom1-card cognativex-bottom1-content2 px-2" sm={"4"}>
                                                                <div className="cognativex-bottom1-article-container">
                                                                    <a className={"cognativex-bottom1-link"} href={this.state.preview.clickUrl} target="_blank" rel="noopener noreferrer">
                                                                        <div style={{backgroundImage: `url(${(this.state.preview.img === '')? defaultImage: this.state.preview.img})`}} className="cognativex-bottom1-image-container cognativex-type-main-image-container-article">
                                                                        </div>
                                                                        <div className="cognativex-bottom1-title">
                                                                            <h5>{(this.state.preview.title === '')? 'Title' : this.state.preview.title}</h5>
                                                                            <p className="cognativex-bottom1-text" >{(typeof (window.brandtext) === 'undefined')? 'Brand text' : window.brandtext}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Col>
                                                            {this.renderPlaceholder(placeholder)}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    };
}

export default CreativeBox;
