import React from "react";
import classnames from "classnames";
import Breadcrumbs from "../components/Breadcrumbs.jsx";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Progress,
  InputGroupAddon, InputGroupText, InputGroup, Nav, NavItem, NavLink, Button
} from "reactstrap";
import Graph1 from "charts/campaignStatusGraph";
import Graph2 from "charts/campaignStatusClicksGraph";
import Graph3 from "charts/campaignStatusBudgetGraph";
import mediaPlanner from '../../api/mediaPlanner';

const breadcrumbs = [
  {name: <i className="fas fa-home"></i>, href: "/", disabled: 0},
  {name: 'Media Planner', href: "/media-planner", disabled: 1},
];

let dataExcluded = ['campaignId', 'campaignCreationDate', 'totalBudget', 'totalClicks', 'minCpc', 'maxCpc', 'campaignPeriodDays'];

class campaignStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tableCols : [],
      showActions: '',
    };
    this.mediaPlanner = new mediaPlanner();
  }


  /*
  * Helper methods
  * perform actions on the campaign table
  * Approves the campaign
  * */
  manageCampaign = (prop) => {
    this.props.history.push(`/admin/campaign-manage/${prop.campaignId}`);
  }

  /*
  * Helper function:
  * Helps populates the action buttons
  * */
  populateData = (data = []) => {
    return data.map((prop, key) => {
      prop.index = key;
      prop.id = prop.campaignId;
      prop.actions =  (
        <div className={"text-right action-btns-"+prop.index+"-"+prop.id} key={prop.id+key}>
          {/*use this button to accept a campaign*/}
          <Button
            onClick={() => {this.manageCampaign(prop)}}
            color="warning"
            size="sm"
            className={classnames(" mx-0 my-0")}
            id={"tooltip03-" + prop.id}
          >
            Manage
          </Button>
        </div>
      );
      return prop;
    });
  }

  printProgress = (numerator,denominator, percent ,title) => {

    return <div className="progress-container" title={title}>
      <div>
        <p style={{"fontSize": "85%"}}>{numerator} <span style={{"color": "rgb(142, 142, 142)"}}>of {denominator}</span></p>
      </div>
      <Progress max="100" value={percent}> <span className="progress-value">{percent}%</span> </Progress>
    </div>;
  }

  /*
  * Before mounting the component,
  * prepares the data of the pending approval campaigns table
  * */
  componentWillMount = () => {
    this.mediaPlanner.getCampaignList().then((r)=> {
      let columns = [];
      if (r.data.length > 0) {
        let obj = r.data[0];
        for (let key in obj) {
          if(dataExcluded.indexOf(key) === -1){
            switch(key) {
              default:
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <span title={info.original[key]}>{info.original[key]}</span>;
                  }
                });
                break;
              case "campaignStatus":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>Status</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <span title={info.original[key]}>{info.original[key]}</span>;
                  }
                });
                break;
              case "remainingDays":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    let daysPassed = original['campaignPeriodDays'] - original[key];
                    let percent = ((daysPassed/original['campaignPeriodDays'])*100).toFixed(1);
                    return this.printProgress(original[key], original['campaignPeriodDays'], percent,"remaining days / Total days");
                  }
                });
                break;
              case "clicksAchieved":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    let percent = ((original[key]/original['totalClicks'])*100).toFixed(1);
                    return this.printProgress(original[key], original['totalClicks'], percent,'Clicks Achieved / Total Clicks Needed');
                  }
                });
                break;
              case "budgetSpent":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    let percent = ((info.original[key] / info.original.totalBudget)*100).toFixed(1);
                    return this.printProgress(info.original[key]+ '$', info.original.totalBudget, percent,'Budget spent / Total Budget');
                  }
                });
                break;
              case "avgCpc":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>CPC</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <div className={"ranges-min-avg-max"}>
                      <span className={'min'} title={"Min Cpc: " + info.original.minCpc}><span className={"text"}>{info.original.minCpc}</span><span className={'label'}>Min</span></span>
                      <span className={"avg"} title={"Avg Cpc: " + info.original[key]}><span className={"text"}>{info.original[key]}</span><span className={'label'}>Avg</span></span>
                      <span className={"max"} title={"Max Cpc: " + info.original.maxCpc}><span className={"text"}>{info.original.maxCpc}</span><span className={'label'}>Max</span></span>
                    </div>;
                  }
                });
                break;
              case "targetStatus":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    return <div className="progress-container"> <Progress max="100" value={original[key]}> <span className="progress-value">{original[key]}%</span> </Progress> </div>
                  },
                });
                break;
            }
          }
        }
      }
      columns.push({
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        width: 100
      });
      this.setState({tableCols: columns}, ()=>{
        this.setState({data: this.populateData(r.data)});
      });
    });
  }

  /*
  * Helper Function:
  * helps filtering out values in table
  * */
  searchForData = (text )=> {
    console.log(`search for ${text}`);
  }

  /*
  * Helper Render Function:
  * renders the revenue graph
  * */
  renderKpis = () => {
    return(
      <div className={'col-sm-12'}>
        <Row>
          <Graph1/>
        </Row>
        <Row>
          <Col sm={"6"}>
            <Graph2/>
          </Col>
          <Col sm={"6"}>
            <Graph3/>
          </Col>
        </Row>
      </div>
    );
  }

  campaignStatus = () =>{
    return (
      <Col sm={12}>
        {this.renderKpis()}
        <Col className={classnames({
          'd-none' : this.state.data.length === 0
        })}>
          <Row  className="col-md-12 col-xs-12 mt-3 mb-2 mx-0 justify-content-between align-items-end">
            <h4 className={"px-0 mb-2"} style={{color: 'white'}}>Campaign List</h4>
            <div className={"px-2 col-sm-5"}>
              <InputGroup
                className={classnames({
                  "input-group-focus": this.state.searchFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="search"
                  placeholder="Search"
                  type="text"
                  onChange={e => this.searchForData(e.target.value)}
                  onFocus={e => this.setState({ searchFocus: true })}
                  onBlur={e => this.setState({ searchFocus: false })}
                />
              </InputGroup>
            </div>
          </Row>
          <Card>
            <CardBody>
              <ReactTable
                minRows={0}
                data={this.state.data}
                resizable={false}
                columns={this.state.tableCols}
                defaultPageSize={10}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </Col>
      </Col>
    );
  }

  render() {
    return (
      <>
        <div className="content">

            <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Card className="card-plain card-subcategories">
                  <CardBody style={{
                    'marginBottom': '-60px',
                    'position': 'relative',
                    'bottom': '60px'
                  }}>
                    {/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger" */}
                    <Nav
                      className="nav-pills-info nav-pills-icons justify-content-center"
                      pills
                    >
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/media-planner"
                        >
                          <i className="fa-info-circle fa" />
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/network-status"
                        >
                          <i className="tim-icons icon-molecule-40" />
                          Network Status
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/campaign-status"
                          onClick={(e)=>{e.preventDefault()}}
                          className={"active"}
                        >
                          <i className="fa-bullhorn fa" />
                          Campaign Status
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {this.campaignStatus()}
        </div>
      </>
    );
  }
}

export default campaignStatus;
