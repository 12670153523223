import React from 'react';
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import classnames from "classnames";

import campaignDetails from '../../api/campaignDetails';

class Performance extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      cols: ['Budget Spent', 'Clicks', 'CPC'],
      rows: ['time'],
      dateFilter: {from: '', to: ''},
      data: []
    };
    this.campaignDetail = new campaignDetails();
  }

  componentDidMount() {
    window.google.load("visualization", "1", {packages:["corechart", "charteditor"]});
    let renderers = window.$.extend(window.$.pivotUtilities.renderers, window.$.pivotUtilities.gchart_renderers);
    this.campaignDetail.performance(this.props.match.params.id).then((r)=>{
      let data = r.data,
      rows = ['Name', 'Status', 'budget', 'clicks', 'impressions', 'cost per click', 'amount spent', 'end time', 'last edited'];

      if(data.length){
        rows = Object.keys(data[0]);
        window.$("#pivot-chart").pivotUI(data, {
          renderers: renderers,
          rows: rows,
          rendererName: "Heatmap"
        });
      }
    });
  }

  filterWithDate(e, type){
    let dateFilter = this.state.dateFilter;
    let date = '';
    switch(type){
      case 'start':
        date = e._d.toISOString();
        console.log('filter with start date', date);
        dateFilter.from = date;
        break;
      case 'end':
        date = e._d.toISOString();
        dateFilter.to = date;
        console.log('filter with end date', date);
        break;
      default:
        console.log('clear date filter');
        dateFilter = {from: '', to: ''};
        break;
    }
    this.setState({dateFilter: dateFilter});
  }

  render() {
    return (
      <>
        <div className="content performance-table">
          <Row className={"col-sm-12 pb-4 align-items-center"}>
            <InputGroup
              className={classnames('cognativex-date-time col-sm-2' , {
                "input-group-focus": this.state.startdateFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-calendar-60" aria-hidden="true"></i>
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                onChange= {e => {this.filterWithDate(e, 'start')}}
                onFocus= {e => this.setState({ startdateFocus: true })}
                onBlur = {e => this.setState({ startdateFocus: false })}
                closeOnTab
                timeFormat={false}
                inputProps={{
                  className: "cognativex-datetime form-control",
                  placeholder: "Filter From Date",
                  name: 'startdate',
                  value: this.state.dateFilter.from
                }}
              />
            </InputGroup>
            <InputGroup
              className={classnames('cognativex-date-time col-sm-2' , {
                "input-group-focus": this.state.enddateFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-calendar-60" aria-hidden="true"></i>
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                onChange= {e => {this.filterWithDate(e, 'end')}}
                onFocus= {e => this.setState({ enddateFocus: true })}
                onBlur = {e => this.setState({ enddateFocus: false })}
                closeOnTab
                timeFormat={false}
                inputProps={{
                  className: "cognativex-datetime form-control",
                  placeholder: "Filter To Date",
                  name: 'enddate',
                  value: this.state.dateFilter.to
                }}
              />
            </InputGroup>
            <a
              href={"/#"}
              onClick={(e)=>{e.preventDefault(); this.filterWithDate(e, 'clear')}}
              className={classnames({'d-none': (this.state.dateFilter.from === '' && this.state.dateFilter.to === '')})}
            >
              <i className={"icon-simple-remove tim-icons"}></i> Clear
            </a>
          </Row>
          <div id={"pivot-chart"}></div>
        </div>
      </>
    );
  }
}
export default Performance;