import React from "react";

// reactstrap components
import {Row, UncontrolledAlert} from "reactstrap";
import CreativeBox from "../../components/CreativeBox";
import countries from "../../../api/countries";

class Wizard extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        creatives: (this.props.creatives)? this.props.creatives : [],
        languages: []
      };
      // initializes the country class that provides with the helper functions
      this.countriesApi = countries;
      // fetches all the countries
      this.countriesApi.getCountries().then((result) => {
          this.setState({languages: this.getLanguages(result.data)});
      });
  }

  getLanguages(res){
    let lang_added = [];
    let lang = [];
    for (let i in res){
      let resLang = res[i]['languages'];
      for (let j in resLang){
        let langName = resLang[j].name;
        if(!(lang_added.indexOf(langName) > -1)){
          // if yet not added then append to array
          lang_added.push(langName);
          lang.push({label: langName, value: resLang[j].iso639_1});
        }
      }
    }

    return lang.sort(function(a, b) {
      let nameA = a.label.toLowerCase();
      let nameB = b.label.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0;
    });
  }

  componentWillMount() {
    if(this.props.id){
      // There is an id for the page
      // then this is an edit page
      // loop through the existing ones and simply give each an identifier
      let creatives = this.state.creatives;
      for(let i in creatives){
        creatives[i]['identifier'] = i;
        creatives[i]['ref'] = React.createRef() // for the ref
      }
      this.setState({creatives: creatives});
    }else{
      // if no page id exists means we are in the add page
      // and if we are in the add page then:
      // add one creative by default
      this.addCreative();
    }
  }
  returnCountCreatives = () =>{
    let count = 0;
    for (let i in this.state.creatives){
      if(!this.state.creatives[i]['deleted']) count++;
    }
    return count;
  }
  isValidated = () => {
    let res = true, creatives = this.state.creatives;
    if(!this.returnCountCreatives()){ return false;}
    for (let i in creatives){
      if(typeof creatives[i].deleted === 'undefined'){
        if(!creatives[i].ref.current.isValidated()){ res = false;}
      }
    }
    return res;
  };
  addCreative = () => {
    let creativesRef = this.state.creatives,
    data = {
      identifier: creativesRef.length,
      title: "",
      clickUrl: "",
      file: null,
      lang: '',
      imagePreviewUrl: "", // for the image upload
      imageUrlState: "", // for the image field URL
      ref: React.createRef() // for the ref
    };
    creativesRef.push(data);
    this.setState({creatives: creativesRef});
  }
  updateCreative = (creativeId, data) => {
    let creativesRef = this.state.creatives;
    for (let i in this.state.creatives){
      let creative = creativesRef[i];

      if(creative.identifier === creativeId){

        // here we found it so update..
        // so what we need to do is del that from array  and re push it
        // save the ref
        data['ref'] = creativesRef[i].ref;
        creativesRef[i] = data;
        this.setState({creatives: creativesRef});
        return;
      }
    }
    // here we did not find it so push the item to the array
    creativesRef.push(data);
    this.setState({creatives: creativesRef})
  }
  getAllDataFromComponentUpdates = (componentData) => {
    this.updateCreative(componentData.identifier, componentData);
  }
  deleteCreative(creativeBox){
    let creativesRef = this.state.creatives;
    creativesRef[creativeBox.identifier]['deleted'] = 1;
    this.setState({creatives: creativesRef});
  }
  render() {
    return (
      <>
        <div>
          <div className="card-header py-0">
            <h4 className="card-title mt-0 mb-3">
              <i className="fas fa-info-circle"></i> Add Creative ({this.returnCountCreatives()})&nbsp;&nbsp;&nbsp;
              <a className="add-more-creatives float-right" onClick={(e)=>{e.preventDefault(); this.addCreative();}} href="/#"><span><i className="fa fa-plus-circle " aria-hidden="true"></i> Add</span></a>
            </h4>
          </div>
          <Row className={"content"}>
              {this.returnCountCreatives() === 0 ? (
                <UncontrolledAlert color="info" fade={false}>
                    <span>
                      Add at least <b>One</b> creative to proceed !
                    </span>
                </UncontrolledAlert>
              ) : null}
              {this.state.creatives.map((creativeBox, index)=>{
                if(typeof creativeBox.deleted === 'undefined'){
                  return (
                      <CreativeBox
                        {...creativeBox}
                        campaignDetailsApi={this.props.campaignDetailsApi}
                        sendStep3AllDataUpdates={this.getAllDataFromComponentUpdates}
                        key={'creative-key-' + creativeBox.identifier}
                        deleteFunction={()=>{this.deleteCreative(creativeBox)}}
                        languages={this.state.languages}
                      />
                  )
                }
                return ''
              })}
          </Row>
        </div>
      </>
    );
  }
}

export default Wizard;
