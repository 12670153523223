import React from 'react';
import mediaPlanner from '../api/mediaPlanner';
import {Bar} from "react-chartjs-2";
import {
  Row, Card, CardBody
} from "reactstrap";
import mainHelper from "../helpers/main";

let helpers = new mainHelper();

let colorset = helpers.cxColorSet;

let hexToRgb = (hex) => {
  hex   = hex.replace('#', '');
  let r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16),
    g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16),
    b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
  return r + ', ' + g + ', ' + b;
}

class CampaignStatusClicksGraph extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data: {},
      gatherData: {}
    }

    // get up the options of the graph
    this.options = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'rgb(255,255,255)',
        }
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "#aaaaaa"
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "#aaaaaa"
            },
            ticks: {
              callback: function(value){
                return value.toLocaleString();
              }
            }
          }
        ]
      }
    };
    // initialize the class to be able to run the ajax call to get the budget details
    this.mediaPlanner = new mediaPlanner();
  }

  componentWillMount = () => {
    this.mediaPlanner.getCampaignStatusClicksGraphDetails().then((r)=>{
      let gatherData = {};
      // loop to separate all data alone
      for(let index in r.data){
        let object = r.data[index]; // {PublisherName: "MBC, campaigns: [...]}
        for (let key in object){
          switch (key) {
            default:
              if(!gatherData[key]) gatherData[key] = [];
              gatherData[key].push(object[key]);
              break;
            case 'details':
              for(let c in object[key]){
                let publisherKey = object[key][c]['publisherName'] + ' publisher';
                if(!gatherData[publisherKey]) gatherData[publisherKey] = [];
                gatherData[publisherKey].push(object[key][c]['clicks']);
              }
              break;
          }
        }

      }

      this.clicksVsViews(gatherData, 'clicks');
      this.setState({gatherData: gatherData});
    });
  }


  /*
  * Helper Function:
  * */
  clicksVsViews = (data, type = 'clicks') => {
    let gatherData = JSON.parse(JSON.stringify(data));
    this.setState({
      data: function(canvas){
        let ctx = canvas.getContext("2d");

        let r = {labels: gatherData['campaignName'], datasets: []};

        r.datasets.push({
          fill: true,
          stack: gatherData['campaignName'],
          borderColor: '#727272',
          borderWidth: 3,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: '#727272',
          pointBorderWidth: 5,
          pointRadius: 4,
          pointBorderColor: 'rgba('+ hexToRgb('#727272') +',0.5)',
          pointHoverRadius: 4,
          pointHoverBorderWidth: 10,
          backgroundColor: '#727272',
          label: 'Remaining',
          data: gatherData.remainingClicks
        });
        let colorindex = 0;
        for (let i in gatherData){
          if(i.indexOf(' publisher') > -1){
            let hexColor = colorset[colorindex%colorset.length];
            colorindex++;
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba("+ hexToRgb(hexColor) +",0.3)");
            gradientStroke.addColorStop(0.4, "rgba("+ hexToRgb(hexColor) +",0.1)");
            gradientStroke.addColorStop(0, "rgba("+ hexToRgb(hexColor) +",0)");
            r.datasets.push({
              fill: true,
              stack: gatherData['campaignName'],
              borderColor: hexColor,
              borderWidth: 3,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: hexColor,
              pointBorderWidth: 5,
              pointRadius: 4,
              pointBorderColor: 'rgba('+ hexToRgb(hexColor) +',0.5)',
              pointHoverRadius: 4,
              pointHoverBorderWidth: 10,
              backgroundColor: gradientStroke,
              label: i.split(' publisher')[0],
              data: gatherData[i]
            });
          }
        }
        return r;
      }
    });
  }

  render() {
    return (
      <>
        <div className="chart-area pt-3 col-sm-12">
          <Row>
              <Card className="card-chart pt-2" style={{minHeight: '300px'}}>
                <Row className={"justify-content-between mx-0 px-2"}>
                  <h4 className="card-title pt-2 px-1 pb-2">Breakdown of Clicks by Publisher</h4>
                </Row>
                <CardBody>
                  <Bar
                    data={this.state.data}
                    options={this.options}
                  />
                </CardBody>
              </Card>
          </Row>
        </div>
      </>
    );
  }
}




export default CampaignStatusClicksGraph;
