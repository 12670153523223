import React from 'react';
import mediaPlanner from '../api/mediaPlanner';
import campaignDetails from '../api/campaignDetails';

import {Line} from "react-chartjs-2";
import {
  Row,
  Card, CardBody, InputGroupAddon, InputGroupText, InputGroup, Col
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import DatetimeRangePicker from "../views/components/ReactDateTimePicker"
import mainHelper from "../helpers/main";
let helpers = new mainHelper();
let colorset = helpers.cxColorSet;

let hexToRgb = (hex) => {
  hex   = hex.replace('#', '');
  let r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16),
    g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16),
    b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
  return r + ', ' + g + ', ' + b;
}

class CampaignStatusGraph extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data: {},
      gatherData: {},
      campaignList: []
    }
    this.months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    // get up the options of the graph
    this.options = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'rgb(255,255,255)',
        }
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9a9a9a",
              callback: function(value){
                return value.toLocaleString();
              }
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 1,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9a9a9a",
              callback: (value) => {
                let mydate = new Date(value);
                return mydate.getDate() + ' ' + this.months[mydate.getMonth()]
              }
            }
          }
        ]
      }
    };

    // initialize the class to be able to run the ajax call to get the revenue details
    this.mediaPlanner = new mediaPlanner();

    this.campaignList = new campaignDetails();
  }


  requestData = (cid = 0) => {
    this.mediaPlanner.getCampaignStatusGraphDetails(cid).then((r)=>{
      let gatherData = {};
      // loop to separate all data alone
      for(let index in r.data){
        let object = r.data[index]; // {PublisherName: "MBC, details: [...]}
        for (let key in object){
          switch (key) {
            default:
              if(!gatherData[key]) gatherData[key] = [];
              gatherData[key].push(object[key]);
              break;
            case 'publishers':
              for(let p in object[key]){
                let publisherClicksKey = object[key][p]['publisherName'] + ' publisherclicks';
                if(!gatherData[publisherClicksKey]) gatherData[publisherClicksKey] = [];
                gatherData[publisherClicksKey].push(object[key][p]['clicks']);
              }
              break;
          }
        }
      }
      this.clicksVsViews(gatherData);
      this.setState({gatherData: gatherData});
    });
  }

  componentWillMount = () => {
    // requests the graph with total campaigns
    this.requestData();
    this.campaignList.getCampaignList().then((r)=>{
      let defaultValue = { value: 0, label: 'Total'};
      let list = [defaultValue];
      this.setState({graphQueryString : list[0]});
      for(let i in r.data){
        let detail = r.data[i];
        list.push({
          value: detail.campaignId, label: detail.campaignName
        });
      }
      this.setState({campaignList: list});
    });
  }

  /*
  * Helper Function:
  * */
  clicksVsViews = (data, type = 'clicks') => {
    let gatherData = JSON.parse(JSON.stringify(data));
    this.setState({
      data: function(canvas){

        let ctx = canvas.getContext("2d");

        // choosing timestamp for the y axis
        let r = {labels: gatherData['timestamp'], datasets: []};

        // adding the publishers values of views or clicks
        let colorindex = 0;
        for (let i in gatherData){
          if(i.indexOf(' publisher'+type) > -1){
            // let hexColor = generateColors();
            let hexColor = colorset[colorindex%colorset.length];
            colorindex++;
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba("+ hexToRgb(hexColor) +",0.3)");
            gradientStroke.addColorStop(0.4, "rgba("+ hexToRgb(hexColor) +",0.1)");
            gradientStroke.addColorStop(0, "rgba("+ hexToRgb(hexColor) +",0)");
            r.datasets.push({
              fill: true,
              borderColor: hexColor,
              borderWidth: 3,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: hexColor,
              pointBorderWidth: 5,
              pointRadius: 4,
              pointBorderColor: 'rgba('+ hexToRgb(hexColor) +',0.5)',
              pointHoverRadius: 4,
              pointHoverBorderWidth: 10,
              backgroundColor: gradientStroke,
              label: i.split(' publisher'+type)[0],
              data: gatherData[i]
            });
          }
        }

        // adding total clicks or total views
        r.datasets.push({
          fill: false,
          borderColor: "#6C6C74",
          borderWidth: 3,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#6C6C74",
          pointHoverRadius: 0,
          pointHoverBorderWidth: 10,
          pointBorderWidth: 0,
          steppedLine: false,
          pointRadius: 0,
          label: "Forecast " + type,
          data: gatherData['forecast'+ type.charAt(0).toUpperCase() + type.slice(1)]
        });

        return r;
      }
    });
  }

  filterGraph = (e) => {
    this.setState({ graphQueryString: e});
    this.requestData(e.value);
  }

  setDate = (date) => {
    console.log('filter with');
    console.log(date);
  }

  render() {
    return (
      <>
        <Card className="card-chart pt-2 mx-3">
          <Row className={"justify-content-between px-4"}>
            <h4 className="card-title pt-2 pb-2 px-2">Campaign Performance by Clicks</h4>
            <Row>
              <Col className={"px-0 pt-2"}>
                <InputGroup
                className={classnames('cognativex-select-group', {
                  "input-group-focus": this.state.cFocus
                })}
                style={{'width': '300px'}}
                >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="graph"
                  value={this.state.graphQueryString}
                  onChange={e => { this.filterGraph(e);}}
                  onFocus={e => this.setState({ cFocus: true })}
                  onBlur={e => this.setState({ cFocus: false })}
                  options={this.state.campaignList}
                  placeholder="Filter"
                />
              </InputGroup>
              </Col>
              <Col>
                <DatetimeRangePicker
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  handler={this.setDate}
                />
              </Col>
            </Row>
          </Row>
          <CardBody style={{minHeight: '350px'}}>
            <Line
              data={this.state.data}
              options={this.options}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}




export default CampaignStatusGraph;
