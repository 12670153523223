import axios from 'axios';

/* Helper Class
* To make requesting countries and regions more flexible
* */
class restCountries{

    baseUrl = "https://restcountries.eu/rest/v2/";

    getContinentOfRegions = {
        'Africa': ['Eastern Africa', 'Middle Africa', 'Northern Africa', 'Southern Africa', 'Western Africa'],
        'North America': ['Caribbean', 'Central America', 'Northern America'],
        'South America': ['South America'],
        'Europe': ['South America'],
        'Asia': ['Asia', 'Middle East'],
        'Oceania': ['Oceania']
    };

    getRegionsCountries = {
        'GCC': ['SA', 'AE', 'QA', 'OM', 'KW', 'BH'],
        'Eastern Africa': ['BI', 'KM', 'DJ','ER','ET','KE','MG','MW','MU','YT','MZ','RE','RW','SC','SO','TZ','UG','ZM', 'ZW'],
        'Middle Africa': ['AO', 'CM', 'CF', 'TD', 'CG', 'CD', 'GQ', 'GA', 'ST'],
        'Northern Africa': ['DZ', 'EG', 'LY', 'MA', 'SS', 'SD', 'TN', 'EH'],
        'Southern Africa':['BW', 'LS', 'NA', 'ZA', 'SZ'],
        'Western Africa': ["BJ", "BF", "	CV", "CI", "GM", "GH", "GN", "GW", "LR", "ML", "MR", "NE", "NG", "SH", "SN", "SL", "TG"],
        'Caribbean': ["AI", "AG", "AW", "BS", "BB", "BQ", "VG", "KY", "CU", "CW", "DM", "DO", "GD", "GP", "HT", "JM", "MQ", "MS", "PR", 'BL', 'KN', 'LC', 'MF', 'VC', 'SX', 'TT', 'TC', 'VI'],
        'Central America': ['BZ','CR','SV','GT','HN','MX','NI','PA'],
        'Northern America': ['BM', 'CA', 'GL', 'PM', 'US'],
        'South America' : 	['AR',  'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF','PY','PE','SR','UY','VE'],
        'Europe': ['AL', 'AD', 'BY', 'BA', 'HR', 'FO', 'GI', "GG", 'IS', 'JE', 'XK', 'LI', 'MK', 'KY', 'MD', 'MC', 'ME', 'NO', 'RU', 'SM', 'RS', 'SJ', 'CH', 'TR', 'UA', 'VA', 'AT	', 'BE', 'BG', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI	', 'ES', 'SE', 'GB'],
        'Middle East': ['	BH', 'IQ', 'IR', 'IL', 'JO', 'KW', 'LB', 'OM', 'PS', 'QA', '	SA', 'SY', 'AE', 'YE'],
        'Asia': ['AF' , 'AM', 'AZ', 'BD', 'BT', 'BN', 'KH', 'CN', 'GE', 'HK', 'IN', 'ID', 'JP', 'KZ', 'KP', 'KR', 'KG', 'LA', 'MO', 'MY', 'MV', 'MN', 'MM', 'NP', 'PK', 'PH', 'SG', 'LK', 'TW', 'TJ', 'TH', 'TL', 'TM', 'UZ', 'VN'],
        'Oceania':['AU', 'FJ', 'PF', 'GU', 'KI', 'Marshall Island', 'FM', 'NC', 'NZ', 'PG', 'WS', 'AS', 'SB', 'TO', 'VU']
    };

    /* Helper Function
    * To get the countries of a specific region
    * */
    getRegionCountries = async (region) => {
        return await axios.get(this.baseUrl + 'regionalbloc/' + region);
    }

    /* Helper Function
    * To get all the countries
    * */
    getCountries = async () => {
        return await axios.get(this.baseUrl + 'all');
    }
}

export default new restCountries();