import axios from 'axios';
import querystring from 'querystring';
import React from "react";

/* Helper Class
* To request campaign details and save other campaign details.
* */
class campaignDetails{

  // baseUrl = 'https://cognativex-campaign-api.appspot.com';
  baseUrl = 'https://cognativex-campaign-portal.appspot.com';
  details = {};

  getList = [
    {
      id: 210,
      name: "Beauty discount",
      status: "On going",
      cpc: "47",
      startdate: "09/01/2019",
      enddate: "29/03/2019",
      spent: 100,
      progress: "25",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2500,
      totalClicks: 3000,
      budget: 10000,
      ctr: 500
    },
    {
      id: 205,
      name: "Cars",
      status: "On going",
      cpc: "66",
      startdate: "10/01/2019",
      enddate : "29/04/2019",
      spent: 500,
      progress: "25",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2500,
      totalClicks: 3000,
      budget: 10000,
      ctr: 500
    },
    {
      id: 203,
      name: "camp 7",
      status: "On going",
      cpc: "41",
      startdate: "10/01/2019",
      enddate: "29/03/2019",
      spent: 100,
      progress: "50",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2500,
      totalClicks: 3000,
      budget: 10000,
      ctr: 500
    },
    {
      id: 200,
      name: "buildings sales",
      status: "Paused",
      cpc:"28",
      startdate: "10/01/2019",
      enddate: "29/03/2019",
      spent: 50,
      progress: "25",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2500,
      totalClicks: 4000,
      budget: 10000,
      ctr: 500
    },
    {
      id: 105,
      name: "camp2",
      status: "On going",
      cpc: "61",
      startdate: "10/01/2019",
      enddate: "29/03/2019",
      spent: 1000,
      progress: "25",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 1000,
      totalClicks: 1500,
      budget: 10000,
      ctr: 500
    },
    {
      id: 103,
      name: "code editors",
      status: "Paused",
      cpc:"21",
      startdate: "10/01/2019",
      enddate: "29/03/2019",
      spent: 200,
      progress: "60",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 100,
      totalClicks: 1000,
      budget: 10000,
      ctr: 500
    },
    {
      id: 102,
      name: "test campaign",
      status: "Paused",
      cpc:"22",
      startdate: "10/01/2019",
      enddate: "29/03/2019",
      spent: 10,
      progress: "25",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2500,
      totalClicks: 4000,
      budget: 10000,
      ctr: 500
    },
    {
      id: 9,
      name: "Assistants needed",
      status: "Paused",
      cpc:"59",
      startdate: "10/01/2019",
      enddate: "29/03/2019",
      spent: 300,
      progress: "10",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2500,
      totalClicks: 4500,
      budget: 10000,
      ctr: 500
    },
    {
      id: 7,
      name: "Testing data 2",
      status: "On going",
      cpc: "41",
      startdate : "10/02/2019",
      enddate: "29/03/2019",
      spent: 300,
      progress: "25",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2500,
      totalClicks: 2500,
      budget: 10000,
      ctr: 500
    },
    {
      id: 6,
      name: "campaign 4",
      status: "On going",
      cpc: "51",
      startdate: "10/01/2019",
      enddate: "29/03/2019",
      spent: 200,
      progress: "25",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2000,
      totalClicks: 2000,
      budget: 10000,
      ctr: 500
    },
    {
      id: 3,
      name: "campaign 5",
      status: "On going",
      cpc: "65",
      startdate: "10/02/2019",
      enddate: "29/03/2019",
      spent: 200,
      progress:  "25",
      reach: 2000,
      frequency: 200,
      impression: 1000,
      cpm: 5000,
      clicks: 2500,
      totalClicks: 4000,
      budget: 10000,
      ctr: 500
    }
  ];
  getCustomList = [
    {accessor: 'startdate', Header: 'Start Date', show: true},
    {accessor: 'enddate', Header: 'End Date', show: true},
    {accessor:'cpc', Header: 'CPC', show: false},
    {accessor: 'status', Header: 'Status', show: true},
    {accessor:'reach', Header: 'Reach', show: false},
    {accessor:'frequency', Header: 'Frequency', show: false},
    {accessor:'impression', Header: 'Impressions', show: false},
    {accessor:'cpm', Header: 'CPM', show: false},
    {accessor:'clicks', Header: 'Clicks', show: false},
    {accessor:'budget', Header: 'Budget', show: false},
    {accessor: 'spent', Header: 'Spent', show: true,
      Cell: ({original}) => {
        return <p>{original.spent}$ <span style={{"color": "#8e8e8e"}}>of {original.budget}$</span></p>
      },},
    {accessor: 'ctr', Header: 'CTR', show: false}
  ];

  constructor(props) {
    this.details = props;
    if(window.location.hostname === 'localhost'){
      this.baseUrl = 'http://localhost:5000';
    }
  }

  /* Helper method
  *  Gets all the campaign details
  * */
  getCampaignDetails = async (id) => {
    return await axios.get( this.baseUrl + `/campaign-details/${id}`);
  }

  /* Helper Function
  * To upload an image of a creative
  * */
  imageUload = async (data, file) => {
    const url = this.baseUrl + '/campaign_image_upload',
      formData = new FormData(),
      config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

    formData.append('file',file);
    // data contains {campaign_key: key , image_ext: ext}
    for (let i in data) {
      formData.append(i, data[i]);
    }

    return  axios.post(url, formData,config)
      .catch(function (error) {
      console.error(error);
    });
  }

  create (data) {
    const url = this.baseUrl + '/campaign_create',
      config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

    let dataToSend = data;
    dataToSend['campaignKey'] = this.details.campaignKey;
    console.log('dataToSend');
    console.log(dataToSend);
    return axios.post(url, querystring.stringify({data: JSON.stringify(dataToSend)}), config)
    .catch(function (error) {
      console.error(error);
      console.error(error.message);
    })
}

  /* Helper method
  *  Gets all the campaign details
  * */
  performance = async (id) => {
    return await axios.get( this.baseUrl + `/campaign-performance/${id}`);
  }

  /* Helper Function
  * To get the countries of a specific region
  * */
  getCampaignList = async () => {
    return await axios.get(this.baseUrl + '/campaign-list');
  }

  estimatedDailyReach = async (data) => {
    const url = this.baseUrl + '/campaign-estimated-daily-reach',
      config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

    let dataToSend = JSON.stringify(data);
    return axios.post(url, querystring.stringify({data: dataToSend}), config)
      .catch(function (error) {
        console.error(error);
        console.error(error.message);
      })
  }
}

export default campaignDetails;
