import React from "react";
import classnames from "classnames";
import Breadcrumbs from "../components/Breadcrumbs.jsx";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  // Progress,
  InputGroupAddon, InputGroupText, InputGroup, Nav, NavItem, NavLink, ModalBody, Modal, CardTitle, Progress
} from "reactstrap";
import Revenue from "charts/revenue";
import mediaPlanner from '../../api/mediaPlanner';
import ReactDatetime from "react-datetime";

const breadcrumbs = [
  {name: <i className="fas fa-home"></i>, href: "/", disabled: 0},
  {name: 'Media Planner', href: "/media-planner", disabled: 1},
];

let dataExcluded = ['campaignId', 'campaignCreationDate'];
let dataCampExcluded = ['campaignId', 'campaignCreationDate', 'totalBudget', 'totalClicks', 'minCpc', 'maxCpc', 'campaignPeriodDays'];


class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      criticalCampaigns: [],
      topPerformingCampaigns: [],
      tableCols : [],
      criticalCampaignsCols : [],
      topPerformingCampaignsCols : [],
      showActions: '',
      alert: null,
      modalClassic: false,
      propReschedule: {}
    };
    this.mediaPlanner = new mediaPlanner();
  }

  /*
  * Helper methods
  * perform actions on the campaign table
  * Approves the campaign
  * */
  manageCampaign = (prop) => {
    this.props.history.push(`/admin/campaign-manage/${prop.campaignId}`);
  }

  /*
  * Helper function
  * To format the data coming from the api
  * like adding for the total budget a $ sign
  * */
  formatData = (data) => {
    for(let index in data){
      for(let key in data[index]){
        if(key === 'totalBudget'){
          data[index][key] = '$' + data[index][key];
        }
      }
    }
    return data;
  }

  /*
  * Helper methods
  * perform actions on the campaign table
  * Approves the campaign
  * */
  manageCampaign = (prop) => {
    this.props.history.push(`/admin/campaign-manage/${prop.campaignId}`);
  }

  printProgress = (numerator,denominator, percent ,title) => {

    return <div className="progress-container" title={title}>
      <div>
        <p style={{"fontSize": "85%"}}>{numerator} <span style={{"color": "rgb(142, 142, 142)"}}>of {denominator}</span></p>
      </div>
      <Progress max="100" value={percent}> <span className="progress-value">{percent}%</span> </Progress>
    </div>;
  }

  populateCampData = (data = []) => {
    // console.log('prop');
    return data.map((prop, key) => {
      // console.log(prop);
      prop.index = key;
      if(prop.campaignId) prop.id = prop.campaignId;
      prop.actions =  (
        <div className={"text-right action-btns-"+prop.index+"-"+prop.id} key={prop.id+key}>
          {/*use this button to accept a campaign*/}
          <Button
            onClick={() => {this.manageCampaign(prop)}}
            color="warning"
            size="sm"
            className={classnames(" mx-0 my-0")}
            id={"tooltip03-" + prop.id}
          >
            Manage
          </Button>
        </div>
      );
      return prop;
    });
  }

  /*
  * Before mounting the component,
  * prepares the data of the pending approval campaigns table
  * */
  componentWillMount = () => {
    this.mediaPlanner.getCampaignsPendingApproval().then((r)=> {
      let columns = [];
      // Gathering the columns of the table from the 1st array object
      if (r.data.length > 0) {
        let obj = r.data[0];
        for (let key in obj) {
          if(dataExcluded.indexOf(key) === -1){
            columns.push({
              Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
              accessor: key
            });
          }
        }
      }
      // adding the action column
      columns.push({
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        width: 100
      });
      let data = this.formatData(r.data);
      // setting the states of the columns
      this.setState({tableCols: columns}, ()=>{
        this.setState({data: this.populateData(data)});
      });
    });
    this.mediaPlanner.getCampaignList('critical').then((r)=> {
      let columns = [];
      if (r.data.length > 0) {
        let obj = r.data[0];
        for (let key in obj) {
          if(dataCampExcluded.indexOf(key) === -1){
            switch(key) {
              default:
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <span title={info.original[key]}>{info.original[key]}</span>;
                  }
                });
                break;
              case "campaignStatus":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>Status</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <span title={info.original[key]}>{info.original[key]}</span>;
                  }
                });
                break;
              case "remainingDays":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    let daysPassed = original['campaignPeriodDays'] - original[key];
                    let percent = ((daysPassed/original['campaignPeriodDays'])*100).toFixed(1);
                    return this.printProgress(original[key], original['campaignPeriodDays'], percent,"remaining days / Total days");
                  }
                });
                break;
              case "clicksAchieved":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    let percent = ((original[key]/original['totalClicks'])*100).toFixed(1);
                    return this.printProgress(original[key], original['totalClicks'], percent,'Clicks Achieved / Total Clicks Needed');
                  }
                });
                break;
              case "budgetSpent":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    let percent = ((info.original[key] / info.original.totalBudget)*100).toFixed(1);
                    return this.printProgress(info.original[key]+ '$', info.original.totalBudget, percent,'Budget spent / Total Budget');
                  }
                });
                break;
              case "avgCpc":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>CPC</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <div className={"ranges-min-avg-max"}>
                      <span className={'min'} title={"Min Cpc: " + info.original.minCpc}><span className={"text"}>{info.original.minCpc}</span><span className={'label'}>Min</span></span>
                      <span className={"avg"} title={"Avg Cpc: " + info.original[key]}><span className={"text"}>{info.original[key]}</span><span className={'label'}>Avg</span></span>
                      <span className={"max"} title={"Max Cpc: " + info.original.maxCpc}><span className={"text"}>{info.original.maxCpc}</span><span className={'label'}>Max</span></span>
                    </div>;
                  }
                });
                break;
              case "targetStatus":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    return <div className="progress-container"> <Progress max="100" value={original[key]}> <span className="progress-value">{original[key]}%</span> </Progress> </div>
                  },
                });
                break;
            }
          }
        }
      }
      columns.push({
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        width: 100
      });
      this.setState({criticalCampaignsCols: columns}, ()=>{
        this.setState({criticalCampaigns: this.populateCampData(r.data)});
      });
    });
    this.mediaPlanner.getCampaignList('top').then((r)=> {
      let columns = [];
      // console.log(r);
      if (r.data.length > 0) {
        let obj = r.data[0];
        for (let key in obj) {
          if(dataCampExcluded.indexOf(key) === -1){
            switch(key) {
              default:
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <span title={info.original[key]}>{info.original[key]}</span>;
                  }
                });
                break;
              case "campaignStatus":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>Status</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <span title={info.original[key]}>{info.original[key]}</span>;
                  }
                });
                break;
              case "remainingDays":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    let daysPassed = original['campaignPeriodDays'] - original[key];
                    let percent = ((daysPassed/original['campaignPeriodDays'])*100).toFixed(1);
                    return this.printProgress(original[key], original['campaignPeriodDays'], percent,"remaining days / Total days");
                  }
                });
                break;
              case "clicksAchieved":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    let percent = ((original[key]/original['totalClicks'])*100).toFixed(1);
                    return this.printProgress(original[key], original['totalClicks'], percent,'Clicks Achieved / Total Clicks Needed');
                  }
                });
                break;
              case "budgetSpent":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: (info) => {
                    let percent = ((info.original[key] / info.original.totalBudget)*100).toFixed(1);
                    return this.printProgress(info.original[key]+ '$', info.original.totalBudget, percent,'Budget spent / Total Budget');
                  }
                });
                break;
              case "avgCpc":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>CPC</span>,
                  accessor: key,
                  Cell: (info) => {
                    return <div className={"ranges-min-avg-max"}>
                      <span className={'min'} title={"Min Cpc: " + info.original.minCpc}><span className={"text"}>{info.original.minCpc}</span><span className={'label'}>Min</span></span>
                      <span className={"avg"} title={"Avg Cpc: " + info.original[key]}><span className={"text"}>{info.original[key]}</span><span className={'label'}>Avg</span></span>
                      <span className={"max"} title={"Max Cpc: " + info.original.maxCpc}><span className={"text"}>{info.original.maxCpc}</span><span className={'label'}>Max</span></span>
                    </div>;
                  }
                });
                break;
              case "targetStatus":
                columns.push({
                  Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                  accessor: key,
                  Cell: ({original}) => {
                    return <div className="progress-container"> <Progress max="100" value={original[key]}> <span className="progress-value">{original[key]}%</span> </Progress> </div>
                  },
                });
                break;
            }
          }
        }
      }
      columns.push({
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        width: 100
      });

      this.setState({topPerformingCampaignsCols: columns}, ()=>{
        this.setState({topPerformingCampaigns: this.populateCampData(r.data)});
      });
    });
  }

  /*
  * Helper function:
  * Helps populates the action buttons
  * */
  populateData = (data = []) => {
    return data.map((prop, key) => {
      prop.index = key;
      prop.id = prop.campaignId;
      prop.actions =  (
        <div className={"text-right action-btns-"+prop.index+"-"+prop.id} key={prop.id+key}>
            {/*use this button to accept a campaign*/}
            <Button
              onClick={() => {this.manageCampaign(prop)}}
              color="warning"
              size="sm"
              className={classnames(" mx-0 my-0")}
              id={"tooltip03-" + prop.id}
            >
              Manage
            </Button>
        </div>
      );
      return prop;
    });
  }

  /*
  * Helper Function:
  * helps filtering out values in table
  * */
  searchForData = (text )=> {
    console.log(`search for ${text}`);
  }

  /*
  * Helper Render Function:
  * renders the revenue graph
  * */
  renderGraphs = () => {
    return(
      <div className={'col-sm-12'}>
        <Row>
          <Col lg="12">
            <Card className="card-chart pt-2">
              <CardBody>
                <h4 className="card-title pt-1 pb-1 px-2">Revenue Overview</h4>
                <div className="chart-area pt-3">
                  <Revenue/>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  /*
  * Helper Render Function:
  * renders the cards
  * */
  renderKpis = () => {
    return(
      <div className={'col-sm-12'}>
        <Row>
          <Col md="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-warning">
                      <i className="fa fa-user" />
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">Active publishers</p>
                      <CardTitle tag="h3">100</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-primary">
                      <i className="fa fa-bullhorn" />
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">Active campaigns</p>
                      <CardTitle tag="h3">120</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-success">
                      <i className="fa fa-check" />
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">Impressions served</p>
                      <CardTitle tag="h3">1500</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-danger">
                      <i className="fa fa-eye" />
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">Total impressions</p>
                      <CardTitle tag="h3">2300</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  topPerformingCampaigns = () => {
    return (
      <Col className={classnames({
        'd-none' : this.state.topPerformingCampaigns.length === 0
      })}>
        <Card>
          <CardBody>
            <div>
              <Row  className="col-md-12 col-xs-12 mt-3 mb-2 mx-0 row px-2">
                <label className={"col-sm-7 px-0"} style={{color: 'white'}}>
                  <i className="fa fa-lightbulb" style={{"color": "yellow", 'marginRight': '2px', 'fontSize': '130%'}}></i> Top performing campaigns:
                </label>
              </Row>
              <ReactTable
                minRows={0}
                data={this.state.topPerformingCampaigns}
                resizable={false}
                columns={this.state.topPerformingCampaignsCols}
                defaultPageSize={10}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  criticalCampaigns = () => {
    return (
      <Col className={classnames({
        'd-none' : this.state.criticalCampaigns.length === 0
      })}>
        <Card>
          <CardBody>
            <div>
              <Row  className="col-md-12 col-xs-12 mt-3 mb-2 mx-0 row px-2">
                <label className={"col-sm-7 px-0"} style={{color: 'white'}}>
                  <i className="fa fa-exclamation" style={{"color": "red", 'marginRight': '2px', 'fontSize': '130%'}}></i> Critical campaigns:
                </label>
              </Row>
              <ReactTable
                minRows={0}
                data={this.state.criticalCampaigns}
                resizable={false}
                columns={this.state.criticalCampaignsCols}
                defaultPageSize={10}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  overview = () => {
    return (
      <Col sm={12}>
        {this.renderGraphs()}
        {this.renderKpis()}
        <Col>
          <Card>
            <CardBody>
              {(this.state.data.length === 0)?
                <div className="card-header py-0"><h4 className="card-title my-0"><i className="fa fa-bullhorn mr-4"></i> No campaigns pending approval</h4></div> :
                <div>
                  <Row  className="col-md-12 col-xs-12 mt-3 mb-2 mx-0 justify-content-end align-items-start row px-2">
                    <label className={"col-sm-7 px-0"} style={{color: 'white'}}>
                      <i className="fa fa-question" style={{"color": "orange", 'marginRight': '2px', 'fontSize': '130%'}}></i> Campaigns pending approval:
                    </label>
                    <div className={"px-2 col-sm-5"}>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.searchFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="search"
                          placeholder="Search"
                          type="text"
                          onChange={e => this.searchForData(e.target.value)}
                          onFocus={e => this.setState({ searchFocus: true })}
                          onBlur={e => this.setState({ searchFocus: false })}
                        />
                      </InputGroup>
                    </div>
                  </Row>
                  <ReactTable
                    minRows={0}
                    data={this.state.data}
                    resizable={false}
                    columns={this.state.tableCols}
                    defaultPageSize={10}
                    showPaginationBottom={true}
                    className="-striped -highlight cx-shadowed-table"
                  />
                </div>
              }
            </CardBody>
          </Card>
        </Col>
        {this.criticalCampaigns()}
        {this.topPerformingCampaigns()}
      </Col>
    );
  }

  render() {
    return (
      <>
        {this.state.alert}
        <Modal
          isOpen={this.state.modalClassic}
          toggle={this.toggleModalClassic}
        >
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalClassic}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Reschedule the <b>{(this.state.propReschedule)? this.state.propReschedule.campaignName : ''}</b> campaign</h6>
          </div>
          <ModalBody className="text-center">
            <Row className={"justify-content-center"}>
              <ReactDatetime
                value={this.state.startDate}
                onChange= {e => this.setState({startDate: e._d.toISOString()})}
                onFocus= {e => this.setState({ startDateFocus: true })}
                onBlur = {e => this.setState({ startDateFocus: false })}
                closeOnTab
                timeFormat={false}
                inputProps={{
                  className: "form-control",
                  placeholder: "From Date",
                  name: 'startDate',
                  style:{color: 'black'}
                }}
                className={'col-sm-6'}
              />

              <ReactDatetime
                value={this.state.toDate}
                onChange= {e => this.setState({toDate: e._d.toISOString()})}
                onFocus= {e => this.setState({ toDateFocus: true })}
                onBlur = {e => this.setState({ toDateFocus: false })}
                closeOnTab
                timeFormat={false}
                inputProps={{
                  className: "form-control",
                  placeholder: "To Date",
                  name: 'toDate',
                  style:{color: 'black'}
                }}
                className={'col-sm-6'}
              />
            </Row>
          </ModalBody>
          <div className="modal-footer">
            <Button
              color="default"
              type="button"
              onClick={()=>{this.rescheduleCampaign();this.toggleModalClassic();}}
            >
              Reschedule
            </Button>
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={()=>{this.toggleModalClassic(); this.setState({startDate: ''}); this.setState({toDate: ''});}}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <div className="content">
            <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Card className="card-plain card-subcategories">
                  <CardBody style={{
                    'marginBottom': '-60px',
                    'position': 'relative',
                    'bottom': '60px'
                  }}>
                    {/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger" */}
                    <Nav
                      className="nav-pills-info nav-pills-icons justify-content-center"
                      pills
                    >
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/media-planner"
                          className={"active"}
                          onClick={(e)=>{e.preventDefault()}}
                        >
                          <i className="fa-info-circle fa" />
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/network-status"
                        >
                          <i className="tim-icons icon-molecule-40" />
                          Network Status
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="/admin/campaign-status"
                        >
                          <i className="fa-bullhorn fa" />
                          Campaign Status
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {this.overview()}
        </div>
      </>
    );
  }
}

export default Overview;
