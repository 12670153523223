let options = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      fontColor: 'rgb(255,255,255)',
    }
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};

let graphOption = (canvas, hexColor, rgbaColor, steppedLine) => {
  let ctx = canvas.getContext("2d");

  let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
  gradientStroke.addColorStop(1, "rgba("+rgbaColor+",0.2)");
  gradientStroke.addColorStop(0.4, "rgba("+rgbaColor+",0.0)");
  gradientStroke.addColorStop(0, "rgba("+rgbaColor+",0)");

  return {
    fill: true,
    borderColor: "#"+hexColor,
    borderWidth: 3,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: "#"+hexColor,
    pointBorderWidth: 5,
    pointRadius: 4,
    pointBorderColor: 'rgba('+rgbaColor+',0.5)',
    pointHoverRadius: 4,
    pointHoverBorderWidth: 10,
    backgroundColor: gradientStroke,
    steppedLine: steppedLine
  }
}

let chart = {
  data: canvas => {
    return {
      labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"], // X-axis
      datasets: [
        {
          ...graphOption(canvas, '1f8ef1', '31, 142, 241', false),
          label: "Budget Spent ($)",
          data: [80, 100, 70, 800, 800, 100], // Y-axis
        },
        {
          ...graphOption(canvas, 'd048b6', '208, 72, 182', true),
          label: "CPC",
          data: [80, 300,800, 0, 100, 800], // Y-axis
        }
      ]
    };
  },
  options: options
};


export default chart;