import React from 'react';
import mediaPlanner from '../api/mediaPlanner';
import {Bar} from "react-chartjs-2";
import {
  Row, Card, CardBody
} from "reactstrap";
import mainHelper from "../helpers/main";

let hexToRgb = (hex) => {
  hex   = hex.replace('#', '');
  let r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16),
    g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16),
    b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
  return r + ', ' + g + ', ' + b;
}

let helpers = new mainHelper();

let colorset = helpers.cxColorSet;

class CampaignStatusBudgetGraph extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data: {},
      gatherData: {}
    }
    this.months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    // get up the options of the graph
    this.options = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'rgb(255,255,255)',
        }
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9a9a9a",
              beginAtZero: true,
              callback: function(value){
                  return '$' + value.toLocaleString();
              }
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              beginAtZero: true,
              padding: 20,
              fontColor: "#9a9a9a",
            }
          }
        ]
      }
    };
    // initialize the class to be able to run the ajax call to get the budget details
    this.mediaPlanner = new mediaPlanner();
  }

  componentWillMount = () => {
    this.mediaPlanner.getCampaignStatusBudgetGraphDetails().then((r)=>{
      let gatherData = {publishers : {}, campaignNames: []};
      // loop to separate all data alone
      for(let index in r.data){
        let object = r.data[index]; // {PublisherName: "MBC, details: [...]}
        gatherData['campaignNames'].push(object['campaignName']);
        let details = object['details'];
        let colorindex = 0;
        for(let d in details){
          let detail = details[d];
          let publisherKey = detail.publisherName.split(" ").join("").toLowerCase()+detail.publisherId;
          let color = colorset[colorindex%colorset.length];
          colorindex++;
          if(typeof gatherData['publishers'][publisherKey] === 'undefined'){
            gatherData['publishers'][publisherKey] = {
              stack: object['publisherName'],
              label: detail.publisherName,
              fill: true,
              backgroundColor: color,
              borderColor: color,
              borderWidth: 3,
              budget: [],
            };
          }
          gatherData['publishers'][publisherKey]['budget'].push(detail['budget']);
        }
      }
      this.clicksVsViews(gatherData, 'budget');
      this.setState({gatherData: gatherData});
    });
  }

  generateColors = () => {
    let letters = '0123456789ABCDEF', color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /*
  * Helper Function:
  * */
  clicksVsViews = (gatherData, type) => {
    this.setState({
      data: function(canvas){
        let ctx = canvas.getContext("2d");
        let r = {labels: gatherData['campaignNames'], datasets: []};
        for (let i in gatherData.publishers){
          if(typeof gatherData['publishers'][i]['backgroundColor'] === 'string'){
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba("+ hexToRgb(gatherData['publishers'][i]['backgroundColor']) +",0.3)");
            gradientStroke.addColorStop(0.4, "rgba("+ hexToRgb(gatherData['publishers'][i]['backgroundColor']) +",0.1)");
            gradientStroke.addColorStop(0, "rgba("+ hexToRgb(gatherData['publishers'][i]['backgroundColor']) +",0)");
            gatherData['publishers'][i]['backgroundColor'] = gradientStroke;
          }

          r['datasets'].push(
            gatherData['publishers'][i]
          );
          r['datasets'][r['datasets'].length-1]['data'] = JSON.parse(JSON.stringify(gatherData['publishers'][i][type]));
        }
        return r;
      }
    });
  }

  render() {
    return (
      <>
        <div className="chart-area pt-3 col-sm-12">
          <Row>
              <Card className="card-chart pt-2" style={{minHeight: '300px'}}>
                <h4 className="card-title pt-2 pb-2 px-2">Breakdown of Campaign Budgets</h4>
                <CardBody>
                  <Bar
                    data={this.state.data}
                    options={this.options}
                  />
                </CardBody>
              </Card>
          </Row>
        </div>
      </>
    );
  }
}




export default CampaignStatusBudgetGraph;
