import React from "react";
import Breadcrumbs from "../components/Breadcrumbs.jsx";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Progress,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Input,
} from "reactstrap";
import mapData from '../../api/mapData';
import publisherApi from '../../api/publisher';
import DatetimeRangePicker from "../components/ReactDateTimePicker";
import Highcharts from 'highcharts';
import mainHelper from "../../helpers/main";
import HighchartsReact from 'highcharts-react-official';
import loaderImage from "../../assets/img/loader.gif";
import {Link} from 'react-router-dom';

require('highcharts/modules/map')(Highcharts);

let helpers = new mainHelper();

let colorset = helpers.cxColorSet;

const breadcrumbs = [
  {name: <i className="fas fa-home"></i>, href: "/", disabled: 0},
  {name: 'Overview', href: "/admin/overview", disabled: 1},
];

let pageViewOps = {
  "title": {
    "text": "",
  },
  "subtitle": { "text": "" },
  "exporting": { "enabled": false },
  "chart": {
    "type": "line",
    "polar": false,
    "backgroundColor": "transparent",
  },
  "series": [],
  "colors": colorset,
  "plotOptions": { "series": { "dataLabels": { "enabled": false } } },
  "legend": {
    "layout": "horizontal",
    "enabled": false,
    "floating": false,
    "itemStyle": {
      "color": '#fff'
    }
  },
  "tooltip": {
    formatter: function() {
      return new Date(this.x).getHours() +':00<br/><b>' + this.y + ' Views</b>';
    }
  },
  "credits": { "enabled": false },
  "annotations": [{}],
  "pane": { "background": [] },
  "responsive": { "rules": [] },
  "xAxis": {
    "type": 'datetime',
    "labels":{
      "style": {
        "color": '#aeaeae'
      }
    }
  },
  "yAxis": [
    {
      "title": { "text": "Pageviews" },
      "labels": {
        "formatter": function () {
          return this.value.toLocaleString() + '$';
        },
        "style": {
          "color": '#aeaeae'
        }
      }
    }]
};

class PublisherOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.mapData = new mapData();
    this.publisherApi = new publisherApi();
    // temporary should exist when user logs in
    this.publisherId = (window.localStorage['cx-uid'])? window.localStorage['cx-uid'] : 0;
    this.appdomain = (window.localStorage['cx-appdomain'])? window.localStorage['cx-appdomain'] : 'striveme.com';
    this.queryDate = '&fromtimestamp=' + helpers.formatDate_yyyy_MM_dd(0) + '&totimestamp=' + helpers.formatDate_yyyy_MM_dd(1);
    this.queryAppdomain = '&appdomain=' + this.appdomain;
    this.queryStringDateAppdomain = this.queryDate + this.queryAppdomain;
  }

  /*
  * Before mounting the component,
  * prepares the data of the pending approval campaigns table
  * */
  componentWillMount = () => {
    this.mapData.getWorld().then((r)=>{
      this.setState({'mapData': r.data});
    });
    this.getPageViews();

    // page views today
    this.setState({pageViewsToday : null}, () => {
      this.publisherApi.pageViews('size=1'+this.queryStringDateAppdomain, 'Today')
        .then((r)=>{
          if(!(r && r.data && r.data.data)) return;
          this.setState({pageViewsToday : helpers.formatNumber(r.data.data[0]['total_views'])});
        });
    });

    // page views by referral today
    this.setState({pageViewsReferral : null}, () => {
      this.publisherApi.pageViews('size=5&orderby=pageviews&sort=DESC&meta=referral'+this.queryStringDateAppdomain, 'Referral')
        .then((r)=>{
          if(!(r && r.data && r.data.data)) return;
          this.setState({topReferrals: r.data.data, pageViewsReferral : {name: (r.data.data[0]['referral'] === "")? "Direct" : r.data.data[0]['referral'] , percent: r.data.data[0]['pageviews_percent'].toFixed(0)}});
        });
    });

    // page views by country today
    this.setState({pageViewsCountry : null}, () => {
      this.publisherApi.pageViews('orderby=pageviews&sort=DESC&size=1&meta=country'+this.queryStringDateAppdomain, 'Country')
        .then((r)=>{
          if(!(r && r.data && r.data.data)) return;
          this.setState({pageViewsCountry : {name: r.data.data[0]['country'] , percent: r.data.data[0]['pageviews_percent'].toFixed(0)}});
        });
    });

    // page views by section today
    this.setState({pageViewsBySection : null}, () => {
      this.publisherApi.pageViews('orderby=pageviews&sort=DESC&size=5'+this.queryStringDateAppdomain, 'Section')
        .then((r)=>{
          if(!(r && r.data && r.data.data)) return;
          this.setState({topSections: r.data.data , pageViewsBySection : {name: r.data.data[0]['section'] , percent: r.data.data[0]['pageviews_percent'].toFixed(0)}});
        });
    });

    // page views by author today
    this.setState({pageViewsByAuthor : null}, () => {
      this.publisherApi.pageViews('orderby=pageviews&sort=DESC&size=5'+this.queryStringDateAppdomain, 'Author')
        .then((r)=>{
          if(!(r && r.data && r.data.data)) return;
          this.setState({topAuthors: r.data.data, pageViewsByAuthor : {name: (r.data.data[0]['author'] === "")? "No Author" : r.data.data[0]['author'] , percent: r.data.data[0]['pageviews_percent'].toFixed(0)}});
        });
    });

    // widget Clicks today
    this.setState({widgetClicksToday : null}, () => {
      this.publisherApi.widgetClicksToday(this.queryStringDateAppdomain)
        .then((r)=>{
          if(!(r && r.data && r.data.data)) return;
          this.setState({widgetClicksToday : helpers.formatNumber(r.data.data[0]['total_clicks'])});
        });
    });

  }

  getPageViews = () => {
    // page views by hr
    this.setState({pageViewsByHr : null}, ()=>{
      this.publisherApi.pageViews('orderby=timestamp&sort=DESC' + this.queryStringDateAppdomain, 'Hour')
        .then((r)=>{
          if(r && r.data && r.data.data){
            let datalist = [];
            for(let i in r.data.data){
              let item = r.data.data[i];
              datalist.push([Date.parse(helpers.cxDate(item.timestamp)), item.total_views]);
            }
            let options = JSON.parse(JSON.stringify(pageViewOps));
            options.series.push({
              fillOpacity: 0.3, yAxis: 0, pointInterval: 24 * 3600 * 1000, type: 'area', name: "", data: datalist, marker: { "symbol": "circle" }
            });
            this.setState({pageViewsByHr : options});
          }
        });
    });
  }


  renderThrobber = (wid = 100) => {
    return <img src={loaderImage} style={{"maxWidth": wid + "px"}} />;
  }

  publisherOverview = () =>{
    return (
      <Col sm={12}>
        <Card>
          <CardBody>
            <Row>
              <Col sm={"6"}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    title: {
                      text: 'Revenue by category',
                      style: {
                        color: '#fff'
                      },
                    },
                    credits: {
                      enabled: false
                    },
                    chart: {
                      backgroundColor: 'transparent',
                      scrollablePlotArea: {
                        minWidth: 700
                      },
                      type: 'area'
                    },
                    legend: {
                      enabled: true,
                      align: 'center',
                      itemStyle: {
                        color: '#fff'
                      }
                    },
                    tooltip: {
                      shared: true,
                      crosshairs: true,
                    },
                    plotOptions: {
                      series: {
                        stacking: 'normal'
                      }
                    },
                    xAxis: {
                      type: 'datetime',
                      labels:{
                        style: {
                          color: '#aeaeae'
                        }
                      }
                    },
                    yAxis: {
                      title: {
                        text: 'Revenue',
                      },
                      labels: {
                        formatter: function () {
                          return this.value.toLocaleString() + '$';
                        },
                        style: {
                          color: '#aeaeae'
                        }
                      }
                    },
                    colors: colorset,
                    series: this.state.categoryGraphData
                  }}
                />
              </Col>
              <Col sm={"6"}>
                {(this.state.mapData)?
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'mapChart'}
                    options={{
                      title: {
                        text: 'Revenue by Location',
                        style: {
                          color: '#fff'
                        },
                      },
                      chart:{
                        backgroundColor: 'transparent',
                      },
                      mapNavigation: {
                        enabled: true
                      },
                      credits: {
                        enabled: false
                      },
                      colorAxis: {
                        min: 0,
                        stops: [
                          [0, '#F2CDF7'],
                          [1, '#ff1dca']
                        ],
                        labels: {
                          style: {
                            color: '#aeaeae'
                          }
                        }
                      },
                      tooltip: {
                        pointFormatter: function() {
                          return this.properties['name'].split(',')[0] + ": " + this.options.value.toLocaleString() + '$';
                        }
                      },
                      legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'left',
                        verticalAlign: 'bottom'
                      },
                      series: [{
                        mapData: this.state.mapData,
                        name: 'world map',
                        data: this.state.mapValues
                      }]
                    }}
                  />
                  : ''}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }

  publisherTableOverview = () => {
    return <>
      <Col xs={12} md={12}>
        <Card>
          <CardBody>
            {(this.state.tableData)?
              <ReactTable
                minRows={0}
                data={this.state.tableData}
                resizable={false}
                columns={this.state.tableCols}
                defaultPageSize={10}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
              :
              <h4 className={"my-0 ml-3"}><i className={"fa fa-exclamation mr-3"} style={{'color': 'orange'}}></i> Empty results</h4>
            }
          </CardBody>
        </Card>
      </Col>
    </>;
  }

  renderPageViewGraph = () => {
    return (
      <Card>
        <CardBody style={{"minHeight": "480px"}}>
          {(this.state.pageViewsByHr)?
            <>
              <Row className={"justify-content-between mx-0"}>
                <CardTitle>Page Views Today</CardTitle>
                <div>
                  <a href={"#"} onClick={(e)=>{e.preventDefault(); this.getPageViews();}}><i className={"tim-icons icon-refresh-02"}></i></a>
                </div>
              </Row>
              <Row>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={this.state.pageViewsByHr}
                />
              </Row>
            </>
            :
            <Row className={"justify-content-center align-items-center"} style={{'minHeight': '480px'}}>
              {this.renderThrobber()}
            </Row>
          }
        </CardBody>
      </Card>
    );
  }

  renderSummaryCards = () => {
    return (
      <>
        <div className={"col-sm-6"}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-warning">
                    <i className="fa fa-eye" />
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">Page Views Today</p>
                    <CardTitle tag="h3">{(this.state.pageViewsToday)? this.state.pageViewsToday : this.renderThrobber(28)}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className={"col-sm-6"}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-success">
                    <i className="tim-icons icon-tap-02" />
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">Widget Clicks Today</p>
                    <CardTitle tag="h3">{(this.state.widgetClicksToday)? this.state.widgetClicksToday : this.renderThrobber(28)}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className={"col-sm-6"}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">Location</p>
                    <Row className={"align-items-center justify-content-end mx-0"}>
                      {(this.state.pageViewsCountry)?
                        <><span><b>{this.state.pageViewsCountry.name}</b></span> &nbsp;&nbsp; <CardTitle tag="h3" className={"mb-0"}>{this.state.pageViewsCountry.percent}%</CardTitle></>
                        : this.renderThrobber(28)}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className={"col-sm-6"}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-danger">
                    <i className="fa fa-users" style={{"padding": "14px 10px"}}/>
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">Referral</p>
                    <Row className={"align-items-center justify-content-end mx-0"}>
                      {(this.state.pageViewsReferral)?
                        <><span><b>{this.state.pageViewsReferral.name}</b></span> &nbsp;&nbsp; <CardTitle tag="h3" className={"mb-0"}>{this.state.pageViewsReferral.percent}%</CardTitle></>
                        : this.renderThrobber(28)}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className={"col-sm-6"}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-success">
                    <i className="icon-satisfied tim-icons" />
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">Author</p>
                    <Row className={"align-items-center justify-content-end mx-0"}>
                      {(this.state.pageViewsByAuthor)?
                        <><span><b>{this.state.pageViewsByAuthor.name}</b></span> &nbsp;&nbsp; <CardTitle tag="h3" className={"mb-0"}>{this.state.pageViewsByAuthor.percent}%</CardTitle></>
                        : this.renderThrobber(28)}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <div className={"col-sm-6"}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-warning">
                    <i className="icon-shape-star tim-icons" />
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">Section</p>
                    <Row className={"align-items-center justify-content-end mx-0"}>
                      {(this.state.pageViewsBySection)?
                        <><span><b>{this.state.pageViewsBySection.name}</b></span> &nbsp;&nbsp; <CardTitle tag="h3" className={"mb-0"}>{this.state.pageViewsBySection.percent}%</CardTitle></>
                        : this.renderThrobber(28)}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }

  renderTrendingArticles = () => {
  }

  renderTopToday = () => {
    return(
      <>
        <div className={"col-sm-4"}>
          <Card>
            <CardBody>
              <Row className={"justify-content-between mx-0"}>
                <CardTitle>Top Authors Today</CardTitle>
                <div>
                  <Link to={"#"}>Show All</Link>
                </div>
              </Row>
              <div className={"mb-3"} style={{minHeight: '213px'}}>
              {
                (this.state.topAuthors)?
                  <div>
                    {this.state.topAuthors.map((prop, key)=>{
                      console.log(prop, key);
                      return (
                        <div className="progress-container" key={"topAuthor-"+ key}> <div><p style={{'fontSize': '85%'}}>{(prop.author === '')? 'No Author':  prop.author}</p></div> <Progress max="100" value={prop.pageviews_percent}> <span className="progress-value">{prop.pageviews_percent.toFixed(0)}%</span> </Progress></div>
                      );
                    })}
                  </div>
                  : <Row className={"justify-content-center align-items-center"} style={{'minHeight': '200px'}}> {this.renderThrobber()} </Row>
              }
              </div>
            </CardBody>
          </Card>
        </div>
        <div className={"col-sm-4"}>
          <Card>
            <CardBody>
              <Row className={"justify-content-between mx-0"}>
                <CardTitle>Top Sections Today</CardTitle>
                <div>
                  <Link to={"#"}>Show All</Link>
                </div>
              </Row>
              <div className={"mb-3"} style={{minHeight: '213px'}}>
                {
                  (this.state.topSections)?
                    <div>
                      {this.state.topSections.map((prop, key)=>{
                        console.log(prop, key);
                        return (
                          <div className="progress-container" key={"topSections-"+ key}> <div><p style={{'fontSize': '85%'}}>{(prop.section === '')? 'No section':  prop.section}</p></div> <Progress max="100" value={prop.pageviews_percent}> <span className="progress-value">{prop.pageviews_percent.toFixed(0)}%</span> </Progress></div>
                        );
                      })}
                    </div>
                    : <Row className={"justify-content-center align-items-center"} style={{'minHeight': '200px'}}> {this.renderThrobber()} </Row>
                }
              </div>
            </CardBody>
          </Card>
        </div>
        <div className={"col-sm-4"}>
          <Card>
            <CardBody>
              <Row className={"justify-content-between mx-0"}>
                <CardTitle>Top Referrals Today</CardTitle>
                <div>
                  <Link to={"#"}>Show All</Link>
                </div>
              </Row>
              <div className={"mb-3"} style={{minHeight: '213px'}}>
                {
                  (this.state.topReferrals)?
                    <div>
                      {this.state.topReferrals.map((prop, key)=>{
                        console.log(prop, key);
                        return (
                          <div className="progress-container" key={"topReferrals-"+ key}> <div><p style={{'fontSize': '85%'}}>{(prop.referral === '')? 'Direct':  prop.referral}</p></div> <Progress max="100" value={prop.pageviews_percent}> <span className="progress-value">{prop.pageviews_percent.toFixed(0)}%</span> </Progress></div>
                        );
                      })}
                    </div>
                    : <Row className={"justify-content-center align-items-center"} style={{'minHeight': '200px'}}> {this.renderThrobber()} </Row>
                }
              </div>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }

  renderVisitorsMap = () => {

  }

  render() {
    return (
      <>
        <div className="content">
          <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
          <Row className={"align-items-start ml-1"}>
            <div className={"col-sm-6"}>
              {this.renderPageViewGraph()}
            </div>
            <Row className={"col-sm-6"}>
              {this.renderSummaryCards()}
            </Row>
          </Row>
          {this.renderTrendingArticles()}
          <Row className={"mx-0"}>
            {this.renderTopToday()}
          </Row>
          {this.renderVisitorsMap()}
        </div>
      </>
    );
  }
}

export default PublisherOverview;
