import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown, Label, FormGroup
} from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import countries from "../../../api/countries";
import ReactDatetime from "react-datetime";
import Switch from "react-bootstrap-switch";

const categories = [
  { value: "", label: "Choose category", isDisabled: true },
  { value: "Activism", label: "Activism" },
  { value: "Advertising", label: "Advertising" },
  { value: "Animation", label: "Animation" },
  { value: "Automobiles", label: "Automobiles" },
  { value: "Badminton", label: "Badminton" },
  { value: "Baseball", label: "Baseball" },
  { value: "Basketball", label: "Basketball" },
  { value: "Beauty", label: "Beauty" },
  { value: "Books", label: "Books" },
  { value: "Boxing", label: "Boxing" },
  { value: "Children, Toys & Games", label: "Children, Toys & Games" },
  { value: "Closing & accessories", label: "Closing & accessories" },
  { value: "Commerce", label: "Commerce" },
  { value: "Computers", label: "Computers" },
  { value: "Consumer Electronics", label: "Consumer Electronics" },
  { value: "Cricket", label: "Cricket" },
  { value: "Cycling", label: "Cycling" },
  { value: "Economics", label: "Economics" },
  { value: "Education", label: "Education" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Entertainment News", label: "Entertainment News" },
  { value: "Environment", label: "Environment" },
  { value: "Events", label: "Events" },
  { value: "Family & Home", label: "Family & Home" },
  { value: "Fashion", label: "Fashion" },
  { value: "Finance", label: "Finance" },
  { value: "Fitness", label: "Fitness" },
  { value: "Flowers", label: "Flowers" },
  { value: "Food", label: "Food" },
  { value: "Food & Drink", label: "Food & Drink" },
  { value: "Football", label: "Football" },
  { value: "Gambling", label: "Gambling" },
  { value: "Games", label: "Games" },
  { value: "Gay, Lesbians, Bisexual & Transgendered", label: "Gay, Lesbians, Bisexual & Transgendered" },
  { value: "General Merchandise", label: "General Merchandise" },
  { value: "Gifts", label: "Gifts" },
  { value: "Golf", label: "Golf" },
  { value: "Government", label: "Government" },
  { value: "Hardware", label: "Hardware" },
  { value: "Hardware", label: "Hardware" },
  { value: "Health", label: "Health" },
  { value: "Health & beauty", label: "Health & beauty" },
  { value: "Hockey", label: "Hockey" },
  { value: "Home & Garden", label: "Home & Garden" },
  { value: "Humor", label: "Humor" },
  { value: "Industry", label: "Industry" },
  { value: "Information Technology", label: "Information Technology" },
  { value: "International Business", label: "International Business" },
  { value: "Internet", label: "Internet" },
  { value: "Investing", label: "Investing" },
  { value: "Law", label: "Law" },
  { value: "Marketing", label: "Marketing" },
  { value: "Media", label: "Media" },
  { value: "Mobile", label: "Mobile" },
  { value: "Motorsport", label: "Motorsport" },
  { value: "Movies", label: "Movies" },
  { value: "Multimedia", label: "Multimedia" },
  { value: "Music", label: "Music" },
  { value: "News & Media", label: "News & Media" },
  { value: "Office Products", label: "Office Products" },
  { value: "Programming Arts", label: "Programming Arts" },
  { value: "Personal Blog", label: "Personal Blog" },
  { value: "Pets", label: "Pets" },
  { value: "Photography", label: "Photography" },
  { value: "Politics", label: "Politics" },
  { value: "Programming & Technologies", label: "Programming & Technologies" },
  { value: "Radio", label: "Radio" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Recreation", label: "Recreation" },
  { value: "Relationships & Marriage", label: "Relationships & Marriage" },
  { value: "Religion & Spirituality", label: "Religion & Spirituality" },
  { value: "Running", label: "Running" },
  { value: "Science", label: "Science" },
  { value: "Security", label: "Security" },
  { value: "Small Business", label: "Small Business" },
  { value: "Soccer", label: "Soccer" },
  { value: "Society Issues", label: "Society Issues" },
  { value: "Softball", label: "Softball" },
  { value: "Software", label: "Software" },
  { value: "Sports", label: "Sports" },
  { value: "Technology News", label: "Technology News" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Television", label: "Television" },
  { value: "Tennis", label: "Tennis" },
  { value: "Travel", label: "Travel" },
  { value: "Water Sports", label: "Water Sports" },
  { value: "Web Design, development & SEO/SEM", label: "Web Design, development & SEO/SEM" },
  { value: "Weddings", label: "Weddings" },
  { value: "Winter Sports", label: "Winter Sports" },
  { value: "Work", label: "Work" }
];
const freqCapPeriod = [
  {'label': 'Hours', 'value': 'Hours', disabled: true},
  {'label': 'Days', 'value': 'Days'},
  {'label': 'Weeks', 'value': 'Weeks', disabled: true},
  {'label': 'Months', 'value': 'Months', disabled: true},
  {'label': 'Lifetime', 'value': 'Lifetime', disabled: true},
];

class Wizard extends React.Component {

  constructor(props) {
    // init the constructor of the parent react component
    super(props);

    // init the state
    this.state = {
      temporary: {
        estimatedReachData: {},
        hoveredData: [],
        campaignNameState: (this.props.campaignName)? 'has-success' : '', // for the campaign field
        brandNameState: (this.props.brandName)? 'has-success' : '', // for the branding field
        categoryState: (this.props.category)? 'has-success' : '', // for the cat. field
        locationsState: (this.props.locations)? 'has-success' : '',
        targetCategoriesState: (this.props.targetCategories)? 'has-success' : '', // for target audience category.
        cpcState: (this.props.cpc)? 'has-success' : '', // for cpc
        cpmState: (this.props.cpm)? 'has-success' : '', // for cpm
        totalClicksState: (this.props.totalClicks)? 'has-success' : '', // for nb of clicks
        totalImpressionsState: (this.props.totalImpressions)? 'has-success' : '', // for nb of clicks
        totalCpcBudgetState: (this.props.totalCpcBudget)? 'has-success' : '',
        totalCpmBudgetState: (this.props.totalCpmBudget)? 'has-success' : '',
      },
      campaignName: (this.props.campaignName)? this.props.campaignName : '',
      brandName: (this.props.brandName)? this.props.brandName : '',
      category: (this.props.category)? this.getSelectObjectByVal(categories , this.props.category) : '',
      locations: '', // for the location field
      blockedLocations: '',
      targetCategories: (this.props.targetCategories)? this.getSelectObjectByVal(categories, this.props.targetCategories, 'multi') : [],
      startDateTime: (this.props.startDateTime)? this.props.startDateTime : '',
      startDateTimeState: (this.props.startDateTime)? 'has-success' : '',
      endDateTime: (this.props.endDateTime)? this.props.endDateTime : '', // for the date fields
      isScheduled: (this.props.isScheduled)? this.props.isScheduled : false, // for the date fields
      schedule: (this.props.schedule)? this.props.schedule : [], // for chosen
      cpc: (this.props.cpc)? this.props.cpc : '',
      cpm: (this.props.cpm)? this.props.cpm : '',
      totalClicks: (this.props.totalClicks)? this.props.totalClicks : '',
      totalImpressions: (this.props.totalImpressions)? this.props.totalImpressions : 1,
      totalCpcBudget: (this.props.totalCpcBudget)? this.props.totalCpcBudget : '', // for total budget
      totalCpmBudget: (this.props.totalCpmBudget)? this.props.totalCpmBudget : '', // for total budget Cpm
      dailyBudget: (this.props.dailyBudget)? this.props.dailyBudget : '',
      isFreqCapEnabled: (this.props.isFreqCapEnabled)? this.props.isFreqCapEnabled : false,
      isCpmEnabled: (this.props.isCpmEnabled)? this.props.isCpmEnabled : false,
      isCpmOptionEnabled: (this.props.isCpmOptionEnabled)? this.props.isCpmOptionEnabled : false,
      freqCapPeriod: (this.props.freqCapPeriod)? this.props.freqCapPeriod : 'Days',
      freqCapImpression: (this.props.freqCapImpression)? this.props.freqCapImpression : '',
      isStartedRightAway: (this.props.isStartedRightAway)? this.props.isStartedRightAway : false,
      estimatedDailyReach: (this.props.estimatedDailyReach)? this.props.estimatedDailyReach : "0",
      targetDevice: {
        mobile: {
          ios: (this.props.targetDevice)? this.props.targetDevice.mobile.ios : true,
          android: (this.props.targetDevice)? this.props.targetDevice.mobile.android : true
        },
        tablet: {
          ios: (this.props.targetDevice)? this.props.targetDevice.tablet.ios : true,
          android: (this.props.targetDevice)? this.props.targetDevice.tablet.android : true
        },
        desktop : (this.props.targetDevice)? this.props.targetDevice.desktop : true
      },
    };
    this.settings = {
      minCpc: 5,
      minBudget: 100,
      minCpmBudget: 100,
      minCpm: 1
    }
    // initializes the country class that provides with the helper functions
    this.countriesApi = countries;
    // gets all the regions available
    this.regions = this.countriesApi.getRegionsCountries;
    // fetches all the countries
    this.countries = [];
    this.countriesApi.getCountries().then((result)=>{
      this.countries = this.adaptCountryArrays(result.data);
      if(this.props.locations) this.setState({locations : this.getSelectObjectByVal(this.countries, this.props.locations, 'multi')});
      if(this.props.blockedLocations) this.setState({blockedLocations : this.getSelectObjectByVal(this.countries, this.props.blockedLocations, 'multi')});
    });
    this.days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Every day'];
    this.hours = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
    this.dayNight = ['am', 'pm'];
    this.selectRef = React.createRef();
  }

  /*
  * Helper function
  * purpose of this function is to get object of a certain value
  * lets say we have 'abc' so we need to get the object of {value: 'abc', label: 'xxx'} out of array
  * */
  getSelectObjectByVal = (arr = [], value = '', multiSelect=0) => {
    let returnValue = null;
    switch (multiSelect) {
      case 'multi':
        let valueArray = [];
        for (let index in arr) {
          for (let valArrIndex in value){
            if(arr[index]['value'] === value[valArrIndex]){
              valueArray.push(arr[index]);
            }
          }
        }
        returnValue = valueArray;
      break;
      default:
        for (let index in arr) {
          if(arr[index]['value'] === value){
            returnValue = arr[index];
            break;
          }
        }
        break;
    }
    return returnValue;
  }

  /* Helper function
  * Params:  data, that is fetched from the country class
  * Result: ex. [key: 1, val:2]  => [key: 1, label: 1, value:2, val:2]
  * Reason: is to supply the select plugin with the `label` and `value` to be able to render them
  * */
  adaptCountryArrays = (data) => {
    return data.map(function(country){
      const newObject = {};
      Object.assign(newObject, country, {'value': country['alpha2Code'] });
      Object.assign(newObject, country, {'label': country['name'] });
      return newObject;
    });
  };

  getCountriesDetails = (countries) =>{
     return this.countries.filter((res)=> countries.indexOf(res.alpha2Code) > -1);
  }

  /* Helper function
  * Params: region, that was selected in the filter
  * Result: will filter out the `target locations` field with the countries of this region
  * and will filter out the blocked locations with the countries that are not yet chosen to be able to block them
  * */
  filterWithRegion = (whichElt, exclude,region) => {
      // get the countries of region..
      let countries = this.adaptCountryArrays(this.getCountriesDetails(this.regions[region]));
      // merge them with locations already chosen
      countries = [...countries, ...this.state[whichElt]];

      // there might be duplicates in the array after merging so get the unique countries out of them
      let uniqueCountries = [];
      let gatherCountriesAdded = [];

      for(let i in countries){
        if(gatherCountriesAdded.indexOf(countries[i]['name']) === -1){
          // if yet not added go ahead and push the country name as a ref
          gatherCountriesAdded.push(countries[i]['name']);
          // gather the object of the country in uniqueCountries
          uniqueCountries.push(countries[i]);
        }
      }

      // now set the countries with  the unique country values
      countries = uniqueCountries;

      // set the changes for the element
      this.change(countries, whichElt, "select", 1);

      // then exclude from the other
      this.filterLocations(whichElt, exclude, countries);
  };

  /* Helper function
  * To verify the length of the field,  Purpose is to make sure there is a value for the field
  * */
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  isObjExists = (arr, key, value) => {
    for (let index in arr){
      if(arr[index][key] === value){ return index;}
    }
    return false;
  }

  /* Helper function
  * To filter out the countries chosen from the `blocked locations` and `locations` select field
  * */
  filterLocations = (to, exclude, data) => {
    let ref = JSON.parse(JSON.stringify(this.state[exclude]));
    for (var d = data.length - 1; d >= 0; d--) {
      // for(let d in data){
      // loop in to data
      let nameAdded = data[d]['name'];
      let index = this.isObjExists(ref, 'name', nameAdded);
      if(index){
        // if this added one exists in  the array then exclude them
        ref.splice(index, 1);
      }
    }
    this.setState({[exclude]: ref});

  };

  /*Helper Function*/
  setEstimatedReactData = (stateName, targetValue) => {
    if(stateName === 'locations' || stateName === 'targetCategories' || stateName === 'blockedLocations') {
      let target = [];
      let key = 'alpha2Code';

      if(stateName === 'targetCategories'){
        key = 'value';
      }

      for(let i in targetValue){
        target.push(targetValue[i][key]);
      }

      let estimatedReachData = this.state.temporary.estimatedReachData;
      estimatedReachData[stateName] = target;
      this.setTemporary("estimatedReachData", estimatedReachData);

      this.props.campaignDetailsApi.estimatedDailyReach(this.state.temporary.estimatedReachData).then((r)=>{
        this.setState({estimatedDailyReach : r.data.estimated_daily_reach});
      });
    }
  }

  /* Helper function
  * To detect the changes on each field to apply validations
  * */
  change = (event, stateName, type, stateNameEqualTo) => {
    let targetValue = '';

    switch (type) {
      case 'select':
        targetValue = event;
        break;
      case 'date':
        targetValue = (typeof event._d === "undefined")? event : event._d.getDate() + '-' + (event._d.getMonth()+1) + '-' + event._d.getFullYear();
        break;
      default:
        targetValue = event.target.value;
        break;
    }

    this.setEstimatedReactData(stateName, targetValue);

    switch (type) {
      case "length":
        if (this.verifyLength(targetValue, stateNameEqualTo)) {
          this.setTemporary(stateName + "State", "has-success");
        } else {
          this.setTemporary(stateName + "State", "has-danger");
        }
        break;
      case "select":
      case "date":
        if(targetValue.length === 0){
          this.setTemporary(stateName + "State", "has-danger");
        }else{
          this.setTemporary(stateName + "State", "has-success");
        }
        break;
      default:
        break;
    }

    this.setState({ [stateName]: targetValue });
  };

  /* Helper function to help set State of data to be discarded */
  setTemporary = (key, data, callback = () => {}) => {
    this.setState(prevState => ({
      temporary:{
        ...prevState.temporary,
        [key]: data
      }
    }), callback());
  }

  /* Helper function
  * To validations on submission
  * */
  isValidated = () => {
    if (
      this.state.temporary.campaignNameState === "has-success" &&
      this.state.temporary.brandNameState === "has-success" &&
      this.state.temporary.categoryState === "has-success" &&
      this.state.temporary.locationsState === "has-success" &&
      // this.state.startDateTimeState === "has-success" &&
      this.state.temporary.targetCategoriesState === "has-success"
    ) {
      if(typeof window.ps !== "undefined") {
        window.ps.element.scrollTop = 0;
        window.ps.update();
      }
      window.brandtext = this.state.brandName;
      return true;
    } else {
      if (this.state.temporary.campaignNameState !== "has-success") {
        this.setTemporary('campaignNameState', "has-danger");
        // this.setState({ campaignNameState: "has-danger" });
      }
      if (this.state.temporary.brandNameState !== "has-success") {
        this.setTemporary('brandNameState', "has-danger");
        // this.setState({ brandNameState: "has-danger" });
      }
      if (this.state.temporary.categoryState !== "has-success") {
        this.setTemporary('categoryState', "has-danger");
        // this.setState({ categoryState: "has-danger" });
      }
      if (this.state.temporary.locationsState !== "has-success") {
        this.setTemporary('locationsState', "has-danger");
        // this.setState({ locationsState: "has-danger" });
      }
      if (this.state.temporary.targetCategoriesState !== "has-success") {
        // this.setState({ targetCategoriesState: "has-danger" });
        this.setTemporary('targetCategoriesState', "has-danger");
      }
      // if (this.state.startDateTimeState !== "has-success") {
      //   this.setState({ startDateTimeState: "has-danger" });
      // }
      return false;
    }
  };


  /* Helper function
* This is used for the `run ads on schedule`
* Checks if data of schedule has been added before or not
* */
  checkIfExists = (arr, data) => {
    for(let index in arr){
      const ar = arr[index];
      if(ar.day === data.day && ar.hour === data.hour) return index;
    }
    return -1;
  };

  /* Helper function
  * Manages the clicks of the  `run ads on schedule`
  * */
  scheduleHoverEffect = (hoverType, data) => {
    let processedData = [];
    // hoverType can be every day and all day
    switch (hoverType) {
      case "Every day":

        // we need to select them all days of 10 am
        for (let d in this.days){
          if(this.days[d] !== hoverType)
            processedData.push({day: this.days[d] ,hour: data.hour});
        }
        this.setTemporary('hoveredData', processedData);
        break;
      case "All day":
        for (let amPm in this.dayNight){
          let ampm = this.dayNight[amPm];
          for (let h in this.hours){
            // there we have h+ampapm
            let hourDay = this.hours[h]+ampm;
            processedData.push({day: data.day, hour: hourDay});
          }
        }
        this.setTemporary('hoveredData', processedData);
        break;
      default:
        break;
    }
  }

  /* Helper function
  * Manages the clicks of the  `run ads on schedule`
  * */
  assignSchedule = (data) =>{
    // {"day":"Wednesday","hour":"10am"}
    const  index = this.checkIfExists(this.state.schedule, data);
    const schedule = this.state.schedule;
    switch(data.day){
      default:
        if(index > -1){
          // if exists remove it
          schedule.splice(index, 1);
          this.setState({schedule: schedule});
        }else{
          // if not exists then set it
          schedule.push(data);
          this.setState({schedule: schedule});
        }
        break;
      case "Every day":
        // {"day":"Every day","hour":"10am"}
        if(!(index > -1)){

          // we need to select them all days of 10 am
          for (let d in this.days){
            // hr and ampm
            const nIndex = this.checkIfExists(schedule, {day: this.days[d], hour: data.hour});
            if(!(nIndex > -1)){
              // then it was not added so push to array
              schedule.push({day: this.days[d] ,hour: data.hour})
            }
          }
          this.setState({schedule: schedule});
        }else{
          // we need to uncheck them all cz was found
          for (let d in this.days){
            // hr and ampm
            const nIndex = this.checkIfExists(schedule, {day: this.days[d], hour: data.hour});
            if(nIndex > -1){
              // then it was not added so push to array
              schedule.splice(nIndex, 1);
            }
          }
          this.setState({schedule: schedule});
        }
        break;
      case "All day":
        let dayKey = 'allday-'+data.dayName,
          checked = (typeof this.state.temporary[dayKey] === "undefined")? true : !this.state.temporary[dayKey];

        this.setTemporary(dayKey, checked);

        for (let amPm in this.dayNight){
          let ampm = this.dayNight[amPm];
          for (let h in this.hours){
            // there we have h+ampapm
            let hourDay = this.hours[h]+ampm;
            const dayIndex = this.checkIfExists(schedule, {day: data.dayName, hour: hourDay});
            if(checked && !(dayIndex > -1)){
              // if it doesnt exist in array and the state is to check then add them to array
              // then it was not added so push to array
              schedule.push({day: data.dayName, hour: hourDay});
            }
            if(!checked && (dayIndex > -1)){
              // if it exists in array and whats needed it to uncheck, then simply remove from array
              // then it was not added so push to array
              schedule.splice(dayIndex, 1);
            }
          }
        }
        this.setState({schedule: schedule});
        break;
    }
  }


  /* Helper function
  * Renders the table of `run ads on schedule`
  * */
  printSchedule = () => {
    return (
      <div className="cognativex-schedule">
        <Row className="cognativex-schedule-mobile">
          <div className={"col-3 text-right"}>
            {this.dayNight.map((ampm, ampmIndex)=> {
              return(
                <span key={ampm+ampmIndex+"-1-mob"}>
                  {
                    this.hours.map((hour, hourIndex)=> {
                      if(hourIndex % 3 === 0)
                        return(
                          <div className={"hours-of-day"} key={hour+hourIndex+"-1-mob"}>
                            {(hourIndex === 0 && ampmIndex === 0) ? <div
                            >
                              &nbsp;
                            </div> : ''}
                            <div
                              className={"day-hour-titles"}
                            >
                              {hour + ampm}
                            </div>
                          </div>
                        )
                      return ""
                    })
                  }
                </span>
              );
            })}
          </div>

          <div className={"col-9"}>
            <div id={"days-titles"}>
              {this.days.map((day, dayIndex)=> {
                return(
                  <span
                    key={day+dayIndex+"-title"}
                    className={"day-title mr-2"}
                  >
                      {day.substring(0,2)}
                  </span>
                );
              })}
            </div>
            <div id={"days-titles"}>
              {this.days.map((day, dayIndex)=> {
                if(day !== 'Every day'){
                  return <span key={day+'-btn-index-'+dayIndex} title={'All ' + day} className={classnames('day-hour-choice day-hour-choice-allday', {
                    "active": (this.state.temporary['allday-'+day] === true)
                  })} onClick={()=>{this.assignSchedule({day: 'All day', dayName: day})}} onMouseOut={()=>{this.setTemporary('hoveredData', [])}} onMouseOver={()=> {this.scheduleHoverEffect('All day', {day: day})}}
                  >
                  </span>;
                }
                return '';
              })}
            </div>
            {this.dayNight.map((ampm, ampmIndex)=>{
              return (
                <div className={"container-hour container-day-"+ ampm} key={ampm+ampmIndex+"-mob"}>
                  {this.hours.map((hour, hourIndex)=> {
                    let class3rd = '';
                    if(hourIndex%3===0) class3rd = ' third-date';
                    return(
                      <div className={"container-hour-" + hour + " " + class3rd} key={hour+hourIndex+"-mob"}>
                        {this.days.map((day, dayIndex)=> {
                          let data = {day: day, hour: hour + ampm};
                          return (
                            <span
                              title={data.day + ' ' + data.hour}
                              key={'contained'+day+dayIndex+"-mob"}
                              onClick={()=>{this.assignSchedule(data)}}
                              onMouseOver={()=> {if(data.day === 'Every day') this.scheduleHoverEffect('Every day', data)}}
                              onMouseOut={()=>{this.setTemporary('hoveredData', [])}}
                              className={classnames('day-hour-choice ' , {
                                "active": (this.checkIfExists(this.state.schedule, data)>-1),
                                "hovered": (this.checkIfExists(this.state.temporary.hoveredData, data)>-1)
                              })}
                            >
                                {data.hour}
                            </span>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              );

            })}
          </div>
        </Row>
        <div className="cognativex-schedule-desktop">
          <Row className={"hours-container"}>
            <div className={"title-day col-sm-3 col-md-2"}>
              &nbsp;
            </div>
            <div className={"col-sm-9 col-md-10 container-dayNums"}>
              {this.dayNight.map((ampm, ampmIndex)=> {
                return(
                  <span key={'container'+ampm+ampmIndex} className={"container-" + ampm}>
                    {this.hours.map((hour, hourIndex)=> {
                      if(hourIndex%3 === 0)
                        return (
                          <span
                            key={hour+ampm+hourIndex}
                            className={"day-hour-titles"}
                          >
                        <span className={'white'}>{hour}{ampm}</span>
                    </span>
                        )
                      return ''
                    })}
                </span>
                )
              })}
            </div>
          </Row>
          {this.days.map((day, dayIndex)=>{
            return (
              <Row className={"container-day container-day-"+ day} key={day+dayIndex}>
                <div className={"title-day col-sm-3 col-md-2 text-right white"}>
                  {day}
                  {day !== 'Every day'?
                    <span title={'All ' + day} className={classnames('day-hour-choice day-hour-choice-allday', {
                      "active": (this.state.temporary['allday-'+day] === true)
                    })}
                          onClick={()=>{this.assignSchedule({day: 'All day', dayName: day})}}
                          onMouseOver={()=> {this.scheduleHoverEffect('All day', {day: day})}}
                          onMouseOut={()=>{this.setTemporary('hoveredData', [])}}
                    ></span>
                  : ''}
                </div>
                <div className={"col-sm-9 col-md-10 container-dayNums"}>
                  {this.dayNight.map((ampm, ampmIndex)=> {
                    return(
                      <span key={ampm+ampmIndex} className={"container-" + ampm}>
                         {this.hours.map((hour, hourIndex)=> {
                           let data = {day: day, hour: hour + ampm}, class3rd = '';
                           if(hourIndex%3===0) class3rd = ' third-date';
                           return (
                             <span
                               title={data.day + ' ' + data.hour}
                               key={hour+hourIndex}
                               onClick={()=>{this.assignSchedule(data)}}
                               onMouseOver={()=> {if(data.day === 'Every day') this.scheduleHoverEffect('Every day', data);}}
                               onMouseOut={()=>{this.setTemporary('hoveredData', [])}}
                               className={classnames('day-hour-choice', class3rd , {
                                 "active": (this.checkIfExists(this.state.schedule, data)>-1),
                                 "hovered": (this.checkIfExists(this.state.temporary.hoveredData, data)>-1)
                               })}
                             >
                                {data.hour}
                            </span>
                           )
                         })}
                     </span>
                    )
                  })}
                </div>
              </Row>
            );
          })}
        </div>
      </div>
    );
  };

  /* Helper function
  * Renders the table of `run ads on schedule`
  * */
  setTargetDevice = (type, os, event) => {
    let device = this.state.targetDevice;
    const checked = event.target.checked;
    switch (type) {
      case 'desktop':
        device[type] = checked;
        break;
      default:
        device[type][os] = checked;
        break;
    }
    this.setState({targetDevice: device});

    let estimatedReachData = this.state.temporary.estimatedReachData;
    estimatedReachData[type+os.charAt(0).toUpperCase()+os.slice(1)] = checked;
    this.setTemporary("estimatedReachData", estimatedReachData);

    this.props.campaignDetailsApi.estimatedDailyReach(this.state.temporary.estimatedReachData).then((r)=>{
      this.setState({estimatedDailyReach : r.data.estimated_daily_reach});
    });
  };

  setMinValueAndCalculations = (event, stateName, type) => {
    let targetValue = Math.abs(event.target.value);
    let minVals = {'targetClicks': 0, 'cpc': 5, 'totalCpcBudget': 100};

    minVals['targetClicks'] = minVals['totalCpcBudget']/minVals['targetClicks'];

    // verify
    if (targetValue) {
      this.setTemporary(stateName + "State", "has-success");
    } else {
      this.setTemporary(stateName + "State", "has-danger");
    }

    if(targetValue < minVals[stateName]) {
      targetValue = minVals[stateName];
    }

    // then if not equal to the minimum value set it to min value
    this.setState({ [stateName]: targetValue }, ()=>{
      switch (stateName) {
        default:
          break;
        case 'totalCpcBudget':
          // updating the cpc total will update the number of clicks and cpc  accordingly
          if(targetValue && this.state.cpc && this.state.totalClicks){
            this.setState({totalClicks: parseInt(parseFloat(targetValue)/ parseFloat(this.state.cpc))});
            this.setState({cpc: parseFloat(parseFloat(targetValue)/this.state.totalClicks)});
          }
          break;
        case 'totalClicks':
        case 'cpc':
          this.setState({totalCpcBudget: this.state.cpc * this.state.totalClicks}, ()=>{
            for(let i in minVals){
              if(this.state[i] < minVals[i]){
                this.setTemporary(i+"State", "has-danger");
              }else{
                this.setTemporary(i+"State", "has-success");
              }
            }
          });
          break;
      }
    });
  }

  /* Helper function
  * Render functions for each section
  * */
  renderSectionCampaignInfo = () => {
    return (
        <div className="cognativex-card-box">
          <div className="card-header py-0"><h4 className="card-title my-0"><i className="fas fa-info-circle"></i> Campaign Info</h4></div>
          <Row className="mt-4 col-sm-12">
            <Col sm="6">
              <InputGroup
                  className={classnames(this.state.temporary.campaignNameState, {
                    "input-group-focus": this.state.campaignNameFocus
                  })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                    name="campaignName"
                    placeholder="Campaign Name *"
                    type="text"
                    value={this.state.campaignName}
                    onChange={e => this.change(e, "campaignName", "length", 1)}
                    onFocus={e => this.setState({ campaignNameFocus: true })}
                    onBlur={e => this.setState({ campaignNameFocus: false })}
                />
                {this.state.temporary.campaignNameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>

              <InputGroup
                  className={classnames(this.state.temporary.brandNameState, {
                    "input-group-focus": this.state.brandNameFocus
                  })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-address-card" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                    name="brandName"
                    placeholder="Brand Name *"
                    type="text"
                    value={this.state.brandName}
                    onChange={e => this.change(e, "brandName", "length", 1)}
                    onFocus={e => this.setState({ brandNameFocus: true })}
                    onBlur={e => this.setState({ brandNameFocus: false })}
                />
                {this.state.temporary.brandNameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>

            </Col>
            <Col sm="6">
              <InputGroup
                  className={classnames('cognativex-select-group ' , this.state.temporary.categoryState, {
                    "input-group-focus": this.state.categoryFocus
                  })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-list" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="category"
                    value={this.state.category}
                    onChange={e => { this.change(e, "category", "select", 1)}}
                    onFocus={e => this.setState({ categoryFocus: true })}
                    onBlur={e => this.setState({ categoryFocus: false })}
                    options={categories}
                    placeholder="Category *"
                />
                {this.state.temporary.categoryState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>


            </Col>
          </Row>
        </div>
    );
  };
  renderSectionTarget = () => {
    return (
        <div className="cognativex-card-box">
          <div className="card-header py-0 position-relative">
            <h4 className="card-title my-0">
              <i className="tim-icons icon-compass-05" aria-hidden="true"></i> Target
            </h4>
            <span style={{position: 'absolute', right: 0, top: 0}}>
              <i className="fa fa-podcast" aria-hidden="true"></i>&nbsp;&nbsp;Estimated Daily Reach <b>{this.state.estimatedDailyReach}</b>
            </span>
          </div>
          <label className={"ml-3 mb-0 mt-4"}><i className="fa fa-globe" aria-hidden="true"></i> Target Location:</label>
          <Row className="mt-4 col-sm-12">
            <Col sm="6">
              <UncontrolledDropdown className="py-3">
                <DropdownToggle
                  aria-expanded={false}
                  aria-haspopup={true}
                  caret
                  className=""
                  color="info"
                  data-toggle="dropdown"
                  id="dropdownMenuButton"
                  type="button"
                >
                  Add countries by region
                </DropdownToggle>
                <DropdownMenu aria-labelledby="dropdownMenuButton">
                  <div className="cognativex-dropdownmenu">
                    {Object.keys(this.regions).map((region) => {
                      return (
                        <DropdownItem
                          href="#"
                          onClick={(e)=>{ e.preventDefault(); this.filterWithRegion('locations' , 'blockedLocations' , region);}}
                          key={'region-'+region}
                        >
                          {region}
                        </DropdownItem>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <InputGroup
                  className={classnames('cognativex-select-group ' , this.state.temporary.locationsState, {
                    "input-group-focus": this.state.locationFocus
                  })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-square-pin" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="locations"
                    ref={this.selectRef}
                    value={this.state.locations}
                    onChange={e => {this.change(e, "locations", "select", 1); this.filterLocations('locations', 'blockedLocations', e);}}
                    onFocus={e => this.setState({ locationFocus: true })}
                    onBlur={e => this.setState({ locationFocus: false })}
                    options={this.countries}
                    placeholder="Target Locations *"
                    isMulti
                    isClearable={true}
                />
                {this.state.temporary.locationsState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
            <Col sm="6">
              <UncontrolledDropdown className="py-3">
                <DropdownToggle
                  aria-expanded={false}
                  aria-haspopup={true}
                  caret
                  className=""
                  color="info"
                  data-toggle="dropdown"
                  id="dropdownMenuButton"
                  type="button"
                >
                  Add countries by region
                </DropdownToggle>
                <DropdownMenu aria-labelledby="dropdownMenuButton">
                  <div className="cognativex-dropdownmenu">
                    {Object.keys(this.regions).map((region) => {
                      return (
                        <DropdownItem
                          href="#"
                          onClick={(e)=>{ e.preventDefault(); this.filterWithRegion('blockedLocations', 'locations', region); }}
                          key={'region-'+region}
                        >
                          {region}
                        </DropdownItem>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <InputGroup
                  className={classnames('cognativex-select-group ' , {
                    "input-group-focus": this.state.blockedLocationFocus
                  })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-ban" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="blockedLocations"
                    value={this.state.blockedLocations}
                    onChange={e => {this.change(e, "blockedLocations", "select", 1); this.filterLocations('blockedLocations', 'locations', e);}}
                    onFocus={e => this.setState({ blockedLocationFocus: true })}
                    onBlur={e => this.setState({ blockedLocationFocus: false })}
                    options={this.countries}
                    placeholder="Block Locations"
                    isMulti
                    isClearable={true}
                />
              </InputGroup>
            </Col>
          </Row>
          <label className={"ml-3 mb-0 mt-5"}><i className="tim-icons icon-satisfied" aria-hidden="true"></i> Target Audience:</label>
          <Row className="mt-4 col-sm-12">
            <Col sm="6">
              <InputGroup
                className={classnames('cognativex-select-group ' , this.state.temporary.targetCategoriesState, {
                  "input-group-focus": this.state.targetCategoriesFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-users" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="targetCategories"
                  value={this.state.targetCategories}
                  onChange={e => { this.change(e, "targetCategories", "select", 1)}}
                  onFocus={e => this.setState({ targetCategoriesFocus: true })}
                  onBlur={e => this.setState({ targetCategoriesFocus: false })}
                  options={categories}
                  placeholder="Target Audience categories *"
                  isMulti
                  isClearable={true}
                />
                {this.state.temporary.targetCategoriesState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-4 col-sm-12">
            <Col sm="12">
              <Label className="mr-3"><i className="tim-icons icon-mobile" aria-hidden="true"></i> Mobile&nbsp;&nbsp;</Label>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" defaultChecked={this.state.targetDevice.mobile.ios} onChange={(e) => {this.setTargetDevice('mobile', 'ios', e)}}/>
                  <span className="form-check-sign" />IOS
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" defaultChecked={this.state.targetDevice.mobile.android} onChange={(e) => {this.setTargetDevice('mobile', 'android' , e)}}/>
                  <span className="form-check-sign" />Android
                </Label>
              </FormGroup>
            </Col>
            <Col sm="12">
              <Label className="mr-3"><i className="tim-icons icon-tablet-2" aria-hidden="true"></i> Tablet&nbsp;&nbsp;&nbsp;</Label>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" defaultChecked={this.state.targetDevice.tablet.ios} onChange={(e) => {this.setTargetDevice('tablet', 'ios', e)}}/>
                  <span className="form-check-sign" />IOS
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" defaultChecked={this.state.targetDevice.tablet.android} onChange={(e) => {this.setTargetDevice('tablet' , 'android', e)}}/>
                  <span className="form-check-sign" />Android
                </Label>
              </FormGroup>
            </Col>
            <Col sm="12">
              <Label className="mr-3"><i className="tim-icons icon-tv-2" aria-hidden="true"></i> Desktop</Label>
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" defaultChecked={this.state.targetDevice.desktop} onChange={(e) => {this.setTargetDevice('desktop' , '', e)}}/>
                  <span className="form-check-sign" /> &nbsp;
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </div>
    );
  };
  renderSectionScheduling = () => {
    return (
      <div className="cognativex-card-box">
        <div className="card-header py-0"><h4 className="card-title my-0"><i className="tim-icons icon-time-alarm"></i> Kick Off</h4></div>
        <Col sm="12">
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                defaultChecked={!this.state.isStartedRightAway}
                defaultValue="0"
                id="startAsap"
                name="campaignKickOff"
                type="radio"
                onClick={e => {this.setState({isStartedRightAway: false})}}
              />
              <span className="form-check-sign" />
              Run the campaign right away
              <br/>
              <span style={{'fontSize': '76%'}}>(The campaign will run after approval)</span>
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                defaultChecked={this.state.isStartedRightAway}
                defaultValue="1"
                id="assignSchedule"
                name="campaignKickOff"
                type="radio"
                onClick={e => this.setState({ isStartedRightAway: true })}
              />
              <span className="form-check-sign" />
              Schedule the campaign
            </Label>
          </FormGroup>
        </Col>
        <div className={classnames({"d-none" : (!this.state.isStartedRightAway)})}>
          <Row className="mt-4 col-sm-12">
            <Col sm="3">

              <InputGroup
                // className={classnames(this.state.startDateTimeState, 'cognativex-date-time' , {
                className={classnames('cognativex-date-time' , {
                  "input-group-focus": this.state.startDateTimeFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-calendar-60" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  timeFormat={false}
                  value={this.state.startDateTime}
                  onChange={e => this.change(e, "startDateTime", "date", 1)}
                  onFocus={e => this.setState({ startDateTimeFocus: true })}
                  onBlur ={e => this.setState({ startDateTimeFocus: false })}
                  closeOnTab
                  inputProps={{
                    className: "cognativex-datetime form-control",
                    placeholder: "From Date *",
                    name: 'startDateTime',
                    readOnly: true
                  }}
                />
                {this.state.startDateTimeState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
            <Col sm="3" className="hhh">

              <InputGroup
                className={classnames( 'cognativex-date-time' , {
                  "input-group-focus": this.state.endDateTimeFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-calendar-60" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  timeFormat={false}
                  value={this.state.endDateTime}
                  onChange= {e => this.change(e, "endDateTime", "date", 1)}
                  onFocus= {e => this.setState({ endDateTimeFocus: true })}
                  onBlur = {e => this.setState({ endDateTimeFocus: false })}
                  closeOnTab
                  inputProps={{
                    className: "cognativex-datetime form-control",
                    placeholder: "To Date",
                    name: 'endDateTime',
                    readOnly: true
                  }}
                />
                <label className="mt-2">If no end date is specified the campaign will keep running. You can always Stop/Pause the campaign from your dashboard.</label>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-4 col-sm-12">
            <Col sm="12">
              <label><i className="fa fa-clock"></i> Advanced scheduling:</label>
              <FormGroup check className="form-check-radio">
                <Label check>
                  <Input
                    defaultChecked={!this.state.isScheduled}
                    defaultValue="0"
                    id="runAdsAllTime"
                    name="adsSchedule"
                    type="radio"
                    onClick={e => {this.setState({schedule: []}); this.setState({ isScheduled: false })}}
                  />
                  <span className="form-check-sign" />
                  Run the ads 24/7
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio">
                <Label check>
                  <Input
                    defaultChecked={this.state.isScheduled}
                    defaultValue="1"
                    id="runAdsOnSchedule"
                    name="adsSchedule"
                    type="radio"
                    onClick={e => this.setState({ isScheduled: true })}
                  />
                  <span className="form-check-sign" />
                  Run the ads on a schedule
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row className={classnames('mt-4 col-sm-12' , {
          "d-none": !this.state.isScheduled
          })}>
            <Col sm="12">
              {this.printSchedule()}
            </Col>
          </Row>
        </div>
      </div>
    );
  };
  renderSectionPricing = () => {
    return(
      <div className="cognativex-card-box">
        <div className="card-header py-0"><h4 className="card-title my-0"><i className="tim-icons icon-money-coins" aria-hidden="true"></i> Budget</h4></div>
        <Row className="mt-4 col-sm-12">
          <Col sm={"12"}>
            <label className={"mr-4 mb-3"}><i className="tim-icons icon-coins" aria-hidden="true"></i> Campaign Budget:</label>
            <span className={classnames('pt-4 ', {
              "d-none": (!this.state.isCpmOptionEnabled),
              "d-inline-block" : (this.state.isCpmOptionEnabled)
            })}>
                  <Switch
                    defaultValue={this.state.isCpmEnabled}
                    offColor=""
                    offText={'cpc'}
                    onColor=""
                    onText={'cpm'}
                    onChange={()=>{this.setState({ isCpmEnabled: !(this.state.isCpmEnabled) })}}
                  />
              </span>
          </Col>
          <Row className={classnames('col-sm-12', {
            "d-none": (this.state.isCpmEnabled)
          })}>
            <Col md="4">
              <InputGroup
                className={classnames(this.state.temporary.cpcState, {
                  "input-group-focus": this.state.cpcFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-dollar-sign" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="cpc"
                  placeholder="Max CPC *"
                  type="number"
                  min={this.settings.minCpc}
                  value={this.state.cpc}
                  onChange={e =>{ this.setMinValueAndCalculations(e, 'cpc', 'length');}}
                  onFocus={e => this.setState({ cpcFocus: true })}
                  onBlur={e => this.setState({ cpcFocus: false })}
                />
                {this.state.temporary.cpcState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
            <Col md="4">
              <InputGroup
                className={classnames(this.state.temporary.totalClicksState, {
                  "input-group-focus": this.state.totalClicksFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-tap-02" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="totalClicks"
                  placeholder="Target clicks *"
                  type="number"
                  min="0"
                  value={this.state.totalClicks}
                  onChange={e =>  this.setMinValueAndCalculations(e, 'totalClicks', 'length')}
                  onFocus={e => this.setState({ totalClicksFocus: true })}
                  onBlur={e => this.setState({ totalClicksFocus: false })}
                />
                {this.state.temporary.totalClicksState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
            <Col md="4">
            <InputGroup
              className={classnames(this.state.temporary.totalCpcBudgetState, {
                "input-group-focus": this.state.totalCpcBudgetFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-dollar-sign" aria-hidden="true"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="totalCpcBudget"
                placeholder="Total Budget *"
                type="number"
                min={this.settings.minBudget}
                value={this.state.totalCpcBudget}
                onChange={e => this.setMinValueAndCalculations(e, 'totalCpcBudget', 'length')}
                onFocus={e => this.setState({ totalCpcBudgetFocus: true })}
                onBlur={e => this.setState({ totalCpcBudgetFocus: false })}
              />
              {this.state.temporary.totalCpcBudgetState === "has-danger" ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </Col>
          </Row>
          <Row className={classnames('col-sm-12', {
            "d-none": (!this.state.isCpmEnabled)
          })}>
            <Col md="4">
              <InputGroup
                className={classnames(this.state.temporary.cpmState, {
                  "input-group-focus": this.state.cpmFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-dollar-sign" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="cpm"
                  placeholder="CPM *"
                  type="number"
                  min={this.settings.minCpm}
                  onInput={e=>{this.setState({cpm: Math.abs(e.target.value)})}}
                  onChange={e => this.change(e, "cpm", "length", 1)}
                  onFocus={e => this.setState({ cpmFocus: true })}
                  onBlur={e => this.setState({ cpmFocus: false })}
                />
                {this.state.temporary.cpmState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
            <Col md="4">
              <InputGroup
                className={classnames(this.state.temporary.totalImpressionsState, {
                  "input-group-focus": this.state.totalImpressionsFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-tap-02" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="totalImpressions"
                  placeholder="impressions *"
                  type="number"
                  min="0"
                  onInput={e=>{this.setState({totalImpressions: Math.abs(e.target.value)})}}
                  value={this.state.totalImpressions}
                  onChange={e => this.change(e, "totalImpressions", "length", 1)}
                  onFocus={e => this.setState({ totalImpressionsFocus: true })}
                  onBlur={e => this.setState({ totalImpressionsFocus: false })}
                />
                {this.state.temporary.totalImpressionsState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
            <Col md="4">
              <InputGroup
                className={classnames(this.state.temporary.totalCpmBudgetState, {
                  "input-group-focus": this.state.totalCpmBudgetFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-dollar-sign" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="totalCpmBudget"
                  placeholder="Total Budget *"
                  type="number"
                  min={this.settings.minCpmBudget}
                  onInput={e=>{this.setState({totalCpmBudget: Math.abs(e.target.value)})}}
                  onChange={e => this.change(e, "totalCpmBudget", "length", 1)}
                  onFocus={e => this.setState({ totalCpmBudgetFocus: true })}
                  onBlur={e => this.setState({ totalCpmBudgetFocus: false })}
                />
                {this.state.temporary.totalCpmBudgetState === "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
        </Row>
        <Row className="mt-5 col-sm-12">
          <label className={"col-sm-12 mb-3"}><i className="fa fa-id-badge" aria-hidden="true"></i> Daily Ad Delivery:</label>
          <Col md="12">
            <InputGroup className={classnames('col-md-4 px-0' , {
              "input-group-focus": this.state.dailyBudgetFocus
            })}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-dollar-sign" aria-hidden="true"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="dailyBudget"
                placeholder="Daily Budget"
                type="number"
                min="0"
                onInput={e=>{this.setState({dailyBudget: Math.abs(e.target.value)})}}
                value={this.state.dailyBudget}
                onChange={e => this.change(e, "dailyBudget", "length", 1)}
                onFocus={e => this.setState({ dailyBudgetFocus: true })}
                onBlur={e => this.setState({ dailyBudgetFocus: false })}
              />
            </InputGroup>
          </Col>
          <label className={"col-md-8"}>This field allows you to limit the spending of your campaign on a daily basis.</label>

        </Row>
        <Row className="mt-2 col-sm-12">
          <div className={"col-sm-12 mb-3"}>
            <label className={"mr-4"}><i className="fa fa-user" aria-hidden="true"></i> Frequency Cap (Number of views):</label>
            <span className={"pt-4 d-inline-block"}>
                <Switch
                  defaultValue={this.state.isFreqCapEnabled}
                  offColor=""
                  offText={
                    <i className="tim-icons icon-simple-remove" />
                  }
                  onColor=""
                  value={this.state.isFreqCapEnabled}
                  onText={<i className="tim-icons icon-check-2" />}
                  onChange={()=>{this.setState({ isFreqCapEnabled: !(this.state.isFreqCapEnabled) })}}
                />
            </span>
          </div>
          <Col
            className={classnames('col-sm-12', {
              "d-none": (!this.state.isFreqCapEnabled)
            })}
          >
            <Row className={'col-sm-12 align-items-center'}>
              <InputGroup className={classnames('col-md-2 px-0' , { "input-group-focus": this.state.freqCapImpressionFocus })}>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa" aria-hidden="true">NB</i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="freqCapImpression"
                  type="number"
                  min="0"
                  onInput={e=>{this.setState({freqCapImpression: Math.abs(e.target.value)})}}
                  defaultValue={this.state.freqCapImpression}
                  onChange={e => this.change(e, "freqCapImpression", "length", 1)}
                  onFocus={e => this.setState({ freqCapImpressionFocus: true })}
                  onBlur={e => this.setState({ freqCapImpressionFocus: false })}
                />
              </InputGroup>
              <span className={"ml-3 pb-2"}>Impressions Per Day</span>
              <Select
                className="col-md-2 pb-2 react-select info d-none"
                classNamePrefix="react-select"
                name="merticSelect"
                defaultValue={this.getSelectObjectByVal(freqCapPeriod, this.state.freqCapPeriod)}
                options={freqCapPeriod}
                isOptionDisabled={(option) => option.disabled === true}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  // Render
  render() {
    return (
      <>
        {this.renderSectionCampaignInfo()}
        {this.renderSectionTarget()}
        {this.renderSectionScheduling()}
        {this.renderSectionPricing()}
      </>
    );
  };
}

export default Wizard;
