import React from 'react';
import mediaPlanner from '../api/mediaPlanner';
import {Radar} from "react-chartjs-2";
import { Row } from "reactstrap";


let graphOption = (canvas, hexColor, rgbaColor, fill) => {
  return {
    fill: fill,
    borderColor: "#"+hexColor,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: "#"+hexColor,
    pointBorderWidth: 2,
    pointRadius: 2,
    pointBorderColor: 'rgba('+rgbaColor+',0.5)',
    pointHoverRadius: 3,
    pointHoverBorderWidth: 3,
    backgroundColor: 'rgba('+rgbaColor+',0.2)',
  }
}

class Population extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data: {},
      gatherData: {}
    }
    // get up the options of the graph
    this.options = {
      legend: {
        display: true,
        labels: {
          fontColor: 'rgb(255,255,255)',
        },
        position: 'bottom'
      },
      tooltips: {
        enabled: true,
      },
      scale: {
        display: true,
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 1,
          display: false
        },
        pointLabels: {
          fontColor: 'white'
        }
      }
    };
    // initialize the class to be able to run the ajax call to get the revenue details
    this.mediaPlanner = new mediaPlanner();
  }

  clicksVsViews = (gatherData, type = 'views') => {
    this.setState({
      data: function(canvas){
        let r = {};
        document.querySelectorAll('.cognativex-tab').forEach((tabItem) => {
          tabItem.classList.remove('active');
        });
        switch (type) {
          case 'views':
          default:
            r = {
              labels: gatherData.publisherName,
              datasets: [{
                label: "Views Required",
                data: gatherData.viewsRequired,
                ...graphOption(canvas, '00ad8e', '0,173,142', true)
              },{
                label: "Campaign Population",
                data: gatherData.campaignPopulation,
                ...graphOption(canvas, 'ff8d72', '255,141,114', true)
              }, {
                label: "Network Population",
                data: gatherData.networkPopulation,
                ...graphOption(canvas, 'd048b6', '208,72,182', true)
              }]
            };
            break;
          case 'clicks':
            r = {
              labels: gatherData.publisherName,
              datasets: [{
                label: "Requested Clicks",
                data: gatherData.requestedClicks,
                ...graphOption(canvas, '00ad8e', '0,173,142', true)
              },{
                label: "Available Clicks",
                data: gatherData.availableClicks,
                ...graphOption(canvas, 'ff8d72', '255,141,114', true)
              }, {
                label: "Total Network Clicks",
                data: gatherData.networkClicks,
                ...graphOption(canvas, 'd048b6', '208,72,182', true)
              }]
            };
            break;
        }
        document.querySelector(`#${type}-graph`).classList.add('active');
        return r;
      }
    });
  }

  componentWillMount = () => {
    this.mediaPlanner.getPopulationGraphDetails().then((r)=>{
      let gatherData = {};

      // loop to separate all data alone
      for(let index in r.data){
        let object = r.data[index]; // {networkPopulation: "1000", campaignPopulation: "10000", viewsRequired: "7000"}
        for(let key in object){
          if(!gatherData[key]){
            gatherData[key] = [];
          }
          gatherData[key].push(object[key]);
        }
      }
      // now gatherData has:
      // {
      //   publisherName: ['MBC', ..],
      //   networkPopulation: ['9000', ..],
      //   campaignPopulation: ['7000', ...],
      //   viewsRequired: ['6000', ..]
      // }
      this.clicksVsViews(gatherData);
      this.setState({gatherData: gatherData});
    });


  }

  render() {
    return (
      <>
        <Row className={"col-sm-12 justify-content-end"}>
          <div className={"cognativex-tabs  ml-2"}>
            <span id={"views-graph"} className={'cognativex-tab'} onClick={()=>{this.clicksVsViews(this.state.gatherData, 'views');}}>Views</span>
            <span id={"clicks-graph"} className={'cognativex-tab'} onClick={()=>{this.clicksVsViews(this.state.gatherData, 'clicks');}}>Clicks</span>
          </div>
        </Row>
        <Radar
          data={this.state.data}
          options={this.options}
        />
      </>
    );
  }
}




export default Population;
