import React from "react";
import classnames from "classnames";
import Breadcrumbs from "../components/Breadcrumbs.jsx";
// react component for creating dynamic tables
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
  Input,
  Progress,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown, InputGroupAddon, InputGroupText, InputGroup, CardTitle
} from "reactstrap";

import campaignDetails from '../../api/campaignDetails';
import {Line} from "react-chartjs-2";

// core components
import chart from "charts/budgetspentCpc";
import NotificationAlert from "react-notification-alert";

const breadcrumbs = [
    {name: <i className="fas fa-home"></i>, href: "/", disabled: 0},
    {name: 'Campaigns', href: "/campaign", disabled: 1},
    {name: 'List'}
];

let cDetails = new campaignDetails();
// Temporary, supposed to be an api call and assigned to a state after ajax is do
const dataTable = cDetails.getList;

class CampaignList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: this.populateData(dataTable),
          customList: cDetails.getCustomList,
          tableCols : [],
          selected: {},
          selectAll: 0,
          showActions: '',
          filterList: [
            'None',
            'daily',
            'Weekly',
            'Monthly'
          ],
          filterData: 'none'
        };
    }

    /*
    * Helper function:
    * Purpose is to Populated the columns of the table
    * */
    updateListColumns = () => {
    this.setState({tableCols: [
        // {
        //   id: "checkbox",
        //   accessor: "",
        //   Header: x => {
        //     return (
        //       <FormGroup check>
        //         <Label check className="d-inline">
        //           <Input type="checkbox"
        //                  checked={this.state.selectAll === 1}
        //                  onChange={() => this.toggleSelectAll()}
        //                  ref={input => {
        //                    if (input) {
        //                      input.indeterminate = this.state.selectAll === 2;
        //                    }
        //                  }}
        //           />
        //           <span className="form-check-sign" />
        //         </Label>
        //       </FormGroup>
        //     );
        //   },
        //   Cell: ({ original }) => {
        //     return (
        //       <FormGroup check>
        //         <Label check className="d-inline">
        //           <Input type="checkbox"
        //                  checked={this.state.selected[original.id] === true}
        //                  onChange={() => this.toggleRow(original.id)}
        //           />
        //           <span className="form-check-sign" />
        //         </Label>
        //       </FormGroup>
        //     );
        //   },
        //   sortable: false,
        //   width: 45,
        // },
        // {
        //   Header: "Id",
        //   accessor: "id",
        //   width: 60,
        // },
        {
          Header: "Name",
          accessor: "name",
        },
        ...this.state.customList,
        {
          Header: "Progress",
          accessor: "progress",
          Cell: ({original}) => {
            return <div className="progress-container"> <div><p style={{'fontSize': '85%'}}>{original.clicks} <span style={{"color": "#8e8e8e"}}>of {original.totalClicks} click</span></p></div> <Progress max="100" value={original.progress}> <span className="progress-value">{original.progress}%</span> </Progress></div>
          },
          width: 150,
        },
        {
          Header: "Actions",
          accessor: "actions",
          sortable: false,
        }
      ]});
  }

    componentWillMount = () => {
      this.updateListColumns();
    }

    /*Helper function to notify about the actions being pressed*/
    notify = (place, type, icon, msg) => {
      var options = {
        place: place,
        message: msg,
        type: type,
        icon: icon,
        autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
    }

    /*
    * Helper function:
    * Purpose is to set/remove Columns from table
    * Called when checkboxes of `customize list` dropdown items are clicked
    * */
    customizeListView = (index ,custom) => {
      let cloneCustomList = this.state.customList;
      cloneCustomList[index]['show'] = !custom.show; // toggle the state of checkbox
      this.setState({customList: cloneCustomList});
      this.updateListColumns();
    }

    /*
    * Helper function:
    * it helps clone a campaign
    * */
    cloneCampaign = (prop) => {
      console.log('Campaign clone');
      console.log(prop);
      this.notify("tc", 'success', 'tim-icons icon-single-copy-04' , 'Cloned Successfully');
    }

    /*
    * Helper function:
    * it helps del a campaign
    * */
    deleteCampaign = (prop) => {
      console.log('Campaign delete');
      console.log(prop);

      var data = this.state.data;
      data.find((d, i) => {
        if (d.id === prop.id) {
          // here you should add some custom code so you can delete the data
          // from this component and from your server as well
          data.splice(i, 1);
          this.setState({data: this.populateData(data)});

          this.notify("tc", 'success', 'tim-icons icon-simple-remove' , 'Deleted Successfully');

          return true;
        }
        return false;
      });
  }

    /*
    * Helper function:
    * it helps pause/play a campaign
    * */
    pausePlayCampaign = (prop) => {
        console.log('Pause Play campaign');
        console.log(prop);
        let action = (prop.status.toLowerCase() !== 'paused')? 'Paused' : 'Played', icons = (prop.status.toLowerCase() !== 'paused')? 'fa fa-pause' : 'tim-icons icon-triangle-right-17';

        this.notify("tc", 'success', icons,  action + ' successfully');
    }

    /*
    * Helper function:
    * it helps edit a campaign
    * */
    performanceCampaign = (prop) => {
        console.log('Performance of the campaign');
        console.log(prop);
        this.props.history.push(`/admin/campaign-performance/${prop.id}`);
      }

    /*
    * Helper function:
    * it helps edit a campaign
    * */
    editCampaign = (prop) => {
      console.log('Edit the campaign');
      this.props.history.push(`/admin/campaign-edit/${prop.id}`);
    }

    /*
    * Helper Function:
    * It helps with opening the actions
    * It is executed on click of the horizontal ellipsis
    * */
    openActions = (event, prop)=>{
      // hide all opened actions
      let action = document.querySelectorAll('[class^="actions-right-"]');
      for (let i = 0; i< action.length; i++){
        action[i].className += " d-none";
      }
      let elt = document.getElementsByClassName(`actions-right-${prop.index+"-"+prop.id}`)[0]
      if(elt){
        // show the element wanted
        elt.classList.remove('d-none');
      }
      // after 5sec close the opened window
      setTimeout(function(){
        if(elt){
          elt.className += ' d-none';
        }
      }, 5000);
    }

    /*
    * Helper function:
    * it helps populate the data with the correct structure
    * populates the index of the array of each child
    * and populates the actions with the
    * */
    populateData = (data = []) => {
        return data.map((prop, key) => {
          prop.index = key;
          prop.actions =  (
            <div className={"text-right action-btns-"+prop.index+"-"+prop.id} key={prop.id+key}>
                <Button
                  onClick={(e)=>this.editCampaign(prop)}
                  color="primary"
                  size="sm"
                  className="btn-icon btn-link like edit-campaign"
                  id={"tooltip01-" + prop.id}
                >
                  <i className="tim-icons icon-pencil"/>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip01-" + prop.id}
                >
                  Edit
                </UncontrolledTooltip>

                {/* use this button to play or pause the data row */}
                <Button
                  onClick={() => {this.pausePlayCampaign(prop)}}
                  color="warning"
                  size="sm"
                  className={classnames("btn-icon btn-link like play-pause-campaign")}
                  id={"tooltip02-" + prop.id}

                >
                  <i className={classnames({
                    'fa fa-pause' : (prop.status === 'On going'),
                    'tim-icons icon-triangle-right-17' : (prop.status === 'Paused')
                  })} ></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip02-" + prop.id}
                >
                  {(prop.status === 'On going')? 'Pause' : (prop.status === 'Paused')? 'Play' : 'Ended' }
                </UncontrolledTooltip>

                {/* use this button to copy the data row */}
                <Button
                  onClick={() => {this.cloneCampaign(prop)}}
                  color="success"
                  size="sm"
                  className="btn-icon btn-link like copy-campaign"
                  id={"tooltip03-" + prop.id}
                >
                  <i className="tim-icons icon-single-copy-04"/>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip03-" + prop.id}
                >
                  Duplicate
                </UncontrolledTooltip>

                {/* use this button to see performance*/}
                <Button
                  onClick={() => {this.performanceCampaign(prop);}}
                  color="danger"
                  size="sm"
                  className="btn-icon btn-link like performance-campaign"
                  id={"tooltip04-" + prop.id}
                >
                  <i className="tim-icons icon-chart-bar-32"/>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip04-" + prop.id}
                >
                  Performance
                </UncontrolledTooltip>

                {/* use this button to remove the data row */}
                <Button
                  onClick={(e) => {this.deleteCampaign(prop)}}
                  color="info"
                  size="sm"
                  className="btn-icon btn-link like delete-campaign"
                  id={"tooltip05-" + prop.id}
                >
                  <i className="tim-icons icon-simple-remove"/>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip05-" + prop.id}
                >
                  Delete
                </UncontrolledTooltip>
            </div>
          );
          return prop;
        });
    }

    /*
    * Helper function:
    * it helps toggle the check box of one row
    * */
    toggleRow = (id) => {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];
        this.setState({
            selected: newSelected,
            selectAll: 2
        });
    }

    /*
    * Helper function:
    * it helps (un)select all the checkboxes of the table
    * */
    toggleSelectAll() {
        let newSelected = {};

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                newSelected[x.id] = true;
            });
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    /*
    * Helper function:
    * it helps filter out the  table with daily, weekly or monthly results.
    * */
    filterList = (filter = 'none') => {
      this.setState({filterData : filter});
      console.log('filter ' + filter);
    }

    /*
    * Helper Function:
    * helps filtering out values in table
    * */
    searchForData = (text )=> {
      console.log(`search for ${text}`);
    }

    renderKpis = () => {
      return(
        <div className={'col-sm-12'}>
          <Row>
            <Col lg="12">
              <Card className="card-chart pt-2">
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chart.data}
                      options={chart.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <div className="info-icon text-center icon-warning">
                        <i className="tim-icons icon-shape-star" />
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers">
                        <p className="card-category">Live Campaigns</p>
                        <CardTitle tag="h3">100</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-tap-02" />
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers">
                        <p className="card-category">Average CTR</p>
                        <CardTitle tag="h3">1000</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <div className="info-icon text-center icon-success">
                        <i className="fa fa-dollar-sign" />
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers">
                        <p className="card-category">Average CPC</p>
                        <CardTitle tag="h3">1500 $</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-single-02" />
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers">
                        <p className="card-category">Reach</p>
                        <CardTitle tag="h3">10,000</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }

    render() {
        return (
            <>
              <NotificationAlert ref="notificationAlert" />
              <div className="content">
                    <Row className="">

                        <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

                        {this.renderKpis()}
                        <Row  className="col-md-12 col-xs-12 mt-3 mb-2 mx-0 justify-content-end align-items-center">
                            <Link to="#" className={"px-2"}>
                                <i className="fas fa-download"></i> Export
                            </Link>
                            <Link to="/admin/campaign-create" className={"px-2"}>
                                <i className="fas fa-plus-circle"></i> Create Campaign
                            </Link>
                            <UncontrolledDropdown className={'d-inline-block px-2'}>
                              <DropdownToggle
                                aria-expanded={false}
                                aria-haspopup={true}
                                caret
                                className="btn-block"
                                color="info"
                                data-toggle="dropdown"
                                id="dropdownMenuButton"
                                type="button"
                              >
                                Customize list
                              </DropdownToggle>
                              <DropdownMenu aria-labelledby="dropdownMenuButton">
                                <div className="cognativex-dropdownmenu">
                                  {this.state.customList.map((custom, index) => {
                                    return (
                                      <DropdownItem
                                        href="#"
                                        key={'custom-'+custom.accessor}
                                        toggle={false}
                                        className={"cursor-auto py-2"}
                                      >
                                        <FormGroup check className={"mx-0 my-0"}>
                                          <Label check className="d-block">
                                            {custom.Header}
                                            <Input type="checkbox" checked={custom.show} onChange={()=>{this.customizeListView(index, custom);}} />
                                            <span className="form-check-sign" />
                                          </Label>
                                        </FormGroup>
                                      </DropdownItem>
                                    );
                                  })}
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown className={'d-inline-block px-2'}>
                              <DropdownToggle
                                aria-expanded={false}
                                aria-haspopup={true}
                                caret
                                className="btn-block"
                                color="success"
                                data-toggle="dropdown"
                                id="dropdownMenuButton"
                                type="button"
                              >
                                Breakdown: {this.state.filterData}
                              </DropdownToggle>
                              <DropdownMenu aria-labelledby="dropdownMenuButton">
                                <div className="cognativex-dropdownmenu">
                                  {this.state.filterList.map((filter, index) => {
                                    return (
                                      <DropdownItem
                                        href="#"
                                        key={'custom-'+filter+index}
                                        className={"cursor-auto py-2"}
                                        onClick={()=>{this.filterList(filter)}}
                                      >
                                        {filter}
                                      </DropdownItem>
                                    );
                                  })}
                                </div>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            <div className={"px-2"}>
                            <InputGroup
                              className={classnames({
                                "input-group-focus": this.state.searchFocus
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fa fa-search" aria-hidden="true"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name="search"
                                placeholder="Search"
                                type="text"
                                onChange={e => this.searchForData(e.target.value)}
                                onFocus={e => this.setState({ searchFocus: true })}
                                onBlur={e => this.setState({ searchFocus: false })}
                              />
                            </InputGroup>
                          </div>
                        </Row>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    <ReactTable
                                        minRows={0}
                                        data={this.state.data}
                                        resizable={false}
                                        columns={this.state.tableCols}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default CampaignList;
