import React from 'react';
import moment from 'moment';
import DatetimeRangePicker from "../../components/rangeDate/rangeDate";

import {
  Button
} from 'reactstrap';

class DateRangePicker extends React.Component {

  constructor(props) {
    super(props);

    this.handleEvent = this.handleEvent.bind(this);

    this.state = {
      startDate: (this.props.startDate)? this.props.startDate : moment().subtract(6, 'days'),
      endDate: (this.props.endDate)? this.props.endDate : moment(),
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
    };
  }

  handleEvent(event, picker) {
    let date = {
      startDate: picker.startDate,
      endDate: picker.endDate,
    };

    this.setState(date);
    if(this.props.handler){
      this.props.handler(date);
    }
  }

  render() {
    let start = this.state.startDate.format('MMMM D, YYYY');
    let end = this.state.endDate.format('MMMM D, YYYY');
    let label = start + ' - ' + end;
    if (start === end) {
      label = start;
    }

    let buttonStyle = { width: '100%' };

    return (
       <DatetimeRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            ranges={this.state.ranges}
            onEvent={this.handleEvent}
          >
            <Button className="d-flex justify-content-between selected-date-range-btn" style={buttonStyle}>
              <div className={"d-flex"}>
                <i className="fa fa-calendar"/> &nbsp; {label}
              </div>
              <div>
                <i className="fa fa-angle-down"/>
              </div>
            </Button>
          </DatetimeRangePicker>
    );
  }

}

export default DateRangePicker;
