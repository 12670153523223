class main {
  cxColorSet = ['#d8adad', '#f0605a', '#8b3091', '#43ba8f', '#ff1dca', '#5fd7fc', '#1e5199', '#0aaf36', '#ffb500', '#f26522'];

  formatNumber = (num) => {
    if (num > 1000) return parseFloat(num / 1000).toFixed(1) + "K";  // maybe only switch if > 1000
    return num;
  }

  addHours = (date, h) => {
    date.setTime(date.getTime() + (h * 60 * 60 * 1000));
    return date;
  }

  cxDate = (date) => {
    let d = new Date(date);
    this.addHours(d, 2);
    return d;
  }

  formatDate_yyyy_MM_dd = (after) => {
    // if when 0 means today
    // if when 1 means add 1 day so tommorrow
    let date = new Date ();
    date.setDate(date.getDate()+after);
    return date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
  }

}

export default main;
