import React, { Component } from 'react';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

/* global FileReader */

export default class Demo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      src: this.props.src,
      cropResult: null,
    };
    this.cropImage = this.cropImage.bind(this);
  }

  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    this.setState({
      cropResult: this.cropper.getCroppedCanvas().toDataURL(),
    }, ()=>{
      this.props.callback(this.state.cropResult);
    });
  }

  render() {
    return (
      <div>
        <div className={"col-sm-12"}>
          <Cropper
            style={{ height: 400, width: '100%' }}
            preview=".cx-img-preview"
            guides={false}
            src={this.state.src}
            ref={cropper => { this.cropper = cropper; }}
          />
        </div>
        <div>
          <div className="box">
            <button onClick={this.cropImage} className={"btn-simple btn btn-primary"}>
            {this.props.cropBtnTxt}
            </button>
            {(this.props.saveBtnTxt)?
              <button  onClick={()=>{this.props.saveBtnClicked(this.state.cropResult)}} className={"mx-1 btn-simple btn btn-primary"}>
              {this.props.saveBtnTxt}
            </button> : ''}
          </div>
        </div>
      </div>
    );
  }
}
