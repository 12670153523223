import React from "react";
import {Link} from 'react-router-dom';

// reactstrap component
import {
    BreadcrumbItem
} from "reactstrap";

class Breadcrumbs extends React.Component {

    // Helper function to print the breadcrumbs using reactstrap in a dynamic way
    renderBreadCrumbs = () => {
        // Loop and get the breadcrumbs for each to generate the corresponding jsx
        const breadcrumbs = this.props.breadcrumbs.map((breadcrumb, index)=>{
            if(this.props.breadcrumbs.length - 1 === index){
                return <BreadcrumbItem className="active" key={breadcrumb.name + index}>{breadcrumb.name}</BreadcrumbItem>
            }
            if(breadcrumb.disabled === 1){
                return <BreadcrumbItem key={breadcrumb.name + index}><Link to={breadcrumb.href} onClick={e => e.preventDefault()}>{breadcrumb.name}</Link></BreadcrumbItem>
            }
            return <BreadcrumbItem key={breadcrumb.name + index}><Link to={breadcrumb.href}>{breadcrumb.name}</Link></BreadcrumbItem>
        });
        // return it
        return breadcrumbs;
    }

    render() {
        if(this.props.breadcrumbs){
            return (
                <>
                    <ol className="breadcrumb bg-transparent ml-3 mb-0">
                        {this.renderBreadCrumbs()}
                    </ol>
                </>
            );
        }
        return <></>;
    }
}

export default Breadcrumbs;
