import React from 'react';
import mediaPlanner from '../api/mediaPlanner';
import {Bar, Line, Radar} from "react-chartjs-2";
import {
  Row,
  Card, CardBody, Col
} from "reactstrap";
import mainHelper from "../helpers/main";
let helpers = new mainHelper();
let colorset = helpers.cxColorSet;


let hexToRgb = (hex) => {
  hex   = hex.replace('#', '');
  console.log(hex);
  let r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16),
    g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16),
    b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
  return r + ', ' + g + ', ' + b;
}

class NetworkStatusGraph extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      dataNetworkStatusViewsGraph: {},
      gatherDataNetworkStatusViewsGraph: {},

      dataNetworkStatusPublishersVsCampaignsGraph: {},
      gatherDataNetworkStatusPublishersVsCampaignsGraph: {},

      dataNetworkStatusPopulation: {},
      gatherDataNetworkStatusPopulation: {},

      dataNetworkStatusRevenueGraph: {},
      gatherDataNetworkStatusRevenueGraph: {},

      defaultTitle : 'Views'
    }
    this.months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    // get up the options of the graph
    this.optionsNetworkStatusViewsGraph = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'rgb(255,255,255)',
        }
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9a9a9a",
              callback: function(value){
                return value.toLocaleString();
              }
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 1,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9a9a9a",
              callback: (value) => {
                let mydate = new Date(value);
                return mydate.getDate() + ' ' + this.months[mydate.getMonth()]
              }
            }
          }
        ]
      }
    };
    this.optionsNetworkStatusPublishersVsCampaignsGraph = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'rgb(255,255,255)',
        }
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9a9a9a",
              beginAtZero: true,
              callback: function(value){
                return value.toLocaleString();
              }
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              beginAtZero: true,
              padding: 20,
              fontColor: "#9a9a9a",
            }
          }
        ]
      }
    };
    this.optionsNetworkStatusPopulation = {
      legend: {
        display: true,
        labels: {
          fontColor: 'rgb(255,255,255)',
        },
        position: 'bottom'
      },
      tooltips: {
        enabled: true,
      },
      scale: {
        display: true,
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 1,
          display: false,
        },
        pointLabels: {
          fontColor: 'white'
        }
      }
    };
    this.optionsNetworkStatusRevenueGraph = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'rgb(255,255,255)',
        }
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9a9a9a",
              beginAtZero: true,
              callback: function(value) {
                return '$' + value.toLocaleString();
              }
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              beginAtZero: true,
              padding: 20,
              fontColor: "#9a9a9a",
              callback: (value) => {
                let mydate = new Date(value);
                return mydate.getDate() + ' ' + this.months[mydate.getMonth()]
              }
            }
          }
        ]
      }
    };

    // initialize the class to be able to run the ajax call to get the revenue details
    this.mediaPlanner = new mediaPlanner();
  }

  componentWillMount = () => {
    this.mediaPlanner.getNetworkStatusViewsGraphDetails().then((r)=>{
      let gatherData = {};
      // loop to separate all data alone
      for(let index in r.data){
        let object = r.data[index]; // {PublisherName: "MBC, details: [...]}
        for (let key in object){
          switch (key) {
            default:
              if(!gatherData[key]) gatherData[key] = [];
              gatherData[key].push(object[key]);
              break;
            case 'publishers':
              for(let p in object[key]){
                let publisherViewsKey = object[key][p]['publisherName'] + ' publisherviews';
                let publisherClicksKey = object[key][p]['publisherName'] + ' publisherclicks';
                if(!gatherData[publisherViewsKey]) gatherData[publisherViewsKey] = [];
                if(!gatherData[publisherClicksKey]) gatherData[publisherClicksKey] = [];
                gatherData[publisherViewsKey].push(object[key][p]['views']);
                gatherData[publisherClicksKey].push(object[key][p]['clicks']);
              }
              break;
          }
        }
      }
      this.clicksVsViewsNetworkStatusViewsGraph(gatherData);
      this.setState({gatherDataNetworkStatusViewsGraph: gatherData});
    });
    this.mediaPlanner.getNetworkStatusPublishersCampaignsGraphDetails().then((r)=>{
      let gatherData = {};
      // loop to separate all data alone
      for(let index in r.data){
        let object = r.data[index]; // {PublisherName: "MBC, campaigns: [...]}
        for (let key in object){
          switch (key) {
            default:
              if(!gatherData[key]) gatherData[key] = [];
              gatherData[key].push(object[key]);
              break;
            case 'campaigns':
              for(let c in object[key]){
                let campaignViewsKey = object[key][c]['campaignName'] + ' campaignviews1';
                let campaignClicksKey = object[key][c]['campaignName'] + ' campaignclicks1';
                if(!gatherData[campaignViewsKey]) gatherData[campaignViewsKey] = [];
                if(!gatherData[campaignClicksKey]) gatherData[campaignClicksKey] = [];
                gatherData[campaignViewsKey].push(object[key][c]['views']);
                gatherData[campaignClicksKey].push(object[key][c]['clicks']);
              }
              break;
          }
        }

      }
      this.clicksVsViewsNetworkStatusPublishersVsCampaignsGraph(gatherData);
      this.setState({gatherDataNetworkStatusPublishersVsCampaignsGraph: gatherData});
    });
    this.mediaPlanner.getNetworkStatusPopulationGraphDetails().then((r)=> {
      let gatherData = {};

      // loop to separate all data alone
      for(let index in r.data){
        let object = r.data[index]; // {networkPopulation: "1000", campaignPopulation: "10000", viewsRequired: "7000"}
        for(let key in object){
          if(!gatherData[key]){
            gatherData[key] = [];
          }
          gatherData[key].push(object[key]);
        }
      }
      // now gatherData has:
      // {
      //   publisherName: ['MBC', ..],
      //   networkPopulation: ['9000', ..],
      //   campaignPopulation: ['7000', ...],
      //   viewsRequired: ['6000', ..]
      // }
      this.clicksVsViewsNetworkStatusPopulation(gatherData);
      this.setState({gatherDataNetworkStatusPopulation: gatherData});
    });
    this.mediaPlanner.getNetworkStatusRevenueGraphDetails().then((r) => {
      let gatherData = {};

      // loop to separate all data alone
      for (let index in r.data) {
        let object = r.data[index];
        for (let key in object){
          switch (key) {
            default:
              if(!gatherData[key]) gatherData[key] = [];
              gatherData[key].push(object[key]);
              break;
            case 'publishers':
              for (let p in object[key]){
                if(!gatherData[object[key][p]['publisherName']]) gatherData[object[key][p]['publisherName']] = [];
                gatherData[object[key][p]['publisherName']].push(object[key][p]['revenue']);
              }
              break;
          }
        }
      }
      this.clicksVsViewsNetworkStatusRevenueGraph(gatherData);
      this.setState({gatherDataNetworkStatusRevenueGraph: gatherData});
    });
  }

  /*
  * Helper Function:
  * */
  clicksVsViewsNetworkStatusViewsGraph = (data, type = 'views') => {
    let gatherData = JSON.parse(JSON.stringify(data));
    this.setState({
      dataNetworkStatusViewsGraph: function(canvas){

        let ctx = canvas.getContext("2d");

        // choosing timestamp for the y axis
        let r = {labels: gatherData['timestamp'], datasets: []};

        // adding the publishers values of views or clicks
        let index = 0;
        for (let i in gatherData){
          if(i.indexOf(' publisher'+type) > -1){
            let hexColor = colorset[index%colorset.length];
            index++;
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba("+ hexToRgb(hexColor) +",0.3)");
            gradientStroke.addColorStop(0.4, "rgba("+ hexToRgb(hexColor) +",0.1)");
            gradientStroke.addColorStop(0, "rgba("+ hexToRgb(hexColor) +",0)");
            r.datasets.push({
              fill: true,
              borderColor: hexColor,
              borderWidth: 3,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: hexColor,
              pointBorderWidth: 5,
              pointRadius: 4,
              pointBorderColor: 'rgba('+ hexToRgb(hexColor) +',0.5)',
              pointHoverRadius: 4,
              pointHoverBorderWidth: 10,
              backgroundColor: gradientStroke,
              label: i.split(' publisher'+type)[0],
              data: gatherData[i]
            });
          }
        }

        // adding total clicks or total views
        r.datasets.push({
          fill: false,
          borderColor: "#6C6C74",
          borderWidth: 3,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#6C6C74",
          pointHoverRadius: 0,
          pointHoverBorderWidth: 10,
          pointBorderWidth: 0,
          steppedLine: false,
          pointRadius: 0,
          label: "Total " + type,
          data: gatherData['total'+ type.charAt(0).toUpperCase() + type.slice(1)]
        });

        return r;
      }
    });
  }
  clicksVsViewsNetworkStatusPublishersVsCampaignsGraph = (data, type = 'views') => {
    let gatherData = JSON.parse(JSON.stringify(data));

    this.setState({
      dataNetworkStatusPublishersVsCampaignsGraph: function(canvas){

        let ctx = canvas.getContext("2d");

        // choosing timestamp for the y axis
        let r = {labels: gatherData['publisherName'], datasets: []};

        // adding the publishers values of views or clicks
        let index = 0;
        for (let i in gatherData){
          if(i.indexOf(' campaign'+type) > -1){
            let hexColor = colorset[index%colorset.length];
            index++;
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba("+ hexToRgb(hexColor) +",0.3)");
            gradientStroke.addColorStop(0.4, "rgba("+ hexToRgb(hexColor) +",0.1)");
            gradientStroke.addColorStop(0, "rgba("+ hexToRgb(hexColor) +",0)");
            r.datasets.push({
              fill: true,
              borderColor: hexColor,
              borderWidth: 3,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: hexColor,
              pointBorderWidth: 5,
              pointRadius: 4,
              pointBorderColor: 'rgba('+ hexToRgb(hexColor) +',0.5)',
              pointHoverRadius: 4,
              pointHoverBorderWidth: 10,
              backgroundColor: gradientStroke,
              label: i.split(' campaign'+type)[0],
              data: gatherData[i]
            });
          }
        }
        return r;
      }
    });
  }
  clicksVsViewsNetworkStatusPopulation = (gatherData, type = 'views') => {
    this.setState({
      dataNetworkStatusPopulation: function(canvas){
        let r = {};

        switch (type) {
          case 'views':
          default:
            r = {
              labels: gatherData.publisherName,
              datasets: [{
                label: "Used Views",
                data: gatherData.usedViews,
                fill: true,
                borderColor: "#00ad8e",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#00ad8e",
                pointBorderWidth: 2,
                pointRadius: 2,
                pointBorderColor: 'rgba(0,173,142,0.5)',
                pointHoverRadius: 3,
                pointHoverBorderWidth: 3,
                backgroundColor: 'rgba(0,173,142,0.2)'
              }, {
                label: "Network Population",
                data: gatherData.networkPopulation,
                fill: true,
                borderColor: "#d048b6",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#d048b6",
                pointBorderWidth: 2,
                pointRadius: 2,
                pointBorderColor: 'rgba(208,72,182,0.5)',
                pointHoverRadius: 3,
                pointHoverBorderWidth: 3,
                backgroundColor: 'rgba(208,72,182,0.2)'
              }]
            };
            break;
          case 'clicks':
            r = {
              labels: gatherData.publisherName,
              datasets: [{
                label: "Used Clicks",
                data: gatherData.usedClicks,
                fill: true,
                borderColor: "#00ad8e",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#00ad8e",
                pointBorderWidth: 2,
                pointRadius: 2,
                pointBorderColor: 'rgba(0,173,142,0.5)',
                pointHoverRadius: 3,
                pointHoverBorderWidth: 3,
                backgroundColor: 'rgba(0,173,142,0.5)'
              }, {
                label: "Total Network Clicks",
                data: gatherData.networkClicks,
                fill: true,
                borderColor: "#d048b6",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#d048b6",
                pointBorderWidth: 2,
                pointRadius: 2,
                pointBorderColor: 'rgba(208,72,182,0.5)',
                pointHoverRadius: 3,
                pointHoverBorderWidth: 3,
                backgroundColor: 'rgba(208,72,182,0.5)'
              }]
            };
            break;
        }
        return r;
      }
    });
  }
  clicksVsViewsNetworkStatusRevenueGraph = (data, type = 'views') => {
    let gatherData = JSON.parse(JSON.stringify(data));
    this.setState({
      dataNetworkStatusRevenueGraph: function(canvas){

        let ctx = canvas.getContext("2d");

        // choosing timestamp for the y axis
        let r = {labels: gatherData['timestamp'], datasets: []};

        // adding the publishers values of views or clicks
        let index = 0;
        for (let i in gatherData){
          if(i !== 'timestamp'){
            let hexColor = colorset[index%colorset.length];
            index++;
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke.addColorStop(1, "rgba("+ hexToRgb(hexColor) +",0.3)");
            gradientStroke.addColorStop(0.4, "rgba("+ hexToRgb(hexColor) +",0.1)");
            gradientStroke.addColorStop(0, "rgba("+ hexToRgb(hexColor) +",0)");
            r.datasets.push({
              stack: gatherData['timestamp'],
              fill: true,
              borderColor: hexColor,
              borderWidth: 3,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: hexColor,
              pointBorderWidth: 5,
              pointRadius: 4,
              pointBorderColor: 'rgba('+ hexToRgb(hexColor) +',0.5)',
              pointHoverRadius: 4,
              pointHoverBorderWidth: 10,
              backgroundColor: gradientStroke,
              label: i,
              data: gatherData[i]
            });
          }
        }

        return r;
      }
    });
  }

  onChange = (type) => {
    this.clicksVsViewsNetworkStatusViewsGraph(this.state.gatherDataNetworkStatusViewsGraph, type);
    this.clicksVsViewsNetworkStatusPublishersVsCampaignsGraph(this.state.gatherDataNetworkStatusPublishersVsCampaignsGraph, type);
    this.clicksVsViewsNetworkStatusPopulation(this.state.gatherDataNetworkStatusPopulation, type);
    this.clicksVsViewsNetworkStatusRevenueGraph(this.state.gatherDataNetworkStatusRevenueGraph);

    this.setState({defaultTitle: type.charAt(0).toUpperCase() + type.slice(1)});

    document.querySelectorAll('.cognativex-tab').forEach((tabItem) => {
      tabItem.classList.remove('active');
    });
    document.querySelector(`#${type}-graph`).classList.add('active');
  }

  render() {
    return (
      <>
        <div className={'col-sm-12'}>
          <Row>
            <Card className="card-chart" >
              <CardBody className={"pt-0"}>
                <Row className={"col-sm-12 justify-content-end mx-0 px-2 position-relative"} style={{top: '13px'}}>
                  <div>
                    <div className={"cognativex-tabs ml-2 mb-2"}>
                      <span id={"views-graph"} className={'cognativex-tab active'} onClick={()=>{this.onChange('views')}}>Views</span>
                      <span id={"clicks-graph"} className={'cognativex-tab'} onClick={()=>{this.onChange('clicks')}}>Clicks</span>
                    </div>
                  </div>
                </Row>
                <Col lg="12">
                  <div className="card-chart">
                    <h4 className="card-title pb-2 px-3">Publisher Performance by {this.state.defaultTitle}</h4>
                    <div className={'chart-area col-sm-12'} style={{minHeight: '360px'}}>
                      <Line
                        data={this.state.dataNetworkStatusViewsGraph}
                        options={this.optionsNetworkStatusViewsGraph}
                      />
                    </div>
                  </div>
                </Col>
                <Row>
                  <Col lg="4">
                    <div className="card-chart pt-5">
                      <h4 className="card-title pt-4 pb-2 px-3">Consumption of {this.state.defaultTitle} by Campaign</h4>
                      <div className={'chart-area col-sm-12'} style={{minHeight: '300px'}}>
                        <Bar
                          data={this.state.dataNetworkStatusPublishersVsCampaignsGraph}
                          options={this.optionsNetworkStatusPublishersVsCampaignsGraph}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={"4"}>
                    <div className="card-chart pt-5 ">
                      <h4 className="card-title pt-4 pb-2 px-3">Revenue by Publisher</h4>
                      <div className={'chart-area col-sm-12'} style={{minHeight: '300px'}}>
                        <Line
                          data={this.state.dataNetworkStatusRevenueGraph}
                          options={this.optionsNetworkStatusRevenueGraph}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="card-chart pt-5 ">
                      <h4 className="card-title pt-4 pb-2 px-3">Network Usage</h4>
                      <div className={'chart-area col-sm-12'} style={{minHeight: '300px'}}>
                        <Radar
                          data={this.state.dataNetworkStatusPopulation}
                          options={this.optionsNetworkStatusPopulation}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </div>
      </>
    );
  }
}



export default NetworkStatusGraph;
