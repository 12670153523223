import axios from 'axios';
import querystring from "querystring";

/* Helper Class
* To request mediaPlanner details
* */
class MediaPlanner {
  // baseUrl = 'https://cognativex-campaign-api.appspot.com';
  details = {};
  baseUrl = 'https://cognativex-campaign-portal.appspot.com';

  constructor(props) {
    this.details = props;
    if(window.location.hostname === 'localhost'){
      this.baseUrl = 'http://localhost:5000';
    }
  }

  /* Helper method
  * requests the revenue graph's details
  * */
  getRevenueGraphDetails = async () => {
    return await axios.get(this.baseUrl + '/mp-revenue-graph');
  }

  getPopulationGraphDetails = async () => {
    return await axios.get(this.baseUrl + '/mp-population-graph');
  }

  /* Helper method
  * requests the capacity graph's details
  * */
  getNetworkStatusPublishersCampaignsGraphDetails = async () => {
    return await axios.get(this.baseUrl + '/mp-network-status-publishers-vs-campaigns-graph');
  }

  /* Helper method
  * requests the capacity graph's details
  * */
  getNetworkStatusRevenueGraphDetails = async () => {
    return await axios.get(this.baseUrl + '/mp-network-status-revenue-graph');
  }

  /* Helper method
  * requests the network graph's details
  * */
  getNetworkStatusViewsGraphDetails = async () => {
    return await axios.get(this.baseUrl + '/mp-network-status-views-graph');
  }

  /* Helper method
  * requests the network graph's details
  * */
  getNetworkStatusPopulationGraphDetails = async () => {
    return await axios.get(this.baseUrl + '/mp-network-status-population-graph');
  }

  /* Helper method
  * requests the clicks graph's details
  * */
  getCampaignStatusClicksGraphDetails = async () => {
    return await axios.get(this.baseUrl + '/mp-campaign-status-clicks-graph');
  }

  /* Helper method
  * requests the clicks graph's details
  * */
  getCampaignStatusBudgetGraphDetails = async () => {
    return await axios.get(this.baseUrl + '/mp-campaign-status-budget-graph');
  }

  /* Helper method
  * requests the clicks graph's details
  * */
  getCampaignStatusGraphDetails = async (cid) => {
    let queryString = '?';
    if (cid === 0) {
      queryString += 'total=true';
    }else{
      queryString+= 'cid='+cid;
    }
    return await axios.get(this.baseUrl + '/mp-campaign-status-graph' + queryString);
  }

  /* Helper method
  * requests the list of campaigns that needs approval
  * */
  getCampaignsPendingApproval = async () => {
    return await axios.get(this.baseUrl + '/mp-campaigns-approval');
  }

  /* Helper method
  * requests the list of publishers for a campaign by its id
  * */
  getCampaignPublishers = async (campaignId) => {
    return await axios.get(this.baseUrl + '/mp-manage-campaign/' + campaignId);
  }

  /* Helper method
  * requests the list of campaigns that needs approval
  * */
  getPublishersList = async () => {
    return await axios.get(this.baseUrl + '/mp-publisher-list');
  }

  /* Helper method
  * requests the list of campaigns that needs approval
  * */
  getCampaignList = async () => {
    return await axios.get(this.baseUrl + '/mp-campaign-list');
  }

  /* Helper method
  *  To reject a campaign
  * */
  postCampaignReject = async (data) => {
    // data should have data = {campaignId: 123}
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return await axios.post(this.baseUrl + '/mp-reject-campaign', querystring.stringify({data: JSON.stringify(data)}), config);
  }

  /* Helper method
  *  To reschedule a campaign
  * */
  postCampaignReschedule = async (data) => {
    // data should have data = {campaign_id: 123}
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return await axios.post(this.baseUrl + '/mp-reschedule-campaign', querystring.stringify({data: JSON.stringify(data)}), config);
  }

  /* Helper method
  *  accepts and saves a campaigns configuration regarding the publishers allocations
  * */
  postCampaignPublishers = async (data) => {
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return await axios.post(this.baseUrl + '/mp-manage-campaign/', querystring.stringify({data: JSON.stringify(data)}), config);
  }
}

export default MediaPlanner;