import React from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";
import uuid from "uuid";
import createHelper from  "./Helpers/Create";
import { Col } from "reactstrap";
import Details from "./CreationSteps/Step1.jsx";
import Creative from "./CreationSteps/Step2.jsx";
import campaignDetails from '../../api/campaignDetails';
import { Prompt } from 'react-router';

class CampaignCreate extends React.Component {
    constructor(props){
        super(props);
        this.campaignDetail = new campaignDetails({
            key: (this.props.match.path === "/admin/campaign-edit/:id")? '' : uuid.v4(),
        });
        this.state = {
            steps: [
                {
                    stepName: "Details",
                    stepIcon: "tim-icons icon-settings-gear-63",
                    component: Details,
                    stepProps: null
                },
                {
                    stepName: "Creative",
                    stepIcon: "tim-icons icon-palette",
                    component: Creative,
                    stepProps: null
                }
            ],
            reactWizard: null
        };
        this.message = 'Leave Site? Changes you made may not be saved.';
    }

    /*
    * This populates the data for the steps.
    * */
    componentWillMount() {
        if(this.props.match.path === "/admin/campaign-edit/:id"){
            let steps = this.state.steps;
            // do the ajax to get the data
            // returned data
            this.campaignDetail.getCampaignDetails(this.props.match.params.id).then((r)=>{
                // r.data;
                this.campaignDetail['campaignKey'] = r.data['campaignKey'];
                let data = createHelper.mapApiToData(r.data);
                for (let i in steps){
                    steps[i]['stepProps'] = data;
                    steps[i]['stepProps']['campaignDetailsApi'] = this.campaignDetail;
                }
                console.log(steps);
                this.setState({steps: steps});
                this.setState({reactWizard: this.printWizard()});
            });
        }else{
            // creation of campaign
            let steps = this.state.steps;
            for (let i in steps) {
                steps[i]['stepProps'] = {
                    campaignDetailsApi: this.campaignDetail
                }
            }
            this.setState({steps: steps});
        }
    }

    /*
    * Setup the `beforeunload` event listener
    * */
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            ev.returnValue = true;
            return ev;
        });
    };

    /*
    * Activates the unload event
    * */
    componentDidMount() {
        // Activate the event listener
        this.setupBeforeUnloadListener();
    }

    /*
    * This on the finish step
    * to save the data whether new or not
    * */
    finishButtonClick = (finalData) => {
        // make a request to create a new campaign
        this.campaignDetail.create(createHelper.mapDataToApi(finalData)).then((response)=>{
            console.log('response');
            console.log(response);
        });
    }

    printWizard = () => {
        return (
          <ReactWizard
          steps={this.state.steps}
          navSteps
          validate
          title=""
          description=""
          headerTextCenter
          finishButtonClasses="btn-wd btn-info"
          nextButtonClasses="btn-wd btn-info"
          previousButtonClasses="btn-wd"
          progressbar
          color="blue"
          finishButtonClick={this.finishButtonClick}
        />
        );
    }
    render() {
        return (
            <>
                <Prompt
                  message={this.message}
                />
                <div className="content">
                    <Col className="mr-auto ml-auto" md="10">
                        {
                            (this.props.match.path === "/admin/campaign-edit/:id") ? this.state.reactWizard : (this.printWizard())
                        }
                    </Col>
                </div>
            </>
        );
    }
}

export default CampaignCreate;
