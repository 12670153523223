import React from "react";
import Breadcrumbs from "../components/Breadcrumbs.jsx";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Input,
} from "reactstrap";
import mapData from '../../api/mapData';
import publisherApi from '../../api/publisher';
import DatetimeRangePicker from "../components/ReactDateTimePicker";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import mainHelper from "../../helpers/main";
require('highcharts/modules/map')(Highcharts);

let helpers = new mainHelper();
let colorset = helpers.cxColorSet;

const breadcrumbs = [
  {name: <i className="fas fa-home"></i>, href: "/", disabled: 0},
  {name: 'Revenue', href: "/admin/revenue", disabled: 1},
];


class PublisherRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      tableCols: [],
      showActions: '',
      mapData: null,
      mapValues: [],
      categoryGraphData: [],
      summaryData: null,
      filterData: 'Date',
      widgetList: null,
      categoryList: null,
      geolocationList: null,
      dateSet : {},
      filterQueryString: '',
      arrQueryString: {'geolocation': [], 'widget': [], 'category': []},
    };
    this.mapData = new mapData();
    this.publisherApi = new publisherApi();
    this.filterList = [
      'Date',
      'Location',
      'Device'
    ];
    // temporary should exist when user logs in
    this.publisherId = (window.localStorage['cx-uid'])? window.localStorage['cx-uid'] : 0;
  }

  /*
  * Before mounting the component,
  * prepares the data of the pending approval campaigns table
  * */

  reCallApis = () => {
    this.publisherApi.getRevenueByLocation(this.publisherId,this.state.filterQueryString).then((r)=>{
      let countries = [];
      for (var i in r.data.data){
        countries.push([r.data.data[i]['countryCode'], r.data.data[i]['value']]);
      }
      this.setState({mapValues: countries});
    });
    this.publisherApi.getRevenueByCategory(this.publisherId , this.state.filterQueryString).then((r)=>{
      let revenueCatData = {};
      for(var i in r.data.data){
        for (var key in r.data.data[i]){
          // dont enter if key is timestamp or there isnt revenue
          if(key === 'timestamp' || key.indexOf('revenue_') === -1) continue;
          // sure there is revenue so parse the key as wanted
          let nKey = key.split('revenue_')[1].split('_').join(" ");
          // add an empty array if not exist
          if(!revenueCatData[nKey]){
            revenueCatData[nKey] = [];
          }
          // push the data
          let date = new Date(r.data.data[i]['timestamp']);
          revenueCatData[nKey].push({y: r.data.data[i][key], x: Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())});
        }
      }

      let series = [];
      for (var keyInd in revenueCatData){
        series.push({name: keyInd, data: revenueCatData[keyInd]})
      }
      this.setState({categoryGraphData: series});
    });
    this.publisherApi.getRevenueSummary(this.state.filterQueryString).then((r)=>{
      if(!(r.data && r.data.data && r.data.data[0])) return;
      this.setState({summaryData: r.data.data[0]});
    });
    this.groupByTableRequest(this.state.filterQueryString, this.state.filterData);
  }

  componentWillMount = () => {
    this.mapData.getWorld().then((r)=>{
      this.setState({'mapData': r.data});
    });
    this.publisherApi.getPublisherCategoryList(this.publisherId).then((r)=>{
      if(r && r.data && r.data.data){
        this.setState({categoryList : r.data.data});
      }
    });
    this.publisherApi.getPublisherGeolocationList(this.publisherId).then((r)=>{
      if(r && r.data && r.data.data){
        this.setState({geolocationList : r.data.data});
      }
    });
    this.publisherApi.getPublisherWidgetList(this.publisherId).then((r)=>{
      if(r && r.data && r.data.data){
        this.setState({widgetList : r.data.data});
      }
    });
    this.reCallApis();
  }

  setDate = (date) => {
    this.setState({dateSet: {'fromtimestamp': date.startDate._d.toISOString(), 'totimestamp': date.endDate._d.toISOString()}}, ()=>{
      this.getQueryStr();
    });
  }

  groupByTableRequest = (querystring , groupBy) => {
    let query = groupBy;
    if(querystring){
      query += '&'+querystring;
    }
    this.publisherApi.getRevenuetable(this.publisherId, query).then((r)=>{
      let columns = [];
      if (r.data.data && r.data.data.length > 0) {
        let obj = r.data.data[0];
        for (let key in obj) {
          switch(key) {
            default:
              columns.push({
                Header: <span className="cognativex-table-header" title={key.split(/(?=[A-Z])/).join(' ').toLowerCase()}>{key.split(/(?=[A-Z])/).join(' ').toLowerCase()}</span>,
                accessor: key,
                Cell: (info) => {
                  let value = info.original[key];
                  if(!isNaN(value)){
                    value = value.toLocaleString();
                  }
                  if(key === 'revenue') {value = value + '$'}
                  return <span title={info.original[key]}>{value}</span>;
                }
              });
              break;
            case "timestamp":
              columns.push({
                Header: <span className="cognativex-table-header" title={'Date'}>Date</span>,
                accessor: key,
                Cell: (info) => {
                  let date = new Date(info.original[key]);
                  return <span title={info.original[key]}>{date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear()}</span>;
                }
              });
              break;
          }
        }
      }
      this.setState({tableCols: columns}, () => {
        this.setState({tableData: r.data.data});
      });
    });
  }

  renderfilter = (filterName, resultToLoopIn, keyName, keyQuery) => {
    return <UncontrolledDropdown className={'d-inline-block px-2'}>
      <DropdownToggle
        aria-expanded={false}
        aria-haspopup={true}
        caret
        className="btn-block"
        color="secondary"
        data-toggle="dropdown"
        id="dropdownMenuButton"
        type="button"
      >
        {filterName}
      </DropdownToggle>
      <DropdownMenu aria-labelledby="dropdownMenuButton">
        <div className="cognativex-dropdownmenu">
          {(filterName === 'geolocation')?
            <>
              <div className={""}>
                {/*<p className={"mx-2"} style={{"color": "#000"}}>Regions</p>*/}
                {/*{resultToLoopIn.map((filter, index) => {*/}
                {/*if(!(this.state['search-'+filterName]) || this.state['search-'+filterName] == '' || filter[keyName].toLowerCase().indexOf(this.state['search-'+filterName].toLowerCase()) > -1) {*/}
                {/*return (*/}
                {/*<DropdownItem*/}
                {/*href="#"*/}
                {/*key={'custom-' + filter[keyName]}*/}
                {/*toggle={false}*/}
                {/*className={"cursor-auto py-2"}*/}
                {/*>*/}
                {/*<FormGroup check className={"mx-0 my-0"}>*/}
                {/*<Label check className="d-block">*/}
                {/*{filter[keyName]}*/}
                {/*<Input type="checkbox" checked={filter['show']} onChange={() => {*/}
                {/*this.filterDown(index, filter, resultToLoopIn, keyQuery);*/}
                {/*}}/>*/}
                {/*<span className="form-check-sign"/>*/}
                {/*</Label>*/}
                {/*</FormGroup>*/}
                {/*</DropdownItem>*/}
                {/*);*/}
                {/*}*/}
                {/*return '';*/}
                {/*})}*/}
              </div>
              <p className={"mx-2"} style={{"color": "#000"}}>Countries</p>
            </>
            : ''}
          <div className={"justify-content-center d-flex flex-wrap"}>
            <input type={"text"} name={"search"} className={"col-sm-10"} placeholder={"Search"} onChange={(e)=>{this.setState({["search-" + filterName] : e.target.value});}}/>
          </div>
          {resultToLoopIn.map((filter, index) => {
            if(!(this.state['search-'+filterName]) || this.state['search-'+filterName] === '' || filter[keyName].toLowerCase().indexOf(this.state['search-'+filterName].toLowerCase()) > -1) {
              return (
                <DropdownItem
                  href="#"
                  key={'custom-' + filter[keyName]}
                  toggle={false}
                  className={"cursor-auto py-2"}
                >
                  <FormGroup check className={"mx-0 my-0"}>
                    <Label check className="d-block">
                      {filter[keyName]}
                      <Input type="checkbox" checked={(filter['show'])? filter['show'] : false} onChange={() => {
                        this.filterDown(index, filterName, filter, keyQuery);
                      }}/>
                      <span className="form-check-sign"/>
                    </Label>
                  </FormGroup>
                </DropdownItem>
              );
            }
            return '';
          })}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>;
  }

  renderKpis = () => {
    if (this.state.summaryData){
      return(
        <div className={'col-sm-12'}>
          <Row>
            <Col md="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <div className="info-icon text-center icon-warning">
                        <i className="fa fa-dollar-sign" />
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers">
                        <p className="card-category">Total Revenue</p>
                        <CardTitle tag="h3">{this.state.summaryData.totalRevenue.toLocaleString()}$</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <div className="info-icon text-center icon-primary">
                        <i className="fa fa-dollar-sign" />
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers">
                        <p className="card-category">Avg. Cpc</p>
                        <CardTitle tag="h3">{this.state.summaryData.avgCpc.toLocaleString()}$</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <div className="info-icon text-center icon-success">
                        <i className="tim-icons icon-tap-02" />
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers">
                        <p className="card-category">Total Clicks</p>
                        <CardTitle tag="h3">{this.state.summaryData.totalClicks.toLocaleString()}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-shape-star" />
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers">
                        <p className="card-category">Avg. CTR</p>
                        <CardTitle tag="h3">{this.state.summaryData.avgCtr.toLocaleString()}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
    return '';
  }

  getQueryStr = () => {
    // now transform to query strings
    let querystring = [];
    for(var fType in this.state.arrQueryString){
      querystring.push(fType+'='+this.state.arrQueryString[fType].join(','));
    }
    for(var time in this.state.dateSet){
      querystring.push(time+'='+this.state.dateSet[time]);
    }
    this.setState({filterQueryString : querystring.join('&')}, ()=>{
      this.reCallApis();
    });
  }

  filterDown = (index, filterName, filter, keyQuery) => {
    // update the data that the checkbox is checked or not
    let list = this.state[filterName+'List'];
    let checked = (filter["show"])? (!filter['show']): true;
    list[index]["show"] = checked;
    this.setState({[filterName+'List'] : list});

    let key = filter[keyQuery];
    let arrQueryString = this.state.arrQueryString;
    if(checked){
      arrQueryString[filterName].push(key);
    }else{
      arrQueryString[filterName].splice(arrQueryString[filterName].indexOf(key), 1);
    }
    this.setState({arrQueryString: arrQueryString}, this.getQueryStr());
  }

  publisherRevenue = () =>{
    return (
      <Col sm={12}>
        <Row className={"justify-content-end mb-2 px-4"}>
          {(this.state.widgetList)?
            <div>
              {this.renderfilter('widget', this.state.widgetList, 'widgetName', 'widgetId')}
            </div>
            : ''
          }
          {(this.state.categoryList)?
            <div>
              {this.renderfilter('category', this.state.categoryList, 'categoryName', 'categoryId')}
            </div>
            : ''
          }
          {(this.state.geolocationList)?
            <div>
              {this.renderfilter('geolocation', this.state.geolocationList, 'geolocationName', 'geolocationCode')}
            </div>
            : ''
          }
          <div style={{'marginTop': '-5px'}}>
            <DatetimeRangePicker
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              handler={this.setDate}
            />
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col sm={"6"}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    title: {
                      text: 'Revenue by category',
                      style: {
                        color: '#fff'
                      },
                    },
                    credits: {
                      enabled: false
                    },
                    chart: {
                      backgroundColor: 'transparent',
                      scrollablePlotArea: {
                        minWidth: 700
                      },
                      type: 'area'
                    },
                    legend: {
                      enabled: true,
                      align: 'center',
                      itemStyle: {
                        color: '#fff'
                      }
                    },
                    tooltip: {
                      shared: true,
                      crosshairs: true,
                    },
                    plotOptions: {
                      series: {
                        stacking: 'normal'
                      }
                    },
                    xAxis: {
                      type: 'datetime',
                      labels:{
                        style: {
                          color: '#aeaeae'
                        }
                      }
                    },
                    yAxis: {
                      title: {
                        text: 'Revenue',
                      },
                      labels: {
                        formatter: function () {
                          return this.value.toLocaleString() + '$';
                        },
                        style: {
                          color: '#aeaeae'
                        }
                      }
                    },
                    colors: colorset,
                    series: this.state.categoryGraphData
                  }}
                />
              </Col>
              <Col sm={"6"}>
                {(this.state.mapData)?
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'mapChart'}
                    options={{
                      title: {
                        text: 'Revenue by Location',
                        style: {
                          color: '#fff'
                        },
                      },
                      chart:{
                        backgroundColor: 'transparent',
                      },
                      mapNavigation: {
                        enabled: true
                      },
                      credits: {
                        enabled: false
                      },
                      colorAxis: {
                        min: 0,
                        stops: [
                          [0, '#F2CDF7'],
                          [1, '#ff1dca']
                        ],
                        labels: {
                          style: {
                            color: '#aeaeae'
                          }
                        }
                      },
                      tooltip: {
                        pointFormatter: function() {
                          return this.properties['name'].split(',')[0] + ": " + this.options.value.toLocaleString() + '$';
                        }
                      },
                      legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'left',
                        verticalAlign: 'bottom'
                      },
                      series: [{
                        mapData: this.state.mapData,
                        name: 'world map',
                        data: this.state.mapValues
                      }]
                    }}
                  />
                  : ''}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }

  groupByFn = (e, filter) => {
    e.preventDefault();
    this.setState({filterData: filter}, ()=>{
      this.groupByTableRequest(this.state.filterData);
    });
  }

  publisherTableRevenue = () => {
    return <>
      {(this.state.tableData)?
        <Row  className="col-md-12 col-xs-12 mt-3 mb-2 mx-0 justify-content-end align-items-center">
            <UncontrolledDropdown className={'d-inline-block px-2'}>
              <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
              >
                Group By: {this.state.filterData}
              </DropdownToggle>
              <DropdownMenu aria-labelledby="dropdownMenuButton">
                <div className="cognativex-dropdownmenu">
                  {this.filterList.map((filter, index) => {
                    return (
                      <DropdownItem
                        href="#"
                        key={'custom-'+filter+index}
                        className={"cursor-auto py-2"}
                        onClick={(e)=>{this.groupByFn(e, filter);}}
                      >
                        {filter}
                      </DropdownItem>
                    );
                  })}
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
        </Row>
      : ''}
      <Col xs={12} md={12}>
          <Card>
          <CardBody>
            {(this.state.tableData)?
              <ReactTable
                minRows={0}
                data={this.state.tableData}
                resizable={false}
                columns={this.state.tableCols}
                defaultPageSize={10}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
              :
              <h4 className={"my-0 ml-3"}><i className={"fa fa-exclamation mr-3"} style={{'color': 'orange'}}></i> Empty results</h4>
            }
          </CardBody>
      </Card>
      </Col>
    </>;
  }

  render() {
    return (
      <>
        <div className="content">
          <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
          {this.publisherRevenue()}
          {this.renderKpis()}
          {this.publisherTableRevenue()}
        </div>
      </>
    );
  }
}

export default PublisherRevenue;
